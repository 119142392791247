import React, { useEffect, useState } from "react";
import classes from "./Reviews.module.css";
import { Link, useParams } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import makeHttpRequest from "../../utils/api";
import { Stack } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import Navbar from "../../Components/Navbar/Navbar";
import { useLanguage } from "../../utils/LanguageContext";

let style = {};
const isMobile = window.innerWidth <= 767;
if (isMobile) {
  style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
} else {
  style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
}

function AllPropertiesReviews() {
  const { type } = useParams();
  const handleClose = () => setOpen(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [inputReview, setInputReview] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [boatId, setBoatId] = useState(0);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { langData, fetchLangData, translate } = useLanguage();
  const handleOpen = (id, typ, rating = "", des = "") => {
    setInputReview(des);
    setSelectedValue(rating);
    if (typ === "boat") {
      setBoatId(id);
    } else {
      setPropertyId(id);
    }
    setOpen(true);
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      let res;
      if (type === "Property") {
        res = await makeHttpRequest("GET", "user/pending-reviews/property");
      } else if (type === "Boat") {
        res = await makeHttpRequest("GET", "user/pending-reviews/boat");
      } else {
        res = await makeHttpRequest("GET", "user/reviews");
      }
      setData(res.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRateChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleReviewChange = (e) => {
    setInputReview(e.target.value);
  };

  const storeFeedback = async () => {
    try {
      setLoading(true);
      let obj = {
        rating: selectedValue,
        description: inputReview,
      };
      if (propertyId != 0) {
        obj.property_id = propertyId;
      } else if (boatId != 0) {
        obj.boat_id = boatId;
      }
      const res = await makeHttpRequest("POST", "user/reviews/store", obj);
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className={classes["page-container"]}>
        <div className="row">
          <div className="col-lg-3 col-md-3 d-none d-lg-block">
            <UserSideNavbar />
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className={classes["reviewmodal-heading"]}>
                  <h1>{translate('Feedback')}</h1>
                </div>
                <div className={classes["reviewsubmit-inputs"]}>
                  <label>{translate('Stars')}</label>
                  <select
                    className="form-control mb-3"
                    onChange={handleRateChange}
                    value={selectedValue}
                  >
                    {(() => {
                      const options = [];
                      for (let i = 1; i <= 5; i++) {
                        options.push(
                          <option
                            key={i}
                            value={i}
                            selected={selectedValue === i ? "selected" : ""}
                          >
                            {i} {i < 2 ? " Star" : "Stars"}
                          </option>
                        );
                      }
                      return options;
                    })()}
                  </select>
                  {/* <input type="text" placeholder="Text area....." /> */}
                  <label>{translate('Write feedback')}</label>
                  <textarea
                    className="form-control"
                    onKeyUp={handleReviewChange}
                  >
                    {inputReview}
                  </textarea>
                  <div className={classes["reviewsubmit-wrapper"]}>
                    <Link>
                      <button
                        onClick={storeFeedback}
                        className={classes["reviewsubmit-btn"]}
                      >
                        {translate('Submit')}
                      </button>
                    </Link>
                  </div>
                </div>
              </Box>
            </Modal>

            <div className={`${classes['reviews-bg']} text-center`}>
              <div className={classes["reviews-viewallWrapper"]}>
                <h2> {type} {translate('Waiting for Review')}</h2>
                {/* <button className="reviews-viewallBtn">
                <Link>View All</Link>
              </button> */}
              </div>
              <div
                className="table-responsive"
                style={{ borderRadius: "10px" }}
              >
                <div className={classes["table-wrapper"]}>
                  <table
                    id="mytable"
                    className="table table-bordred table-striped"
                  >
                    <thead className={classes["reviews-table-th"]}>
                      <th>{translate('Property Name')}</th>
                      <th>{translate('Location')}</th>
                      <th>{translate('Phone')}</th>
                      <th>{translate('Rate')}</th>
                    </thead>
                    <tbody>
                      {data?.pendings?.data?.map((p, i) => (
                        <tr key={i}>
                          <td>{p.name}</td>
                          <td>{p?.location?.name}</td>
                          <td>{p.phone ? p?.phone : "Not Available"}</td>
                          <td>
                            <AiFillStar
                              style={{
                                color: "#f2c821",
                                cursor: "pointer",
                                fontSize: "25px",
                              }}
                              onClick={() => handleOpen(p.id, "property")}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AllPropertiesReviews;
