import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css"; // You can create this CSS file to add custom styles
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { BiMenu } from "react-icons/bi";
import { useSelector } from "react-redux";
import UserSiderbar from "./UserSidebar";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";
import ButtonI from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { ButtonGroup } from "react-bootstrap";
import { setStateBasedOnDomain } from "../../utils/helpers";

function Navbar() {
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector((state) => state.user);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : setStateBasedOnDomain()
  );

  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState("");
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const { langData, fetchLangData, translate } = useLanguage();

  const handleLanguage = (newLanguage) => {
    setSelectedLanguage(newLanguage);
    fetchLangData(newLanguage);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "language");
      setLanguages(res?.data?.languages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <header className="site-navbar" role="banner">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <div className="site-logo">
            <a href="/" className="font-weight-bold">
              <img src="/assets/icons/whiteLogo.png" alt="..." />
            </a>
          </div>
          <button
            className={`navbar-toggler ${isNavOpen ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={isNavOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleNav}
          >
            <BiMenu style={{ color: "#FFF", fontSize: "50px" }} />
          </button>
          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="site-menu ">
              <div className="site-menu-links">
                <li
                  className={
                    pathname === "/" || pathname.startsWith("/Home")
                      ? "active"
                      : ""
                  }
                >
                  <Link className="nav-link" to="/">
                    {translate("Home")}
                  </Link>
                  {/* <a href="#" className="nav-link">
                    Home
                  </a> */}
                </li>
                <li
                  className={
                    pathname.startsWith("/accomodation") ? "active" : ""
                  }
                >
                  <Link className="nav-link" to="/accomodation">
                    {translate("Accommodations")}
                  </Link>
                  {/* <a href="#" className="nav-link">
                    Accommodations
                  </a> */}
                </li>
                <li className={pathname.startsWith("/boat") ? "active" : ""}>
                  <Link className="nav-link" to="/boat">
                    {translate("Boat")}
                  </Link>
                </li>
                <li className={pathname === "/blog" ? "active" : ""}>
                  <Link className="nav-link" to="/blog">
                    {translate("Blog")}
                  </Link>
                </li>
                <li className={pathname === "/contact" ? "active" : ""}>
                  <Link className="nav-link" to="/contact">
                    {translate("Contact")}
                  </Link>
                </li>
              </div>
              <div className=" navbar-right-wrapper">
                <div className="nav-contant">
                  {/* <img alt="" src="/assets/icons/countryIcon.png" /> */}
                  <div className="language-dropdown">
                    <select
                      id="language-select"
                      value={selectedLanguage}
                      onChange={(e) => handleLanguage(e.target.value)}
                    >
                      {languages &&
                        languages?.map((lan, i) => (
                          <option value={lan.code}>
                            {translate(lan.name)}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {!isLoggedIn && (
                  <Link to="/login">
                    <Button variant="contained" size="small">
                      {translate("User Login")}
                    </Button>
                  </Link>
                )}
                {!isLoggedIn && (
                  <Link to="/register">
                    <Button variant="contained" size="small">
                      {translate("User Register")}
                    </Button>
                  </Link>
                )}

                {isLoggedIn && (
                  <Link to="/dashboard">
                    <Button variant="contained" size="small">
                      {translate("Dashboard")}
                    </Button>
                  </Link>
                )}
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <UserSiderbar />
    </header>
  );
}

export default Navbar;
