import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./DashboardSideNav.css";
import DashboardLogout from "../DashboardLogout/DashboardLogout";
import { useLanguage } from "../../utils/LanguageContext";

function DashboardSideNav() {
  const [showMenu, setShowMenu] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [selectedOption, setSelectedOption] = useState("propertyInquiry");
  return (
    <>
      <nav className="navbar navbar-owner" id="sidebar">
        <div
          className="navbar-container container mobile-sidebar-container"
          // style={{ display: "flex", justifyContent: "center" }}
        >
          <input type="checkbox" name="" id="" />
          <div
            className="hamburger-lines-ownerside"
            style={{ color: "#ffffff" }}
          >
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <div className="owner-dashboardLogo-img">
            <Link to={"/dashboard"}>
              <img alt="" src="/assets/icons/dashboardLogo.png" />
            </Link>
          </div>

          <ul className="menu-items mobile-position">
            <Link to={"/dashboard"}>
              <h1
                className="dashboard-subTitle mb-2"
                style={{ fontSize: "16px" }}
              >
                {translate("DASHBOARD")}
              </h1>
            </Link>
            <hr />
            <h1 className="dashboard-subTitle mb-3">{translate("INQUIRY")}</h1>
            <li>
              <Link
                className={
                  selectedOption === "propertyInquiry"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("propertyInquiry")}
                to="/property-inquiry"
              >
                <p>{translate("All Property Inquiry")}</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  selectedOption === "allBoatInquiry"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("allBoatInquiry")}
                to="/boat-inquiry"
              >
                <p>{translate("All Boat Inquiry")}</p>
              </Link>
            </li>
            <h1 className="dashboard-subTitle mt-2 mb-4">
              {translate("ACCOMODATION")}
            </h1>
            <li>
              {" "}
              <Link
                to="/all-property"
                className={
                  selectedOption === "allProperty"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("allProperty")}
              >
                <p>{translate("All Property")}</p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to="/room-category"
                className={
                  selectedOption === "roomCategory"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("roomCategory")}
              >
                <p>{translate("Room Category")}</p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to="/booked-property"
                className={
                  selectedOption === "allBookedProperty"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("allBookedProperty")}
              >
                <p>{translate("All Booked Property")}</p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to="/pending-property"
                className={
                  selectedOption === "pendingProperty"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("pendingProperty")}
              >
                <p>{translate("Pending Booked Property")}</p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to="/success-property"
                className={
                  selectedOption === "successProperty"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("successProperty")}
              >
                <p style={{ lineHeight: "15px" }}>
                  {translate("Success Booked Property")}{" "}
                </p>
              </Link>
            </li>
            <li>
              <Link
                to="/cancel-property"
                className={
                  selectedOption === "cancelProperty"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("cancelProperty")}
              >
                <p style={{ lineHeight: "15px" }}>
                  {translate("Cancel Booked Property")}{" "}
                </p>
              </Link>
            </li>
            <h1 className="dashboard-subTitle mt-3 mb-4">
              {translate("BOAT")}
            </h1>
            <li>
              {" "}
              <Link
                to="/all-boat"
                className={
                  selectedOption === "allBoat"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("allBoat")}
              >
                <p>{translate("All Boat")}</p>
              </Link>
            </li>
            <li>
              <Link
                to="/booked-boat"
                className={
                  selectedOption === "allBookedBoat"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("allBookedBoat")}
              >
                <p>{translate("All Booked Boat")}</p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to="/pending-boat"
                className={
                  selectedOption === "pendingBoat"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("pendingBoat")}
              >
                <p>{translate("Pending Booked Boat")}</p>
              </Link>
            </li>
            <li>
              <Link
                to="/success-boat"
                className={
                  selectedOption === "successBoat"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("successBoat")}
              >
                <p style={{ lineHeight: "15px" }}>
                  {translate("Success Booked Boat")}{" "}
                </p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to="/cancel-boat"
                className={
                  selectedOption === "cancelBoat"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("cancelBoat")}
              >
                <p style={{ lineHeight: "15px" }}>
                  {translate("Cancel Booked Boat")}{" "}
                </p>
              </Link>
            </li>
            <h1 className="dashboard-subTitle mt-2 mb-4">
              {translate("WITHDRAW")}
            </h1>
            <li>
              <Link
                to="/withdraw-money"
                className={
                  selectedOption === "withdrawMoney"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("withdrawMoney")}
              >
                <p style={{ lineHeight: "15px" }}>
                  {translate("Withdraw Money")}{" "}
                </p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to="/withdraw-log"
                className={
                  selectedOption === "withdrawLog"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("withdrawLog")}
              >
                <p style={{ lineHeight: "15px" }}>
                  {translate("Withdraw Log")}{" "}
                </p>
              </Link>
            </li>
            <h1 className="dashboard-subTitle mt-2 mb-4">
              {translate("SUPPORT TICKET")}
            </h1>
            <li>
              <Link
                to="/open-ticket"
                className={
                  selectedOption === "openTicket"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("openTicket")}
              >
                <p style={{ lineHeight: "15px" }}>{translate("Open Ticket")}</p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to="/my-ticket"
                className={
                  selectedOption === "myTicket"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("myTicket")}
              >
                <p style={{ lineHeight: "15px" }}>{translate("My Ticket")}</p>
              </Link>
            </li>
            {/* <h1 className="dashboard-subTitle mt-2 mb-4">
              {translate("ACCOUNT")}
            </h1> */}
            {/* <li>
              {" "}
              <Link
                to="/profile"
                className={
                  selectedOption === "profile"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("profile")}
              >
                <p style={{ lineHeight: "15px" }}>{translate("Profile")}</p>
              </Link>
            </li> */}
            {/* <li>
              {" "}
              <Link
                to="/security"
                className={
                  selectedOption === "security"
                    ? "side-nav-icon dashboard-selected-tab"
                    : "side-nav-icon"
                }
                onClick={() => setSelectedOption("security")}
              >
                <p style={{ lineHeight: "15px" }}>
                  {translate("2FA Security")}
                </p>
              </Link>
            </li> */}
            <DashboardLogout />
          </ul>
        </div>
      </nav>
    </>
  );
}

export default DashboardSideNav;
