import React from "react";
import "./ForgetPassword.css";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

function ForgetPassword() {
  return (
    <div className="p-4">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 reset-content-wrapper">
          <div className="restPassword-heading">
            <img src="/assets/icons/companyLogo.png" alt="..." />
            <div className="restPassword-topIcon">
              <img src="/assets/icons/aa1.png" alt="..." />
            </div>
            <div className="restPassword-bottomIcon">
              <img src="/assets/icons/aa2.jpeg" alt="..." />
            </div>
            <h1>Forget Password</h1>

            <div className="restPassword-email">
              <label>Email</label>
              <input type="email" placeholder="Enter your email" />
            </div>

            <div className="resetPassword-btn">
              <button>Reset Password</button>
            </div>

            <div className="signIn-link">
              <p>
                Back to <Link to="/login">Sign In</Link>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6  d-none d-lg-block d-md-block">
          <div
            id="resetPasswordcarouselExampleIndicator"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators-resetPassword">
              <button
                type="button"
                data-bs-target="#resetPasswordcarouselExampleIndicator"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#resetPasswordcarouselExampleIndicator"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#resetPasswordcarouselExampleIndicator"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="/assets/icons/resetPasswordImg.png"
                  className="d-block w-100"
                  alt="..."
                />

                <div className="carousel-caption">
                  <h2>
                    “We've been using CAPRIEASY to explore every new location,
                    and I can't imagine having a good time traveling without
                    it.”
                  </h2>
                  <h3>Brooklyn Simmons</h3>
                  <p>Happy Client</p>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src="/assets/icons/loginPageImg.png"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption">
                  <h2>
                    “We’ve been using CAPRIEASY to explore every new place and
                    can’t imagine enjoying my travel without it.”
                  </h2>
                  <h3>Olivia Rhye</h3>
                  <p>Happy Client</p>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src="/assets/icons/signupPageImg.png"
                  className="d-block w-100"
                  alt="..."
                />
                <div className="carousel-caption">
                  <h2>
                    “We have been utilizing CAPRIEASY to explore each new
                    location, and I can't fathom traveling without it.”
                  </h2>
                  <h3>Wade Warren</h3>
                  <p>Happy Client</p>
                </div>
              </div>
            </div>
            <div>
              <button
                className="resetPassword-carousel-control-prev"
                type="button"
                data-bs-target="#resetPasswordcarouselExampleIndicator"
                data-bs-slide="prev"
              >
                <span
                  className="resetPassword-carousel-control-prev-icon"
                  aria-hidden="true"
                >
                  <BsArrowLeft />
                </span>
              </button>
              <button
                className="resetPassword-carousel-control-next"
                type="button"
                data-bs-target="#resetPasswordcarouselExampleIndicator"
                data-bs-slide="next"
              >
                <span
                  className="resetPassword-carousel-control-next-icon"
                  aria-hidden="true"
                >
                  <BsArrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
