import React, { createContext, useContext, useEffect, useState } from "react";
import makeHttpRequest from "./api";
import { setStateBasedOnDomain } from "./helpers";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [langData, setLangData] = useState(null);

  const fetchLangData = async (locale) => {
    try {
      localStorage.setItem("lang", locale);
      const response = await makeHttpRequest("GET", `languages/${locale}`);
      setLangData(response.data);
    } catch (error) {
      console.error("Error fetching language data:", error);
    }
  };

  useEffect(() => {
    let lang = setStateBasedOnDomain();
    if (localStorage.getItem("lang")) {
      lang = localStorage.getItem("lang");
    }
    fetchLangData(lang);
  }, []);

  const translate = (key) => {
    return langData && langData[key] ? langData[key] : key;
  };

  return (
    <LanguageContext.Provider value={{ langData, fetchLangData, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
