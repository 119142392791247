import React, { useState, useEffect } from "react";
import "./RegisterOwner.css";
import { Link, useNavigate } from "react-router-dom";
import makeHttpRequest from "../../utils/api";
import AddressAutocomplete from "../../Components/AddressAutocomplete";
import ExtensionApi from "../../Components/ExtensionApi";
import RegisterImage from "../../Components/RegisterImage";
import { useLanguage } from "../../utils/LanguageContext";
import Toaster from "../../Components/Toaster";
import { googleMapApiKey } from "../../utils/helpers";

function RegisterOwner() {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countries, setCountries] = useState([]);
  const [host_form, setHostForm] = useState([]);
  const [name, setName] = useState("");
  // const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [dynamic_data, setDynamicData] = useState({});
  const [place, setPlace] = useState(null);
  const [country_code, setCountryCode] = useState("IT");
  const [captcha, setCaptcha] = useState("");
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();

  const emailIsValid = (email) => {
    // if (!/\S+@\S+\.\S+/.test(email)) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {       
      return { status: false, msg: "Enter Valid Email" };
    } else {
      return { status: true, msg: "Valid Email" };
    }
  };

  const passwordIsValid = (password) => {
    if (password.length < 8) {
      return {
        status: false,
        msg: translate("Password must be greater than 8 characters"),
      };
    } else if (password !== password_confirmation) {
      return { status: false, msg: translate("Confirm Password must be same") };
    } else {
      return { status: true, msg: translate("Valid Password") };
    }
  };

  const handleRegister = async (e) => {
    if (!isCaptchaPassed) {
      Toaster("warning", translate("Captcha Required"));
      return;
    }
    e.preventDefault();
    // Validate email
    let valid = emailIsValid(email);
    if (!valid.status) {
      setEmailError(valid.msg);
      return;
    } else {
      setEmailError('');
    }

    // Validate password
    valid = passwordIsValid(password);
    if (!valid.status) {
      setPasswordError(valid.msg);
      return;
    }
    setIsButtonDisabled(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("mobile", phone);
      formData.append("country", country_code.toUpperCase());
      formData.append("password", password);
      formData.append("password_confirmation", password_confirmation);
      // formData.append("dynamic_data", dynamic_data);
      for (const key in dynamic_data) {
        formData.append(key, dynamic_data[key]);
      }
      // Object.keys(selectedFiles).forEach((docType) => {
      //   formData.append(`host_form[${docType}]`, selectedFiles[docType]);
      // });

      const response = await makeHttpRequest(
        "POST",
        "owner/register",
        formData
      );
      if (response.success) {
        navigate("/owner-login");
        setIsButtonDisabled(false);
      }
      setIsButtonDisabled(false);
    } catch (error) {
      setIsButtonDisabled(false);
      // console.error("Error is :", error);
    }
  };

  const handleInputChange = (e, key) => {
    const { type, value, files } = e.target;

    setDynamicData((prevData) => ({
      ...prevData,
      [key]: type == "file" ? files[0] : value,
    }));
  };

  // Fetch country data
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const res = await makeHttpRequest("POST", "country", {
          user_type: "owner",
        });
        setCaptcha(res.data.gextension);
        setCountries(res.data.records);
        setHostForm(res.data.form);
      } catch (error) {
        console.error("Error is:", error);
      }
    };
    fetchCountryData();
  }, []);

  const handlePlaceChanged = (place) => {
    setPlace(place);
    setAddress(place.formatted_address);
    const countryAddressComponent = place.address_components?.find(
      (addressComponent) => addressComponent.types[0] === "country"
    );
    if (countryAddressComponent) {
      setCountryCode(countryAddressComponent.short_name);
    }
  };

  const handleDocumentChange = (e, docType) => {
    const file = e.target.files[0];

    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [docType]: file,
    }));
  };

  return (
    <div className="p-5">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 signUp-content-wrapper">
          <div className="signUp-heading">
            <img src="/assets/icons/companyLogo.png" alt="..." />
            <div className="SignUpHeading-topIcon">
              <img src="/assets/icons/aa1.png" alt="..." />
            </div>
            <div className="SignUpHeading-bottomIcon">
              <img src="/assets/icons/aa2.jpeg" alt="..." />
            </div>
            <h1>{translate("Welcome to CAPRIEASY")}</h1>
            <div className="signUp-email">
              <label>{translate("Full name")}</label>
              <input
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder={translate("Enter your name")}
              />
            </div>
            {/* <div className="signUp-email">
              <label>User Name</label>
              <input required onChange={(e)=>set} type="text" placeholder="Enter your username" />
            </div> */}
            <div className="signUp-email">
              <label>{translate("Email")}</label>
              <input
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder={translate("Enter your email")}
              />
              <p className="error-message text-danger font-weight-bold">{emailError}</p>
            </div>
            <div className="signUp-email">
              <label>{translate("Address")}</label>
              <AddressAutocomplete
                apiKey={googleMapApiKey()}
                onPlaceChanged={handlePlaceChanged}
                AddressSet={setAddress}
                placeHolder={translate("Enter a location")}
              />
            </div>
            {country_code && (
              <div className="signUp-phone">
                <label>{translate("Phone")}</label>
                <div className="input-group">
                  <div className="input-group-addon">
                    <select
                      className="text-center"
                      value={country}
                      disabled
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      {Object.keys(countries).map(
                        (code, i) =>
                          countries[code].code ==
                            country_code.toUpperCase() && (
                            <option
                              key={i}
                              value={countries[code].code}
                              selected={countries[code].selected ? true : false}
                            >
                              {countries[code].dial}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                  <input
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="number"
                    placeholder={translate("Enter your Phone")}
                    min="111"
                  />
                </div>
              </div>
            )}
            <div className="signUp-password">
              <label>{translate("Password")}</label>
              <input
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder={translate("Create a password")}
              />
              <p className="error-message text-danger font-weight-bold">{passwordError}</p>
            </div>
            <div className="signUp-password">
              <label>{translate("Confirm Password")}</label>
              <input
                required
                value={password_confirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                type="password"
                placeholder={translate("Confirm password")}
              />
              <p className="text-info font-weight-bold">{translate("Must be at least 8 characters")}</p>
            </div>

            {Object.keys(host_form).map((v, k) => (
              <div key={k} className="signUp-password mt-3">
                <label>
                  {translate(host_form[v].field_level)}{" "}
                  {host_form[v].validation === "required" && (
                    <span className="text-danger font-weight-bold">{translate("*")}</span>
                  )}
                </label>
                {host_form[v].type === "text" && (
                  <input
                    type="text"
                    name={host_form[v].field_name}
                    className="form-control"
                    value={dynamic_data[host_form[v].field_name] || ""}
                    onChange={(e) =>
                      handleInputChange(e, host_form[v].field_name)
                    }
                    placeholder={host_form[v].field_level}
                    required={host_form[v].validation === "required"}
                  />
                )}
                {host_form[v].type === "textarea" && (
                  <textarea
                    name={host_form[v].field_name}
                    className="form-control"
                    placeholder={host_form[v].field_level}
                    rows="3"
                    value={dynamic_data[host_form[v].field_name] || ""}
                    onChange={(e) =>
                      handleInputChange(e, host_form[v].field_name)
                    }
                    required={host_form[v].validation === "required"}
                  />
                )}
                {host_form[v].type === "file" && (
                  <>
                    <div
                      className="fileinput fileinput-new"
                      data-provides="fileinput"
                    >
                      <input
                        type="file"
                        name={host_form[v].field_name}
                        className="form-control"
                        onChange={(e) =>
                          handleInputChange(e, host_form[v].field_name)
                        }
                        placeholder={host_form[v].field_level}
                        required={host_form[v].validation === "required"}
                      />
                    </div>
                    {host_form.errors &&
                      host_form.errors[host_form[v].field_name] && (
                        <span className="text-danger font-weight-bold">
                          {host_form.errors[host_form[v].field_name]}
                        </span>
                      )}
                  </>
                )}
              </div>
            ))}

            <div className="signUp-password">
              <ExtensionApi
                googlerecaptcha2="google-recaptcha2"
                CaptchaPassed={setIsCaptchaPassed}
              />
              {/* {isCaptchaPassed && (
                <p className="text-success">{translate("Recaptcha passed!")}</p>
              )} */}
            </div>

            <div className="signUp-btn mt-3">
              <button
                disabled={!isCaptchaPassed || isButtonDisabled}
                onClick={handleRegister}
              >
                {translate("Get Started")}
              </button>
            </div>
            <div className="google-signUp">
              {/* <button>
                <img src="/assets/icons/googleIcon.png" alt="..." />
                <p>Sign in with Google</p>
              </button> */}
            </div>
            <div className="signIn-link">
              <p>
                {translate("Already have an account?")}{" "}
                <Link
                  style={{ fontSize: "16px", color: "#45AAF0" }}
                  to="/owner-login"
                >
                  {translate("Sign In")}
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6  d-none d-lg-block d-md-block">
          <RegisterImage type={"register"} />
        </div>
      </div>
    </div>
  );
}

export default RegisterOwner;
