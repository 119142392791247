import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  boat: "",
};

export const boatSlice = createSlice({
  name: "boat",
  initialState,
  reducers: {
    addBoat: (state, action) => {
      state.boat = action.payload;
    },
  },
});

export const { addBoat } = boatSlice.actions;

export default boatSlice.reducer;
