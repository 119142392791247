import React, { useEffect, useState } from "react";
import "./DahboardNavbar.css";
import AvatarImg from "../../Components/Avatar/AvatarImg";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";

function DahboardNavbar() {
  const { langData, fetchLangData, translate } = useLanguage();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  );
  const [languages, setLanguages] = useState("");
  const [loading, setLoading] = useState(false);
  const handleLanguage = (newLanguage) => {
    setSelectedLanguage(newLanguage);
    fetchLangData(newLanguage);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "language");
      setLanguages(res?.data?.languages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div id="navbar" className="navbar-sticky p-0">
      <div className="narbar-wrapper">
        <div className="input-group mb-3 search-input">
          {/* <input
            type="text"
            className="form-control"
            placeholder="Search........"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <div className="input-group-append">
            <span
              className="input-group-text"
              id="basic-addon2"
              style={{ background: "#45AAF0" }}
            >
              <BiSearch style={{ color: "#FFF" }} />
            </span>
          </div> */}
        </div>

        <div className="nav-contant">
          {/* <img alt="" src="/assets/icons/countryIcon.png" /> */}
          <div className="language-dropdown">
            <select
              style={{ color: "black" }}
              id="language-select"
              value={selectedLanguage}
              onChange={(e) => handleLanguage(e.target.value)}
            >
              {languages &&
                languages?.map((lan, i) => (
                  <option key={lan?.code} value={lan?.code}>
                    {lan?.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="navbar-userIcon">
          {/* <StyledEngineProvider injectFirst> */}
          <AvatarImg />
          {/* </StyledEngineProvider>, */}
          {/* <IoMdNotifications  style={{color:"#C4C4C4", fontSize:"30px"}}/>
          <img src="/assets/icons/iconUser.png" alt="..." /> */}
        </div>
      </div>
    </div>
  );
}

export default DahboardNavbar;
