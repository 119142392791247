import React from "react";
import { CircularProgress, Stack } from "@mui/material";

function Loader({ size = 100, height = '100' }) {
  return (
    // <Stack alignItems="center" justifyContent="center">
    //   <CircularProgress size={size} />
    // </Stack>
    <Stack height={height+'vh'} alignItems="center" justifyContent="center">
      <CircularProgress size={size} sx={{ color: "#8bc53e" }} />
    </Stack>
  );
}

export default Loader;
