import React, { useState, useEffect } from "react";
import "./AddNewProperty.css";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { CircularProgress, Stack } from "@mui/material";
import { BiCheck } from "react-icons/bi";
import Loader from "../../Components/Loader";
import { useLanguage } from "../../utils/LanguageContext";

function AddProperty() {
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [ori_image, setOriImage] = useState(null);
  const [ori_image1, setOriImage1] = useState(null);
  const [ori_image2, setOriImage2] = useState(null);
  const [ori_image3, setOriImage3] = useState(null);
  const [ori_image4, setOriImage4] = useState(null);
  const { langData, fetchLangData, translate } = useLanguage();

  let initialState = {
    name: "",
    property_type: "",
    location: "",
    phone: "",
    phone_call_time: "",
    price: 0,
    description: "",
    map_url: "",
    discount: 0,
    star: null,
    amenities: [],
    extra_features: [],
    is_complete: null,
    images: [],
  };

  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [feature, setFeature] = useState("");
  const [state, setState] = useState(initialState);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/property/create");
      setLoading(false);
      if (res.success) {
        setData(res.data);
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const amenities = state?.amenities?.reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});

  const handleAmenities = (arg) => {
    if (amenities[arg]) {
      let foundIdx = state.amenities.findIndex((item) => item == arg);
      let copy = JSON.parse(JSON.stringify(state.amenities));
      copy.splice(foundIdx, 1);
      setState((prev) => ({ ...prev, amenities: copy }));
    } else {
      setState((prev) => ({
        ...prev,
        amenities: [...prev.amenities, arg],
      }));
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name == "feature") {
      setFeature(value);
    }
  };
  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && feature.trim() !== "") {
      setState((prev) => ({
        ...prev,
        extra_features: [...prev.extra_features, feature.trim()],
      }));
      setFeature("");
    }
  };
  const handleTagRemove = (arg) => {
    const updatedTags = state.extra_features.filter((tag) => tag !== arg);
    setState((prev) => ({ ...prev, extra_features: updatedTags }));
  };

  const handleSubmit = async () => {
    if (!state.name) {
      Toaster("info", "Enter Property Name");
      return;
    }
    if (!state.property_type) {
      Toaster("info", "Enter Property Type");
      return;
    }
    if (state.location === 0) {
      Toaster("info", "Select Location");
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", state.name);
      if (ori_image) {
        formData.append("image", ori_image);
      }
      formData.append("property_type", state.property_type);
      formData.append("location", state.location);
      formData.append("phone", state.phone);
      formData.append("phone_call_time", state.phone_call_time);
      if (state.is_complete == 1) {
        formData.append("price", state.price);
      }
      formData.append("discount", state.discount);
      formData.append("description", state.description);
      formData.append("map_url", state.map_url);
      if (state.star != null) {
        formData.append("star", state.star);
      }
      formData.append("is_complete", state.is_complete);

      state.extra_features.forEach((feature, index) => {
        formData.append(`extra_features[${index}]`, feature);
      });

      state.amenities.forEach((amenity, index) => {
        formData.append(`amenities[${index}]`, amenity);
      });
      // Append other form fields as needed
      const images = [ori_image1, ori_image2, ori_image3, ori_image4];
      images.forEach((image, index) => {
        if (image) {
          formData.append(`images[${index}]`, image);
        }
      });

      // Send a POST request to the server to submit the data
      const res = await makeHttpRequest(
        "POST",
        "owner/property/store",
        formData
      );
      setLoading(false);

      if (res.success) {
        // Handle success (e.g., redirect to a success page)
      } else {
        // Handle error (e.g., display an error message)
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const options_tag = [];
  for (let i = 0; i <= data?.star; i++) {
    options_tag.push(`<option key=${i} value=${i}> ${i}</option>`);
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="addProperty-container">
          <div className="addproperty-btnWrapper">
            <button className="addProperty-addBtn">
              <Link to="/all-property">
                <BsArrowLeft />
              </Link>
            </button>
            <h2>{translate("Create New Property")}</h2>
          </div>

          <div className="addProperty-contentBg">
            <div className="addProperty-detailHeading">
              <h2>{translate(data?.page_title)}</h2>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12 col-12">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 mt-3 addProperty-name-inputs">
                    <label className="addProperty-inputLabel">
                      {translate("Property Name")}
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Property Name"
                      required
                      name="name"
                      value={state.name}
                      onChange={(e) =>
                        setState({ ...state, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-3 addProperty-name-inputs">
                    <label className="addProperty-inputLabel">
                      {translate("Property Type")}
                      <span>*</span>
                    </label>
                    <div
                      className="addProperty-dropdown"
                      style={{ width: "100%" }}
                    >
                      <select
                        name="property_type"
                        value={state.property_type}
                        onChange={(e) => {
                          const selectedType = e.target.value;
                          const selectedDataType =
                            e.target.options[e.target.selectedIndex].dataset
                              .type;
                          setState({
                            ...state,
                            property_type: selectedType,
                            is_complete: selectedDataType,
                          });
                        }}
                      >
                        <option key={0} value="" data-type={0}>
                          {" "}
                          {translate("Select One")}{" "}
                        </option>
                        {data?.property_types?.map((item, i) => (
                          <option
                            key={item?.id}
                            value={item?.id}
                            data-type={item.type}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-4 addProperty-inputs">
                    <label className="addProperty-inputLabel">
                      {translate("Location")}
                      <span>*</span>
                    </label>
                    <div
                      className="addProperty-dropdown"
                      style={{ width: "100%" }}
                    >
                      <select
                        name="location"
                        value={state.location}
                        onChange={(e) =>
                          setState({ ...state, location: e.target.value })
                        }
                      >
                        {data?.locations?.map((item, i) => (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-4 addProperty-name-inputs">
                    <label className="addProperty-inputLabel">
                      {translate("Google Map Embbed URL")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={translate("Google Map Embbed URL")}
                      required
                      name="map_url"
                      value={state.map_url}
                      onChange={(e) =>
                        setState({ ...state, map_url: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12 mt-3">
                <label className="addProperty-inputLabel">
                  {translate("Upload Image")}
                </label>
                <ImageUploader
                  selectedImage={image}
                  onImageChange={setImage}
                  onChangeEvent={setOriImage}
                  // onFileChange={setImage}
                />
              </div>
            </div>

            <div className="row">
              {state.is_complete === "0" && data?.star > 0 && (
                <div className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs">
                  <label className="addProperty-inputLabel">
                    {translate("Select Stars")}
                    <span>*</span>
                  </label>
                  <div
                    className="addProperty-dropdown"
                    style={{ width: "100%" }}
                  >
                    <select
                      name="star"
                      value={state.star}
                      onChange={(e) =>
                        setState({ ...state, star: e.target.value })
                      }
                    >
                      {Array.from(
                        { length: data?.star },
                        (_, i) =>
                          i !== 0 && (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                </div>
              )}
              {state.is_complete === "1" && (
                <div className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs">
                  <label className="addProperty-inputLabel">
                    {translate("Property Price")}
                    <span>*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      aria-describedby="basic-addon2"
                      value={state.price}
                      onChange={(e) =>
                        setState({ ...state, price: e.target.value })
                      }
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        id="basic-addon2"
                        style={{ background: "#45AAF0", color: "#FFF" }}
                      >
                        {data?.curr_name}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs">
                <label className="addProperty-inputLabel">
                  {translate("Discount")}
                </label>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Discount"
                    aria-describedby="basic-addon2"
                    name="discount"
                    value={state.discount}
                    onChange={(e) =>
                      setState({ ...state, discount: e.target.value })
                    }
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      style={{ background: "#45AAF0", color: "#FFF" }}
                    >
                      {translate("%")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs">
                <label className="addProperty-inputLabel">
                  {translate("Extra Features")}
                </label>

                <input
                  type="text"
                  className="form-control"
                  required
                  name="feature"
                  value={feature}
                  onChange={handleInput}
                  onKeyDown={handleInputKeyDown}
                />
                <p className="addNewBoat-xxtraFeature">
                  {translate("Write features then press enter")}
                </p>

                <div
                  className="tag-container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "10px",
                  }}
                >
                  {state.extra_features.map((tag, i) => (
                    <div
                      className="tag"
                      style={{
                        background: "#8cc5ed",
                        marginRight: "5px",
                        width: "fit-content",
                        borderRadius: "4px",
                        padding: "3px",
                      }}
                      key={i}
                    >
                      <span style={{ color: "white" }}>{tag}</span>
                      <span
                        style={{
                          color: "red",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        className="tag-remove"
                        onClick={() => handleTagRemove(tag)}
                      >
                        &#10006;
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              {/* 
            <div
              className="col-lg-4 col-md-12 col-12 mt-4 addProperty-name-inputs"
              style={{ paddingTop: "40px" }}
            >
              <label
                className="addProperty-inputLabel"
                style={{ marginRight: "20px" }}
              >
                Complete Booking
              </label>

              <input
                type="checkbox"
                placeholder="Whole Book"
                name="is_complete"
                checked={state.is_complete}
                onChange={(e) =>
                  setState({ ...state, is_complete: e.target.value })
                }
              />
            </div> */}
            </div>

            <div className="addProperty-amenitiesBg">
              <div className="addProperty-amenities">
                <h2>{translate("Amenities")}</h2>
              </div>
              <div className="row">
                {data?.amenities?.map((item) => {
                  return (
                    <div className="col-lg-3 col-md-6 col-12 p-1">
                      <button
                        className={
                          amenities[item.id]
                            ? "addProperty-tabs addProperty-selected-tab"
                            : "addProperty-tabs"
                        }
                        onClick={() => handleAmenities(item.id)}
                      >
                        {item?.name}
                        {amenities[item.id] && (
                          <BiCheck
                            style={{
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          />
                        )}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="addProperty-amenitiesBg">
              <div className="row">
                <div className="col-xs-12 addProperty-message-input">
                  <label className="addProperty-inputLabel">
                    {translate("Description")}
                  </label>
                  <textarea
                    className="form-control"
                    placeholder=""
                    required
                    rows={10}
                    value={state.description}
                    onChange={(e) =>
                      setState({ ...state, description: e.target.value })
                    }
                  ></textarea>
                </div>
                <div className="col-lg-12 col-md-12 col-12 addProperty-inputs mt-3">
                  <label className="addProperty-inputLabel">
                    {translate("Image Gallery")}
                  </label>
                  <div className="row">
                    <div className="col-lg-3 col-md-12 col-12 mt-2">
                      <ImageUploader
                        selectedImage={image1}
                        onImageChange={setImage1}
                        onChangeEvent={setOriImage1}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-12 mt-2">
                      <ImageUploader
                        selectedImage={image2}
                        onImageChange={setImage2}
                        onChangeEvent={setOriImage2}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-12 mt-2">
                      <ImageUploader
                        selectedImage={image3}
                        onImageChange={setImage3}
                        onChangeEvent={setOriImage3}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-12 mt-2">
                      <ImageUploader
                        selectedImage={image4}
                        onImageChange={setImage4}
                        onChangeEvent={setOriImage4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="addProperty-submitBtn">
                  {loading && (
                    <Stack alignItems="center" justifyContent="center">
                      <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
                    </Stack>
                  )}
                  {!loading && (
                    <button onClick={handleSubmit} style={{ color: "#fff" }}>
                      {translate("Add New Property")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AddProperty;
