import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import makeHttpRequest from "../utils/api";
import { useEffect } from "react";
import { useState } from "react";

function ExtensionApi(requested) {
  /**
   * For captcha v2
   * googlerecaptcha2={'google-recaptcha2'}
   *
   */
  // console.log(requested);
  const [extension, setExtension] = useState("");
  const handleRecaptchaChange = (value) => {
    const isCaptchaPassed = Boolean(value);
    requested.CaptchaPassed(isCaptchaPassed);
  };
  useEffect(() => {
    const checkExtension = async () => {
      try {
        const res = await makeHttpRequest("GET", "extension");
        setExtension(res.data);
      } catch (error) {
        console.error("Error is:", error);
      }
    };
    checkExtension();
  }, []);

  return (
    <>
      {requested.googlerecaptcha2 == "google-recaptcha2" &&
        extension.googlerecaptcha2?.status == 1 ? (
          <ReCAPTCHA
            // sitekey={"6LeTdR0pAAAAAEbCy9WVdgJUHi40NyRbro41_PoJ"}
            sitekey={extension.googlerecaptcha2?.shortcode?.sitekey?.value}
            onChange={handleRecaptchaChange}
          />
        ):(
          requested.CaptchaPassed(true)
        )}
    </>
  );
}

export default ExtensionApi;
