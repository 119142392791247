import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { Link } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";
import { AiOutlineCheck } from "react-icons/ai";
import "./WithdrawMoney.css";
import { light } from "@mui/material/styles/createPalette";
import { useLanguage } from "../../utils/LanguageContext";


function WithdrawPreview() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();


  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/withdraw/preview");
      setData(res.data);
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  async function handleWithdraw() {
    try {
      let val = document.getElementById("amount").value;

      setLoading(true);
      // const res = await makeHttpRequest("POST", `owner/withdraw`, {
      //   amount: val,
      //   method_code: id,
      //   currency: currency,
      // });
      // setLoading(false);
      // if (res.success) {
      //   Toaster("success", res?.message);
      // } else {
      //   Toaster("error", "Something went wrong");
      // }
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div className="withdrawMoney-container">
      <div className="withdrawMoney-Bg">
        <div className="row pb-4">
          <div className="col-lg-5 col-md-6 col-12">
            {/* <img src={image} alt={name} width={400} height={400} /> */}
            {data?.withdraw?.method?.description}
          </div>
          <div className="col-lg-7 col-md-6 col-12 p-5">
            <div className="withdrawMoney-contentBg mt-3">
              <h1>{translate('Method')}:</h1>
              <p>{translate('hello')}</p>
            </div>

            <div className="withdrawMoney-contentBg mt-3">
              <h1>{translate('Limit')}:</h1>
              <p>{translate('12 -22- EUR')}</p>
            </div>

            <div className="withdrawMoney-contentBg mt-3">
              <h1>{translate('CHARGE')}:</h1>
              <p>
                <i style={{ fontWeight: 50 }}>{translate('Not Apply')}</i>
              </p>
            </div>

            <div className="withdrawMoney-contentBg mt-3">
              <h1>{translate('PROCESSING TIME')}</h1>
              <p>{translate('22')}</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 pr-5 pl-5">
            <div
              className=" row withdrawMoney-amountBg"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-5 col-md-6 col-12 withdrawMoney-amountHeading">
                <h1>{translate('ENTER AMOUNT')}:</h1>
              </div>

              <div className="col-lg-7 col-md-6 col-12">
                <div className="input-group withdrawMoney-input ">
                  <input
                    id="amount"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value.replace(
                        /^\.|[^\d\.]/g,
                        ""
                      );
                    }}
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      style={{ background: "#45AAF0", color: "#FFF" }}
                    >
                      $$$$
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="withdrawMoney-btn mt-2">
          <button onClick={handleWithdraw}>
            <Link>{translate('WITHDRAW NOW')}</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WithdrawPreview;
