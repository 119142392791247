import React, { useEffect, useState } from "react";
import "./BoatPage.css";
import Navbar from "../../Components/Navbar/Navbar";
import BoatSearchEngine from "../../Components/BoatSearchEngine/BoatSearchEngine";
import { BiCheck } from "react-icons/bi";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";
import { BsArrowRightShort, BsFillStarFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
import { FaMapMarkerAlt } from "react-icons/fa";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import Footer from "../../Components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import makeHttpRequest from "../../utils/api";
import { addBoat } from "./boatSlice";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { Carousel } from "react-bootstrap";
// import Carousel from "react-grid-carousel";
import {
  Carousel,
  Card as CardR,
  Stack as StackR,
  Button as ButtonR,
} from "react-bootstrap";
import Wishlist from "../../Components/Wishlist";
import { useLanguage } from "../../utils/LanguageContext";

const customIndicatorStyles = {
  background: "#45AAF0",
  borderRadius: "50%",
  width: "8px",
  height: "8px",
  display: "inline-block",
  margin: "20px 5px 0px",
};

function BoatPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, slug } = useParams();
  const location = useLocation();
  // const [boat, setBoat] = useState("");
  const { boat } = useSelector((state) => state.boat);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filter_location, setFilterLocation] = useState("");
  const [filter_boatTypeId, setFilterBoatTypeId] = useState("");

  const [boats, setBoats] = useState("");
  const [multi_image_slider, setMultiImageSlider] = useState([]);
  const { langData, fetchLangData, translate } = useLanguage();

  useEffect(() => {
    if (location.state) {
      const res = location.state;
      setFilterLocation(res?.data?.request?.location);
      setFilterBoatTypeId(res?.data?.request?.boatType);
      setBoats(res.data);
      // title of page
      
      dispatch(addBoat(res.data));
    } else if (id && slug) {
      getData();
    } else {
      getBoats();
    }
  }, [id]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `search-boat-type/${id}/${slug}`
      );
      setBoats(res.data);
      dispatch(addBoat(res.data));

      const objectsArray = Object.values(res?.data?.boat_types);
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      let subarraySize = 0;
      if (isMobile) {
        subarraySize = 1;
      } else {
        subarraySize = 3;
      }
      const newMultiImageSlider = [];
      for (let i = 0; i < objectsArray.length; i += subarraySize) {
        const subarray = objectsArray.slice(i, i + subarraySize);
        newMultiImageSlider.push(subarray);
      }

      setMultiImageSlider(newMultiImageSlider);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const getBoats = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "boat");
      setBoats(res.data);
      dispatch(addBoat(res?.data));

      const objectsArray = Object.values(res?.data?.boat_types);
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      let subarraySize = 0;
      if (isMobile) {
        subarraySize = 1;
      } else {
        subarraySize = 3;
      }
      const newMultiImageSlider = [];
      for (let i = 0; i < objectsArray.length; i += subarraySize) {
        const subarray = objectsArray.slice(i, i + subarraySize);
        newMultiImageSlider.push(subarray);
      }
      setMultiImageSlider(newMultiImageSlider);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  function generateSlug(inputString) {
    const slug = inputString.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    return slug.replace(/^-+|-+$/g, "");
  }

  const handleDetails = (item) => {
    if (slug) {
      navigate(`/boat/${item?.id}/${slug}/details`);
    } else {
      const resultSlug = generateSlug(item.name);
      navigate(`/boat/${item?.id}/${resultSlug}/details`);
    }
  };

  const handleChildData = (data) => {
    setBoats(data);
    dispatch(addBoat(data));
  };

  return (
    <Box>
      {loading && (
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && (
        <>
          <Navbar />
          <div className="container">
            <div className="main-heading boat-heading">
              <h1>{boat?.page_title}</h1>
            </div>
            <div className="mt-4">
              <BoatSearchEngine
                boat_types={boats.boat_types}
                locations={boats.locations}
                onDataReceived={handleChildData}
                locationId={filter_location}
                boat_typeId={filter_boatTypeId}
              />
            </div>

            <div className="amenities-boat-heading">
              <div className="amenities-boat-heading-topIcon">
                <img src="/assets/icons/aa1.png" alt="..." />
              </div>
              <div className="amenities-boat-heading-bottomIcon">
                <img src="/assets/icons/aa2.jpeg" alt="..." />
              </div>
              <h1>{translate(boat?.boat?.heading)}</h1>
              <p>{translate(boat?.boat?.sub_heading)}</p>
            </div>

            <div>
              <div className="container-fluid">
                <Carousel indicators={false}>
                  {multi_image_slider?.map((items, index) => (
                    <Carousel.Item>
                      <StackR
                        direction="horizontal"
                        className="h-100 justify-content-center align-items-center"
                        gap={3}
                      >
                        {items?.map((item) => (
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/boat/search-location/${item?.id}/${item?.slug}`}
                          >
                            <CardR
                              style={{
                                width: "18rem",
                                border: "none",
                              }}
                            >
                              <CardR.Body>
                                <CardR.Img
                                  variant="top"
                                  src={item.image}
                                  style={{ height: "200px" }}
                                />
                                <CardR.Title
                                  style={{
                                    textAlign: "center",
                                    padding: "5px",
                                    textDecoration: "none",
                                  }}
                                >
                                  {item.name}
                                </CardR.Title>
                              </CardR.Body>
                            </CardR>
                          </Link>
                        ))}
                      </StackR>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>

            <div className="ship-list-wrapper">
              <div className="ship-list-content">
                <div className="ship-list-topIcon">
                  <img src="/assets/icons/aa1.png" alt="..." />
                </div>
                <div className="ship-list-bottomIcon">
                  <img src="/assets/icons/aa2.jpeg" alt="..." />
                </div>
                <h1>{translate("Our featured ships list")}</h1>
                <p>{translate("Write detail here")}</p>
              </div>
              <div className="ship-list-button">
                {/* <Button variant="contained">
                  View All
                  <BsArrowRightShort
                    style={{ fontSize: "15px", marginLeft: "10px" }}
                  />
                </Button> */}
              </div>
            </div>

            <div className="row class1 mb-5">
              {boat?.boats?.data?.length == 0 ? (
                <Stack
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="#fff"
                  border="1px solid #7c7777"
                >
                  <h2>{translate(boat?.empty_message)}</h2>
                </Stack>
              ) : (
                boat?.boats?.data?.map((item, index) => {
                  return (
                    <div className="col-lg-4 mt-4">
                      <Card style={{ borderRadius: "10px" }}>
                        <CardMedia>
                          <div
                            id={`carouselExampleIndicators_${index}`}
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-indicators">
                              {item?.thumbnail?.map((image, i) => (
                                <button
                                  type="button"
                                  data-bs-target={`#carouselExampleIndicators_${index}`}
                                  data-bs-slide-to={i}
                                  className={i === 0 ? "active" : ""}
                                  aria-current={i === 0 ? "true" : "false"}
                                  key={i}
                                ></button>
                              ))}
                            </div>
                            <div className="carousel-inner">
                              {item?.thumbnail?.map((image, i) => (
                                <div
                                  className={`carousel-item ${i === 0 ? "active" : ""
                                    }`}
                                  key={i}
                                >
                                  <img
                                    src={image}
                                    style={{ width: '100%', height: '100%' }}
                                    // className="d-block w-100"
                                    alt={`Slide ${i + 1}`}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="boat-card-offer">
                              {item.discount>0 && (
                              <h4>{showAmount(item?.discount)} {translate("% off")}</h4>
                              )}
                              <Wishlist id={item.id} type={"boat"} />
                            </div>
                          </div>
                        </CardMedia>
                        <CardContent className="">
                          <div className="shipList-ranking">
                            <Typography className="shiplist-card-location">
                              <p>
                                <FaMapMarkerAlt
                                  style={{
                                    color: "#8BC53E",
                                    marginRight: "7px",
                                    fontSize: "15px",
                                  }}
                                />
                                <span>{item.location}</span>
                              </p>
                            </Typography>
                            <Typography className="shiplist-card-location">
                              <p>
                                <BsFillStarFill
                                  style={{
                                    color: "#8BC53E",
                                    marginRight: "7px",
                                    fontSize: "15px",
                                  }}
                                />
                                <span>
                                  {showAmount(item.rating)} ({item.review})
                                  {translate("Reviews")}
                                </span>
                              </p>
                            </Typography>
                          </div>

                          <div>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="shiplist-card-heading"
                            >
                              <h1>{item.name}</h1>
                              <h6>{item?.boat_type?.name}</h6>
                              <p
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "light",
                                }}
                              >
                                {item?.amenities?.map((a, index) => (
                                  <span>
                                    {" "}
                                    <BiCheck />
                                    {a.name}{" "}
                                  </span>
                                ))}
                              </p>
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="shiplist-card-discription"
                            >
                              {/* <p>{item.guests} Guests</p>
                              <p>{item.bed} Bed</p>
                              <p>{item.cabin} Cabin</p>
                              <p>{item.bathroom} bathroom</p> */}
                            </Typography>
                          </div>

                          <Typography className="shiplist-card-deatil-button">
                            <h3>
                              {boat?.curr_sym}
                              {showAmount(item?.price)}{" "}
                              <span>/{translate(item.label)}</span>
                            </h3>
                            <button onClick={() => handleDetails(item)}>
                              {translate("View Detail")}
                            </button>
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          {/* <div className="boatPage-fastBoatCards">
            <FastBoatCards />
          </div> */}

          <div className="boatPage-footer">
            <Footer />
          </div>
        </>
      )}
    </Box>
  );
}

export default BoatPage;
