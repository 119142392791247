import makeHttpRequest from "./api";

export function googleMapApiKey() {
  return "AIzaSyD6uW4KJEDNb3oVIKmNN9Alw0lVwrh_Euw";
}

export function googleLoginApiKey() {
  return "435847607589-g32qk8vf5j06frk5l6bbm4lq06banr5s.apps.googleusercontent.com";
}

export function limitCharacters(inputString, maxLength = 10) {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength);
  }
  return inputString;
}

export function setStateBasedOnDomain() {
  try {
    const parsedUrl = new URL(window.location.href);
    const domain = parsedUrl.hostname.split(".").slice(-2).join(".");
    switch (domain) {
      case "caprieasy.com":
        return "en";
      case "caprieasy.it":
        return "it";
      case "localhost":
        return "it";
      default:
        return "en";
    }
  } catch (error) {
    console.error("Error parsing URL:", error.message);
    return null;
  }
}


export function title(title = null){
  if(title == null){
    return 'Capri Easy Booking Online';
  }
  return 'Capri Easy Booking Online - '+title;
}

export const imageUrlToBase64 = async (url) => {
  const data ={path:url}
  let res = await makeHttpRequest('POST', 'image', data);
  return res?.data?.prefix+res?.data?.image;
  // const response = await fetch(url);
  //   const blob = await response.blob();
  //   return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onloadend = () => resolve(reader.result);
  //       reader.onerror = reject;
  //       reader.readAsDataURL(blob);
  //   });
};