import React, { useEffect, useState } from "react";
import classes from "./UserProfileEdit.module.css";
import Footer from "../../Components/Footer/Footer";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import Navbar from "../../Components/Navbar/Navbar";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";
import { CircularProgress, Stack } from "@mui/material";
import Toaster from "../../Components/Toaster";
import { addLeadingZero } from "../../utils/addLeadingZero";

function UserProfileEdit() {
  const [fullname, setfullname] = useState("Jane Cooper");
  const [email, setemail] = useState("jane@example.com");
  const [phonenumber, setphonenumber] = useState("+123456789");
  const [address, setaddress] = useState("");
  const [stat, setStat] = useState({
    total_tickets: "",
    total_booked: "",
    total_pending_review: "",
  });
  const [governmentId, setgovernmentId] = useState("1234567890");
  const [emergencyContact, setemergencyContact] = useState("+21304560789");
  const { langData, fetchLangData, translate } = useLanguage();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "user/profile-setting");
      setfullname(response?.data?.user?.name);
      setemail(response?.data?.user?.email);
      setphonenumber(response?.data?.user?.phone);
      setaddress(response?.data?.user?.address?.address);
      setStat({
        total_tickets: response?.data?.total_tickets,
        total_booked: response?.data?.total_booked,
        total_pending_review: response?.data?.total_pending_review,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const saveData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("POST", "user/profile-setting", {
        address: address,
      });
      setLoading(false);
      Toaster("success", response?.data?.message);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      {loading && (
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}

      <div className={classes["page-container"]}>
        {!loading && (
          <div className="row">
            <div className={`col-lg-3 col-md-3 col-12 ${classes.profileCard}`}>
              <div className={`container ${classes['user_profile_info']}`}>
                <div className={`${classes['profile-container']} mt-2`}>
                  {/* <img
                    src="/assets/icons/profilePic.png"
                    alt="userProfilePic"
                    className={classes['profile-pic']}
                  /> */}
                  <span className={classes["Save-icon"]}>
                    {/* <img
                      src="/assets/icons/profileEdit.png"
                      alt="userProfileEdit"
                    /> */}
                  </span>
                </div>
                <div className={`container ${classes['profile_info']} mt-3`}>
                  <b style={{ fontFamily: "Montserrat" }}>{fullname}</b>
                  <p
                    style={{ fontFamily: "Montserrat" }}
                    className={classes["p-tag-color"]}
                  >
                    {email}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-12">

              {/* <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="container profileCard ml-4">
                  <div className={`container ${classes['profile-details']}`}>
                    <div className={`${classes.ticketCards} mt-2`}>
                      <img  
                        src="/assets/icons/totalTicket.png"
                        alt="totalTicketPic"
                        className={classes['profile-pic']}
                      />
                    </div>
                    <div className={`container ${classes.ticket_info} mt-4`}>
                      <b>
                        <span style={{ fontFamily: "Montserrat" }}>
                          {addLeadingZero(stat?.total_tickets)}
                        </span>
                      </b>
                      <p
                        className={`${classes['p-tag-color']} mb-0 mt-1`}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Total Tickets")}
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="container profileCard mx-3">
                  <div className={`container ${classes['profile-details']}`}>
                    <div className={`${classes.ticketCards} mt-2`}>
                      <img
                        src="/assets/icons/totalBookedTicket.png"
                        alt="totalBookedTicketPic"
                        className={classes['profile-pic']}
                      />
                    </div>
                    <div className={`container ${classes.ticket_info} mt-4`}>
                      <b>
                        <span style={{ fontFamily: "Montserrat" }}>
                          {addLeadingZero(stat?.total_booked)}
                        </span>
                      </b>
                      <p
                        className={`${classes['p-tag-color']} mb-0 mt-1`}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Total Booked")}
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="container profileCard">
                  <div className={`container ${classes['profile-details']}`}>
                    <div className={`${classes.ticketCards} mt-2`}>
                      <img
                        src="/assets/icons/totalPendingReviews.png"
                        alt="totalPendingReviewsPic"
                        className={classes['profile-pic']}
                      />
                    </div>
                    <div className={`container ${classes.ticket_info} mt-4`}>
                      <b>
                        <span style={{ fontFamily: "Montserrat" }}>
                          {addLeadingZero(stat?.total_pending_review)}
                        </span>
                      </b>
                      <p
                        className={`${classes['p-tag-color']} mb-0 mt-1`}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Pending Reviews")}
                      </p>
                    </div>
                  </div>
                </div>
                </div>
              </div> */}
              <div className={classes.profilee}>
                <div className={`container ${classes.profileCard} ml-4`}>
                  <div className={`container ${classes['profile-details']}`}>
                    <div className={`${classes.ticketCards} mt-2`}>
                      <img  
                        src="/assets/icons/totalTicket.png"
                        alt="totalTicketPic"
                        className={classes['profile-pic']}
                      />
                    </div>
                    <div className={`container ${classes.ticket_info} mt-4`}>
                      <b>
                        <span style={{ fontFamily: "Montserrat" }}>
                          {addLeadingZero(stat?.total_tickets)}
                        </span>
                      </b>
                      <p
                        className={`${classes['p-tag-color']} mb-0 mt-1`}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Total Tickets")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`container ${classes.profileCard} mx-3`}>
                  <div className={`container ${classes['profile-details']}`}>
                    <div className={`${classes.ticketCards} mt-2`}>
                      <img
                        src="/assets/icons/totalBookedTicket.png"
                        alt="totalBookedTicketPic"
                        className={classes['profile-pic']}
                      />
                    </div>
                    <div className={`container ${classes.ticket_info} mt-4`}>
                      <b>
                        <span style={{ fontFamily: "Montserrat" }}>
                          {addLeadingZero(stat?.total_booked)}
                        </span>
                      </b>
                      <p
                        className={`${classes['p-tag-color']} mb-0 mt-1`}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Total Booked")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`container ${classes.profileCard}`}>
                  <div className={`container ${classes['profile-details']}`}>
                    <div className={`${classes.ticketCards} mt-2`}>
                      <img
                        src="/assets/icons/totalPendingReviews.png"
                        alt="totalPendingReviewsPic"
                        className={classes['profile-pic']}
                      />
                    </div>
                    <div className={`container ${classes.ticket_info} mt-4`}>
                      <b>
                        <span style={{ fontFamily: "Montserrat" }}>
                          {addLeadingZero(stat?.total_pending_review)}
                        </span>
                      </b>
                      <p
                        className={`${classes['p-tag-color']} mb-0 mt-1`}
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Pending Reviews")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row mt-3">
          {!loading && (
            <div className="col-lg-3 col-md-3 col-12">
              <UserSideNavbar />
            </div>
          )}
          {!loading && (
            <div className="col-lg-9 col-md-9 col-12">
              <div className={classes["supportTicket-bg"]}>
                {/* <div className="supportTicket-btnWrapper">
                  <h2> Profile</h2>
                </div> */}
                {/* <div className="container w-75 personalInfoContainer profileCard ml-5">
                <div className="container"> */}
                <div
                  className="table-responsive"
                  style={{ borderRadius: "10px" }}
                >
                  <div className={classes["supportTicket-wrapper"]}>
                    <form>
                      <p
                        className="h1 ml-4 my-3"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Personal Info")}
                      </p>
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="fullName"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Full name")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            disabled
                            type="text"
                            className={`${classes['form-control-plaintext']} pl-4`}
                            id="fullName"
                            onChange={(e) => {
                              setfullname(e.target.value);
                            }}
                            style={{ fontFamily: "Montserrat" }}
                            value={fullname}
                          />
                          &nbsp;{" "}
                          <a
                            href="#"
                            className={`${classes.anchorTagcolor} ml-2 mt-2`}
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {/* {translate('Save')} */}
                          </a>
                        </div>
                      </div>
                      <hr className="hr" />
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="email"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Email address")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            disabled
                            type="email"
                            className={`${classes['form-control-plaintext']} pl-4`}
                            id="email"
                            onChange={(e) => {
                              setemail(e.target.value);
                            }}
                            value={email}
                            style={{ fontFamily: "Montserrat" }}
                          />
                          &nbsp;{" "}
                          <a
                            className={`${classes.anchorTagcolor} ml-2 mt-2`}
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {/* {translate('Save')} */}
                          </a>
                        </div>
                      </div>
                      <hr className="hr" />
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="phoneNumber"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Phone number")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            disabled
                            type="text"
                            className={`${classes['form-control-plaintext']} pl-4`}
                            id="phoneNumber"
                            onChange={(e) => {
                              setphonenumber(e.target.value);
                            }}
                            value={phonenumber}
                            style={{ fontFamily: "Montserrat" }}
                          />
                          &nbsp;{" "}
                          <a
                            href="#"
                            className={`${classes.anchorTagcolor} ml-2 mt-2`}
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {/* {translate('Save')} */}
                          </a>
                        </div>
                      </div>
                      <hr className="hr" />
                      <div className="form-group col">
                        <b>
                          <label
                            htmlFor="address"
                            className="col-sm-12 col-form-label"
                            style={{ fontFamily: "Montserrat" }}
                          >
                            {translate("Address")}
                          </label>
                        </b>
                        <br />
                        <div className="col-sm-12 d-flex justify-content-between m-0">
                          <input
                            type="text"
                            className={`${classes['form-control-plaintext']} pl-4`}
                            id="address"
                            onChange={(e) => {
                              setaddress(e.target.value);
                            }}
                            value={address}
                            style={{ fontFamily: "Montserrat" }}
                          />
                          &nbsp;{" "}
                          <span
                            href="#"
                            className={`${classes.anchorTagcolor} ml-2 mt-2`}
                            style={{
                              fontFamily: "Montserrat",
                              cursor: "pointer",
                              background: "#45aaf0 !important",
                              color: "#fff",
                            }}
                            onClick={saveData}
                          >
                            {translate("Save")}
                          </span>
                        </div>
                      </div>
                      <hr className="hr" />
                      {/* <div className="form-group col">
                      <b>
                        <label
                          htmlFor="governmentId"
                          className="col-sm-12 col-form-label"
                          style={{ fontFamily: "Montserrat" }}
                        >
                          {translate('Government Id')}
                        </label>
                      </b>
                      <br />
                      <div className="col-sm-12 d-flex justify-content-between m-0">
                        <input disabled
                          type="text"
                          className={`${classes['form-control-plaintext']} pl-4`}
                          id="governmentId"
                          onChange={(e) => {
                            setgovernmentId(e.target.value);
                          }}
                          value={governmentId}
                          style={{ fontFamily: "Montserrat" }}
                        />
                        &nbsp;{" "}
                        <a
                          href="#"
                          className={`${classes.anchorTagcolor} ml-2 mt-2`}
                          style={{ fontFamily: "Montserrat" }}
                        >
                          // {translate('Save')}
                        </a>
                      </div>
                    </div>
                    <hr className="hr" />
                    <div className="form-group col">
                      <b>
                        <label
                          htmlFor="emergencyContact"
                          className="col-sm-12 col-form-label"
                          style={{ fontFamily: "Montserrat" }}
                        >
                          {translate('Emergency Contact')}
                        </label>
                      </b>
                      <br />
                      <div className="col-sm-12 d-flex justify-content-between m-0">
                        <input disabled
                          type="text"
                          className={`${classes['form-control-plaintext']} pl-4`}
                          id="emergencyContact"
                          onChange={(e) => {
                            setemergencyContact(e.target.value);
                          }}
                          value={emergencyContact}
                          style={{ fontFamily: "Montserrat" }}
                        />
                        &nbsp;{" "}
                        <a
                          href="#"
                          className={`${classes.anchorTagcolor} ml-2 mt-2`}
                          style={{ fontFamily: "Montserrat" }}
                        >
                          // {translate('Save')}
                        </a>
                      </div>
                    </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UserProfileEdit;
