import React from "react";
import Button from "@mui/material/Button";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";

function DiscoverBtn({ label, url }) {
  const { langData, fetchLangData, translate } = useLanguage();

  return (
    <Link to={`/${url}`}>
      <Button variant="contained">
        {translate(label)}
        <BsArrowRightShort style={{ fontSize: "22px", marginLeft: "10px" }} />
      </Button>
    </Link>
  );
}

export default DiscoverBtn;
