import React, { useState, useEffect } from "react";
import "./Footer.css";
import {
  BiLogoFacebook,
  BiLogoYoutube,
  BiLogoInstagramAlt,
  BiLogoLinkedin,
  BiSolidPhoneCall,
  BiLocationPlus,
} from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillSendFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import makeHttpRequest from "../../utils/api";
import Toaster from "../Toaster";
import { CircularProgress, Grid } from "@mui/material";
import { BsArrowRightShort } from "react-icons/bs";
import Button from "@mui/material/Button";
import { useLanguage } from "../../utils/LanguageContext";
import { useSelector } from "react-redux";

function Footer() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [footer, setFooter] = useState("");
  const { langData, fetchLangData, translate } = useLanguage();
  const { isLoggedIn } = useSelector((state) => state.user);


  const handleSubmit = async () => {
    try {
      if (!email) {
        Toaster("info", "Enter email");
        return;
      }

      setLoading(true);
      const data = {
        email: email,
      };
      const res = await makeHttpRequest("POST", "subscribe", data);

      setLoading(false);
      if (res.success === true) {
        Toaster("success", res.message);
        setEmail("");
      } else {
        if (res?.data[0]) {
          Toaster("info", res?.data[0]);
        } else {
          Toaster("error", "Error");
        }
      }
    } catch (error) {
      console.error("Error is :", error);
      setLoading(false);
    }
  };
  const handleInput = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await makeHttpRequest("GET", "footer");
      setFooter(response.data);
    } catch (error) {
      console.error("Error is:", error);
    }
  };
  return (
    <>
    
    <div className="footer-background">
    <div className="container my-5 py-5">
        <Grid container spacing={3} sx={{justifyContent: "space-between", alignItems: "center"}}>
          <Grid item md={12} xs={12}>
            <div className="contactUs-wrapper" style={{justifyContent:"center"}}>
              {/* <img src="/assets/icons/headphoneIcon.png" alt="..." /> */}
              <div className="footer-contactUs-topIcon">
                {/* <img src="/assets/icons/whiteaa1.png" alt="..." /> */}
              </div>
              <div className="contactUs-bottomIcon">
                {/* <img src="/assets/icons/aa2.jpeg" alt="..." /> */}
              </div>
              <h1 className="text-white">{translate("Want to be an Partner? Join Us Now")}</h1>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <div className="owner-btn p-1">
            {!isLoggedIn && (
              <Link to="/owner-login" className="m-1">
                <Button variant="contained" size="small">
                  {translate("Owner Login")}
                </Button>
              </Link>
            )}
         
            {!isLoggedIn && (
              <Link to="/owner-register" className="m-1">
                <Button variant="contained" size="small">
                  {translate("Owner Register")}
                </Button>
              </Link>
            )}
            </div>
          </Grid>
        </Grid>
    </div>

      <div className="container">
        <Grid
          container
          spacing={3}
          sx={{
            // px: "5%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item md={6} xs={12}>
            <div className=" contactUs-wrapper">
              <img src="/assets/icons/headphoneIcon.png" alt="..." />
              <div className="footer-contactUs-topIcon">
                {/* <img src="/assets/icons/whiteaa1.png" alt="..." /> */}
              </div>
              <div className="contactUs-bottomIcon">
                <img src="/assets/icons/aa2.jpeg" alt="..." />
              </div>
              <h1>{translate("Do you need support with our platform?")}</h1>
            </div>
          </Grid>
          <Grid item md={3} xs={12}>
            <div className=" contactUs-btn">
              <Link to="/contact">
                <Button variant="contained">
                  {translate("Contact Us")}
                  <BsArrowRightShort
                    style={{ fontSize: "22px", marginLeft: "10px" }}
                  />
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>

        {/* <div
          className="row class2"
          style={{ display: "flex", alignItems: "center" }}
        >
          
          <div className="col-lg-8 contactUs-btn">
           
          </div>
        </div> */}
        <hr
          style={{
            marginBottom: "80px",
            border: "1px solid #F8FBFC",
            opacity: "20%",
          }}
        />
        <Grid
          container
          spacing={3}
          // sx={{ px: "5%" }}
        >
          <Grid item md={3} xs={12}>
            <div className="social-content-wrapper ">
              <img src="/assets/icons/whiteLogo.png" alt="..." />
              <p className="footer-short">
                {translate(footer?.footer?.data_values?.short_description)}{" "}
              </p>
              <div className="social-icons">
                <Link to={"https://www.facebook.com/caprieasyapp"}>
                  <BiLogoFacebook
                    style={{
                      background: "#112f42",
                      padding: "5px",
                      borderRadius: "50px",
                      fontSize: "25px",
                    }}
                  />
                </Link>
                <Link to={"https://www.instagram.com/caprieasy"}>
                  <BiLogoInstagramAlt
                    style={{
                      background: "#112f42",
                      padding: "5px",
                      borderRadius: "50px",
                      fontSize: "25px",
                    }}
                  />
                </Link>
              </div>
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="important-links">
              <h3>{translate("Site Links")}</h3>
              <ul>
                <li>
                  <Link to="/accomodation">{translate("Accommodation")}</Link>
                </li>
                <li>
                  <Link to="/boat">{translate("Boat")}</Link>
                </li>
                <li>
                  <Link to="/locations">{translate("Location")}</Link>
                </li>
                <li>
                  <Link to="/blog">{translate("Blog")}</Link>
                </li>
                <li>
                  <Link to="/contact">{translate("Contact")}</Link>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="important-links">
              <h3>{translate("Importants Links")}</h3>
              <ul>
                {footer.policy_pages &&
                  footer.policy_pages.map((page, i) => (
                    <li key={i}>
                      <Link
                        to={`/policy/${page?.id}/${page?.data_values?.title}`}
                      >
                        {translate(page?.data_values?.title)}
                      </Link>
                    </li>
                  ))}
                <li>
                  <Link to="/register">{translate("User Registration")}</Link>
                </li>
                <li>
                  <Link to="/owner-register">
                    {translate("Owner Registration")}
                  </Link>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="important-links">
              <h3>{translate("Contact Info")}</h3>
              <ul>
                {/* <li>
                  {" "}
                  <BiSolidPhoneCall
                    style={{
                      background: "#112f42",
                      padding: "5px",
                      color: "#45AAF0",
                      borderRadius: "50px",
                      fontSize: "25px",
                      marginRight: "10px",
                    }}
                  />
                  {translate(footer?.contact?.data_values?.contact_number)}
                </li> */}
                <li>
                  {" "}
                  <BiLocationPlus
                    style={{
                      background: "#112f42",
                      padding: "5px",
                      color: "#45AAF0",
                      borderRadius: "50px",
                      fontSize: "25px",
                      marginRight: "10px",
                    }}
                  />
                  {translate(footer?.contact?.data_values?.contact_address)}
                </li>
                <li>
                  {" "}
                  <HiOutlineMail
                    style={{
                      background: "#112f42",
                      padding: "5px",
                      color: "#45AAF0",
                      borderRadius: "50px",
                      fontSize: "25px",
                      marginRight: "10px",
                    }}
                  />
                  {translate(footer?.contact?.data_values?.email_address)}
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="news-letter">
              <h3>{translate("News Letter")}</h3>
              <p>{translate(footer?.subscribe?.data_values?.heading)}</p>

              {loading && <CircularProgress size={40} />}
              {!loading && (
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`${translate("Recipient's username")}`}
                    aria-label={translate("Recipient's username")}
                    aria-describedby="basic-addon2"
                    value={email}
                    onChange={handleInput}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        handleSubmit();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      style={{ background: "#45AAF0", cursor: "pointer" }}
                      onClick={handleSubmit}
                    >
                      <BsFillSendFill style={{ color: "#FFF" }} />
                    </span>
                  </div>
                </div>
              )}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="checkbox-label" htmlFor="flexCheckDefault">
                  {translate("I agree to all terms and policies")}
                </label>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="copy-right-footer">
        {/* Develop by Azba Solution(Bilawal Basheer) */}
        <p>{translate("© 2023 Caprieasy. All Right Reserved")}</p>
      </div>
    </div>
    </>

  );
}

export default Footer;
