import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { Link } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";
import { AiOutlineCheck } from "react-icons/ai";
import "./PendingBookedBoat.css";
import { useLanguage } from "../../utils/LanguageContext";


function PendingBookedBoat() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();


  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/booked-boat/pending");
      setData(res.data);
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  function getStatusBadge(status) {
    if (status === 0) {
      return <span className="badge badge-warning">{translate('Pending')}</span>;
    } else if (status === 1) {
      return <span className="badge badge-success">{translate('Success')}</span>;
    } else if (status === 2) {
      return <span className="badge badge-danger">{translate('Cancel')}</span>;
    } else if (status === 3) {
      return <span className="badge badge-success">{translate('Approved')}</span>;
    } else if (status === 4) {
      return <span className="badge badge-success">{translate('Rejected')}</span>;
    }
    return null;
  }

  async function handleStatusChange(id, status) {
    try {
      setLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `owner/booked-boat/${id}/${status}`
      );
      setLoading(false);
      if (res.success) {
        Toaster("success", res?.message);
      } else {
        Toaster("error", "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <div className="mainContainer">
      <div className="pendingBoat-headingWrapper">
        <div className="pendingBoat-heading">
          <h1>{data?.type} {translate('Booked Boat')}</h1>
          <p>
            {translate('Total')} {data?.type} {translate('Boats')} <span>{data?.booked_boats?.total}</span>
          </p>
        </div>
        <div className="">
          <button className="pendingBoat-addBtn">
            <Link>+ {translate('Add New Boat')}</Link>
          </button>
        </div>
      </div>

      <div className="table-responsive">
        <div className="table-wrapper">
          <table id="mytable" className="table table-bordred table-striped">
            <thead className="pendingBoat-table-th">
              <th>{translate('S.N.')}</th>
              <th>{translate('Name')}</th>
              <th>{translate('Boat Type')}</th>
              <th>{translate('Location')}</th>
              <th>{translate('Price')}</th>
              <th>{translate('Discount')}</th>
              <th>{translate('Discounted Price')}</th>
              <th>{translate('Status')}</th>
              <th>{translate('Action')}</th>
            </thead>
            <tbody>
              {data?.booked_boats?.data?.map((row, index) => (
                <tr key={index} className="bookedBoat-td-wrapper">
                  <td style={{ paddingTop: "18px" }}>
                    <div className="sr-no-bg">0{index + 1}</div>
                  </td>
                  <td>{row?.boat.name}</td>
                  <td>{row?.boat.boat_type.name}</td>
                  <td>{row?.boat.location.name}</td>
                  <td>
                    {data?.curr_sym}
                    {showAmount(row?.boat?.price)}
                  </td>
                  <td>{showAmount(row?.boat?.discount)}%</td>
                  <td>
                    {data?.curr_sym}
                    {showAmount(row?.total_price)}
                  </td>
                  <td
                    style={{ paddingTop: "20px" }}
                    className="bookedBoat-status"
                  >
                    {getStatusBadge(row?.status)}
                  </td>
                  <td>
                    {row?.status != 1 && (
                      <Link
                        className="btn btn-sm btn-primary m-1"
                        onClick={() => handleStatusChange(row?.id, "success")}
                      >
                        <AiOutlineCheck />
                      </Link>
                    )}
                    {row?.status != 2 && (
                      <Link
                        className="btn btn-sm btn-danger m-1"
                        onClick={() => handleStatusChange(row?.id, "cancel")}
                      >
                        {translate('Cancel')}
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {data?.booked_boats?.data?.length == 0 && (
        <div className="pendingBoat-Img">
          <img src="/assets/icons/pendingProperty-img.png" alt="..." />
        </div>
      )}
    </div>
  );
}

export default PendingBookedBoat;
