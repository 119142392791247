import React, { useRef, useState } from "react";
import makeHttpRequest from "../../utils/api";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import Toaster from "../../Components/Toaster";
import { useDispatch } from "react-redux";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import ExtensionApi from "../../Components/ExtensionApi";
import RegisterImage from "../../Components/RegisterImage";
// google login
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
import { useLanguage } from "../../utils/LanguageContext";

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const { langData, fetchLangData, translate } = useLanguage();
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const responseGoogleSuccess = async (response) => {
    try {
      const { credential } = response;
      const res = await makeHttpRequest("POST", "auth/google/callback", {
        token: credential,
        user_type: "user",
      });
      setLoggedUser(res);
    } catch (error) {
      console.error(error);
    }
  };

  const responseGoogleFailure = (response) => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogin = async () => {
    if (!isCaptchaPassed) {
      Toaster("warning", "Captcha Required");
      return;
    }
    try {
      if (!state.username) {
        Toaster("info", "Enter username");
        return;
      }
      if (!state.password) {
        Toaster("info", "Enter password");
        return;
      }
      setLoading(true);
      const data = {
        username: state.username,
        password: state.password,
        user_type: "user",
      };
      const res = await makeHttpRequest("POST", "login", data);

      setLoggedUser(res);
      setLoading(false);
    } catch (error) {
      console.error("Error is :", error);
      setLoading(false);
    }
  };

  const handleEnter = (name) => {
    if (name == "username") {
      if (!state.username) {
        Toaster("info", "Enter email");
      } else if (state.username && !state.password) {
        passwordRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    } else {
      if (!state.password) {
        Toaster("info", "Enter password");
      } else if (!state.username && state.password) {
        usernameRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    }
  };

  const setLoggedUser = (res) => {
    if (res?.success === true) {
      localStorage.setItem("bearerToken", res?.data?.token);
      localStorage.setItem("userType", res?.data?.type);
      localStorage.setItem("userEmail", res?.data?.user?.email);

      // Toaster("success", res.message);
      if (res?.data?.type === "user") {
        dispatch(userLogged("user"));
      } else {
        dispatch(userLogged("owner"));
      }
      navigate("/dashboard");
    } else {
      Toaster("error", "Invalid credentials");
    }
  };

  return (
    <div className="login-form">
            <img src="/assets/icons/companyLogo.png" alt="..." />
            <div className="loginHeading-topIcon">
              <img src="/assets/icons/aa1.png" alt="..." />
            </div>
            <div className="loginHeading-bottomIcon">
              <img src="/assets/icons/aa2.jpeg" alt="..." />
            </div>
            {/* <form method="post" onSubmit={handleLogin}> */}
            <h1>{translate("Welcome To Caprieasy")}</h1>

            <div className="login-email">
              <label>{translate("Email")}</label>
              <input
                name="username"
                type="text"
                placeholder={translate("Enter User")}
                ref={usernameRef}
                value={state.username}
                onChange={handleInput}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    handleEnter("username");
                  }
                }}
              />
            </div>
            <div className="login-password">
              <label>{translate("Password")}</label>
              <input
                name="password"
                type="password"
                placeholder={translate("Enter your password")}
                ref={passwordRef}
                value={state.password}
                onChange={handleInput}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    handleEnter("password");
                  }
                }}
              />
            </div>

            <div className="login-password">
              <ExtensionApi
                googlerecaptcha2="google-recaptcha2"
                CaptchaPassed={setIsCaptchaPassed}
              />
              {/* {isCaptchaPassed && (
                <p className="text-success">{translate("Recaptcha passed!")}</p>
              )} */}
            </div>

            <div className="remember-wrapper">
              <div className="form-check remember-me">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {translate("Remember for 30 days")}
                </label>
              </div>

              <Link
                style={{ fontSize: "16px", color: "#45AAF0" }}
                to="/forgetPassword"
              >
                {translate("Forget Password")}
              </Link>
            </div>
            <div className="signIn-btn">
              {loading && <Loader size={40} />}
              {!loading && (
                <button disabled={!isCaptchaPassed} onClick={handleLogin}>
                  {translate("Sign In")}
                </button>
              )}
            </div>
            {/* </form> */}
            <div className="google-login">
              <GoogleLogin
                text={translate("Sign in with Google")}
                onSuccess={responseGoogleSuccess}
                onError={responseGoogleFailure}
                cancel_on_tap_outside={true}
                // cookiePolicy={"single_host_origin"}
              />
            </div>
            {/* <div className="google-login">
              <button>
                <img src="/assets/icons/googleIcon.png" alt="..." />
                <p>Sign in with Google</p>
              </button>
            </div>
            <div className="facebook-login">
              <button>
                <img src="/assets/icons/facebookIcon.png" alt="..." />
                <p>Sign in with Facebook</p>
              </button>
            </div> */}

            <div className="signUp-link">
              <p>
                {translate("Don't have an account?")}{" "}
                <Link
                  style={{ fontSize: "16px", color: "#45AAF0" }}
                  to="/register"
                >
                  {translate("Sign Up")}
                </Link>
              </p>
            </div>

      <div className="col-lg-6 col-md-6  d-none d-lg-block d-md-block">
        <RegisterImage type={"login"} />
      </div>
    </div>
  );
}

export default LoginPage;
