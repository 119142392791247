import React, { useEffect, useState } from "react";
import "./UpdateBoat.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import makeHttpRequest from "../../utils/api";
import { CircularProgress, Stack } from "@mui/material";
import ImageUploader from "../../Components/ImageUploader/NewImageUploader";
import Toaster from "../../Components/Toaster";
import { BiCheck } from "react-icons/bi";
import { useLanguage } from "../../utils/LanguageContext";
import ReactImagePickerEditor from "react-image-picker-editor";
import { imageUrlToBase64 } from "../../utils/helpers";
import ImageEditor from "./ImageEditor";
import SingleImageEditor from "./SingleImageEditor";

function UpdateBoat() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState("");

  const [change_images, setChangeImages] = useState([]);
  const [old_image, setOldImage] = useState([]);

  const handleChangeImage = (newImage) => {
    setChangeImages((prevImages) => [...prevImages, newImage]);
  };

  const handleOldImage = (val)=>{
    setOldImage(val);
  }

  const [image, setImage] = useState(null);
  const [image1a, setImage1a] = useState(null);
  const [image1b, setImage1b] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);


  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  const [imageCount, setImageCount] = useState(data?.boat?.images?.length);
  const [imageData, setImageData] = useState(
    Array.from({ length: imageCount }, (_, index) => ({
      selected: data?.boat?.images[index] || null,
      ori: data?.boat?.images[index] || null,
    }))
  );
  const [imageDataBase, setImageDataBase] = useState([]);

  const [imageDataNew, setImageDataNew] = useState([]);
  

  useEffect(() => {
    setImageCount(data?.boat?.images?.length || 0);
    setImageData(
      Array.from({ length: imageCount }, (_, index) => ({
        selected: data?.boat?.images[index] || null,
        ori: data?.boat?.images[index] || null,
      }))
    );
  }, [data]);

  const handleImageChange = (index, newImage) => {
    if (imageDataBase[index] && newImage !== imageDataBase[index]) {
        const newImageData = [...imageDataNew];
        // newImageData[index] = { ...newImageData[index], selected: newImage };
        newImageData[index] = newImage;
        setImageDataNew(newImageData);
    } 
};

  // Function to add a new ImageUploader
  const addImageUploader = () => {
    setImageCount(imageCount + 1);
    setImageData([...imageData, { selected: null, ori: null }]);
  };
  const { langData, fetchLangData, translate } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [feature, setFeature] = useState("");
  const [state, setState] = useState({});

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `owner/boat/edit/${id}`);
      setLoading(false);
      if (res.success) {
        setData(res?.data);
        let initialState = {
          name: res?.data?.boat?.name,
          boat_type: res?.data?.boat?.boat_type,
          location: res?.data?.boat?.location,
          phone: res?.data?.boat?.phone,
          phone_call_time: res?.data?.boat?.phone_call_time,
          price: res?.data?.boat?.price,
          description: res?.data?.boat?.description,
          map_url: res?.data?.boat?.map_url,
          discount: res?.data?.boat?.discount,
          adult: res?.data?.boat?.adult,
          child: res?.data?.boat?.child,
          amenities: res?.data?.boat?.amenities,
          extra_features: res?.data?.boat?.extra_features,
          status: res?.data?.boat?.status,
          images: res?.data?.boat?.images,
          image: res?.data?.boat?.image,
          image1a: res?.data?.boat?.image1a,
          image1b: res?.data?.boat?.image1b,
        };
        const thumbnailsArray = [
          res?.data?.boat?.image,
          res?.data?.boat?.image1a,
          res?.data?.boat?.image1b
        ];
        setThumbnails(thumbnailsArray);
        let old_img = [];
        res?.data?.boat?.images.forEach((val,index) => {
          old_img.push(val);
        });
        setOldImage(old_img);
        setState(initialState);
        // if (res?.data?.boat?.image) setImage(res?.data?.boat?.image);
        // if (res?.data?.boat?.image1a) setImage1a(res?.data?.boat?.image1a);
        // if (res?.data?.boat?.image1b) setImage1b(res?.data?.boat?.image1b);

        const responseData = res?.data?.boat;
        setImageCount(res?.data?.boat?.images?.length);
        if (responseData && responseData.images) {
          responseData.images.forEach((image, index) => {
            const setImageFunction = `setImage${index + 1}`;
            if (image) {
              // Dynamically set the state using the setImage function
              this[setImageFunction](image);
            }
          });
        }
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const amenities = state?.amenities?.reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});

  const handleAmenities = (arg) => {
    let ameni = state?.amenities ?? [];
    if (amenities[arg]) {
      let foundIdx = ameni.findIndex((item) => item == arg);
      let copy = JSON.parse(JSON.stringify(ameni));
      copy.splice(foundIdx, 1);
      setState((prev) => ({
        ...prev,
        amenities: copy,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        amenities: [...prev.amenities, arg],
      }));
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name == "feature") {
      setFeature(value);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && feature.trim() !== "") {
      setState((prev) => {
        const extraFeaturesArray = Array.isArray(prev.extra_features)
          ? prev.extra_features
          : [];

        return {
          ...prev,
          extra_features: [...extraFeaturesArray, feature.trim()],
        };
      });
      setFeature("");
    }
  };

  const handleTagRemove = (arg) => {
    const updatedTags =
      state?.extra_features?.filter((tag) => tag !== arg) || [];

    setState((prev) => ({
      ...prev,
      extra_features: updatedTags,
    }));
  };

  const handleSubmit = async () => {

    if (!data?.boat?.name) {
      Toaster("info", translate("Enter Boat Name"));
      return;
    }
    if (!data?.boat?.boat_type) {
      Toaster("info", translate("Enter Boat Type"));
      return;
    }
    if (!data?.boat?.location) {
      Toaster("info", translate("Select Location"));
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", state.name);
      if (image) {
        formData.append("image", image);
      }
      if (image1a) {
        formData.append("image1a", image1a);
      }
      if (image1b) {
        formData.append("image1b", image1b);
      }
      formData.append("boat_type", state.boat_type);
      formData.append("location", state.location);
      formData.append("phone", state.phone);
      formData.append("phone_call_time", state.phone_call_time);
      formData.append("price", state.price);
      formData.append("discount", state.discount);
      formData.append("description", state.description);
      formData.append("map_url", state.map_url);
      formData.append("status", state.status);

      if (state.extra_features) {
        state.extra_features.forEach((feature, index) => {
          formData.append(`extra_features[${index}]`, feature);
        });
      }

      state.amenities.forEach((amenity, index) => {
        formData.append(`amenities[${index}]`, amenity);
      });
      // Append other form fields as needed
      console.log('imageDataNew', imageDataNew);
      imageDataNew.forEach((new_data, index) => {
        if(new_data){
          formData.append(`images[${index}]`, new_data);
        }
      });
      
      // formData.append("change_images", change_images);

      old_image.forEach((new_data, index) => {
        if (new_data) {
        try{
          // Append each image to the FormData with a dynamic key
          formData.append(`old_images[${index}]`, new_data);
        } catch (error) {
          console.error('Error fetching image:', error);
        }
        }
      });


      const res = await makeHttpRequest(
        "POST",
        `owner/boat/update/${id}`,
        formData
      );
      setLoading(false);

      if (res.success) {
        // navigate('/all-boat')
      } else {
        // Handle error (e.g., display an error message)
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

 

  return (
    <div className="updateBoat-container">
      <div className="updateBoat-btnWrapper">
        <button className="updateBoat-addBtn">
          <Link to="/add-boat">
            <BsArrowLeft />
          </Link>
        </button>
        <h2>{translate("Update Boat")}</h2>
      </div>

      <div className="updateBoat-contentBg">
        <div className="updateBoat-detailHeading">
          <h2>{translate("Boat details")}</h2>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-12 col-12">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12 mt-3 updateBoat-name-inputs">
                <label className="updateBoat-inputLabel">
                  {translate("Boat Name")}
                  <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={translate("Enter Boat Name")}
                  required
                  name="name"
                  value={state?.name}
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-3 updateBoat-name-inputs">
                <label className="updateBoat-inputLabel">
                  {translate("Boat Type")}
                  <span>*</span>
                </label>
                <div className="updateBoat-dropdown" style={{ width: "100%" }}>
                  <select
                    name="boat_type"
                    value={state?.boat_type}
                    onChange={(e) => {
                      const selectedType = e.target.value;
                      setState({
                        ...state,
                        boat_type: selectedType,
                      });
                    }}
                  >
                    {!state?.boat_type && (
                      <option key={0} value="0">
                        {translate("Select Type")}
                      </option>
                    )}
                    {data?.boat_types?.map((item, i) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-4 updateBoat-name-inputs">
                <label className="updateBoat-inputLabel">
                  {translate("Boat Price")}
                  <span>*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Price"
                    aria-describedby="basic-addon2"
                    value={state.price}
                    onChange={(e) =>
                      setState({ ...state, price: e.target.value })
                    }
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      style={{ background: "#45AAF0", color: "#FFF" }}
                    >
                      {data?.curr_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-4 updateBoat-name-inputs">
                <label className="updateBoat-inputLabel">
                  {translate("Discount")}
                </label>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder={translate("Discount")}
                    aria-describedby="basic-addon2"
                    name="discount"
                    value={state.discount}
                    onChange={(e) =>
                      setState({ ...state, discount: e.target.value })
                    }
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      style={{ background: "#45AAF0", color: "#FFF" }}
                    >
                      {translate("%")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-3 addNewBoat-name-inputs">
                <label className="addNewBoat-inputLabel">
                  {translate("Adults")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder={translate("Adults")}
                  required
                  value={state.adult}
                  onChange={(e) =>
                    setState({ ...state, adult: e.target.value })
                  }
                  min="1"
                />
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-3 addNewBoat-name-inputs">
                <label className="addNewBoat-inputLabel">
                  {translate("Childs")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder={translate("Childs")}
                  required
                  value={state.child}
                  onChange={(e) =>
                    setState({ ...state, child: e.target.value })
                  }
                  min="0"
                />
              </div>
              <div className="col-lg-4 col-md-12 col-12 mt-4 updateBoat-inputs">
                <label className="updateBoat-inputLabel">
                  {translate("Location")}
                  <span>*</span>
                </label>
                <div className="updateBoat-dropdown" style={{ width: "100%" }}>
                  <select
                    name="location"
                    value={state.location}
                    onChange={(e) =>
                      setState({ ...state, location: e.target.value })
                    }
                  >
                    {!state?.location && (
                      <option value="">{translate("Select Location")}</option>
                    )}

                    {data?.locations?.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-12 mt-4 updateBoat-name-inputs">
                <label className="updateBoat-inputLabel">
                  {translate("Google Map Embed URL")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={translate("Google Map Embed URL")}
                  required
                  name="map_url"
                  value={state.map_url}
                  onChange={(e) =>
                    setState({ ...state, map_url: e.target.value })
                  }
                />
              </div>
              <div className="col-lg-8 col-md-12 col-12 mt-4 updateBoat-name-inputs">
                <label className="updateBoat-inputLabel">
                  {translate("Extra Features")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  required
                  name="feature"
                  value={feature}
                  onChange={handleInput}
                  onKeyDown={handleInputKeyDown}
                />
                <p className="addNewBoat-xxtraFeature">
                  {translate("Write features then press enter")}
                </p>

                <div
                  className="tag-container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "10px",
                  }}
                >
                  {state?.extra_features?.map((tag, i) => (
                    <div
                      className="tag m-1"
                      style={{
                        background: "#8cc5ed",
                        marginRight: "5px",
                        width: "fit-content",
                        borderRadius: "4px",
                        padding: "3px",
                      }}
                      key={i}
                    >
                      <span style={{ color: "white" }}>{tag}</span>
                      <span
                        style={{
                          color: "red",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        className="tag-remove"
                        onClick={() => handleTagRemove(tag)}
                      >
                        &#10006;
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12 mt-4 updateBoat-status-inputs">
                <label className="updateBoat-inputLabel">
                  {translate("Status")}
                </label>
                <div className="updateBoat-dropdown" style={{ width: "100%" }}>
                  <select
                    name="status"
                    value={state.status}
                    onChange={(e) =>
                      setState({ ...state, status: e.target.value })
                    }
                  >
                    <option key={1} value={1}>
                      {translate("Active")}
                    </option>
                    <option key={0} value={0}>
                      {translate("InActive")}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12 mt-3">
            <label className="updateBoat-inputLabel">
              {translate("Thumbnail 1")}
            </label>
            <SingleImageEditor imageUrl={thumbnails[0]} setImage={setImage} />
            <label className="addNewBoat-inputLabel">
              {translate("Thumbnail 2")}
            </label>
            <SingleImageEditor imageUrl={thumbnails[1]} setImage={setImage1a} />
            <label className="addNewBoat-inputLabel">
              {translate("Thumbnail 3")}
            </label>
            <SingleImageEditor imageUrl={thumbnails[2]} setImage={setImage1b} />
          </div>
        </div>
      </div>

      <div className="updateBoat-amenitiesBg">
        <div className="updateBoat-amenities">
          <h2>{translate("Amenities")}</h2>
        </div>
        <div className="row">
          {data?.amenities?.map((item, i) => {
            return (
              <div key={i} className="col-lg-3 col-md-6 col-12 p-1">
                <button
                  className={
                    amenities[item.id]
                      ? "updateBoat-tabs updateBoat-selected-tab"
                      : "updateBoat-tabs"
                  }
                  onClick={() => handleAmenities(item.id)}
                >
                  {item?.name}
                  {amenities[item.id] && (
                    <BiCheck
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    />
                  )}
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <div className="updateBoat-amenitiesBg">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 updateBoat-message-input">
            <label className="updateBoat-inputLabel">
              {translate("Description")}
            </label>
            <textarea
              className="form-control"
              placeholder=""
              required
              rows={10}
              value={state.description}
              onChange={(e) =>
                setState({ ...state, description: e.target.value })
              }
            ></textarea>
          </div>
        </div>
      </div>
      <div className="updateBoat-amenitiesBg">
        <div className="row">
          <div className="col-lg-9 col-md-9 col-9 addNewBoat-inputs">
            <label className="addNewBoat-inputLabel">
              {translate("Image Gallery")}
            </label>
          </div>
          <div className="col-lg-3 col-md-3 col-3 addNewBoat-submitBtn">
            {/* This is working for more than 4 images */}
            <button className="text-white" onClick={addImageUploader}>
              {translate("Add More Image")}
            </button>
          </div>
          <div className="col-lg-12 col-md-12 col-12 addNewBoat-inputs">
            <div className="row">
              <ImageEditor imageData={imageData} imageDataBase={imageDataBase} setImageDataBase={setImageDataBase} handleImageChange={handleImageChange} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="updateBoat-submitBtn">
              {loading && (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
                </Stack>
              )}
              {!loading && (
                <button onClick={handleSubmit}>
                  {translate("Update Boat")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateBoat;
