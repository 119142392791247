import React from "react";
import "./BookingConfirmed.css";
import { BsFillStarFill } from "react-icons/bs";
import { Card, CircularProgress } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { BsFillHeartFill } from "react-icons/bs";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { Stack } from "react-bootstrap";
import { useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";

const data = [
  {
    image: "/assets/icons/discoverLocation2.png",
    name: "Napoli",
    priceTitle: "Average price",
    price: 260,
    shift: "Night",
    carouselImages: [
      "/assets/icons/accomodationIcon1.png",
      "/assets/icons/dealicon3.png",
      "/assets/icons/dealicon1.png",
    ],
    location: "2972 Santa Ana",
    rating: "4.8/5",
    shipName: "Disney Dream",
    guests: 8,
    bed: 8,
    cabin: 8,
    bathroom: 8,
    nightPrice: 99,
  },
];



function BookingConfirmed() {
  const navigate = useNavigate();
  const [owner, setOwner] = useState("");
  const [loading, setLoading] = useState("");
  useEffect(() => {
    getData();
  }, []);
  
  const urlParams = new URLSearchParams(window.location.search);
  const paymentIntent = urlParams.get('payment_intent');
  const paymentIntentClientSecret = urlParams.get('payment_intent_client_secret');
  const redirectStatus = urlParams.get('redirect_status');
  
  // Create an object with the parameters
  const stripe_obj = {
    payment_intent: paymentIntent,
    payment_intent_client_secret: paymentIntentClientSecret,
    redirect_status: redirectStatus,
  };
  
  // Now you can use this 'stripe_obj' in your API request
  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", "ipn/stripe-js", stripe_obj);
      setLoading(false);
      if (res.success) {
        setOwner(res.data);
      } else {
        navigate('/');
        // Toaster("error", "Error");
      }
    } catch (error) {
      navigate('/');
      setLoading(false);
    }
  };
  return (
    <>
    <Navbar />
    {loading && (
      <Stack style={{height:"80vh", alignItems:"center", justifyContent:"center"}}>
        <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
      </Stack>
    )}
    <div style={{ background: "#f3f3f5" }}>
      {!loading && (
      <div className="container">
      
        <div className="row" style={{padding:"100px 0px"}}>
          <div className="col-lg-12 col-md-12 col-12 ">
            <div className="acommodation-confirm-bg">
              <div className="accomodation-confirmation">
                <img src="/assets/icons/confirmIcon.png" alt="..." />
                <h1>Congratulation!</h1>
                <p>You tour has been booked! 🎉</p>
              </div>

              <div className="accomodation-host-contentWrapper">
                <h5>{owner.name}</h5>
                <div className="accomodation-host-content">
                  <p>Hosted by</p>
                  <img src={owner.image} alt="..." height={30} width={30}/> 
                  {/* /assets/icons/boatHost.png */}
                  <h6>{owner.owner}</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 acommodationConfirm-btn mt-3">
                <Link to="/">
                <button>Back To Home</button>
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-4 col-md-4 col-12">
            <h1 className="accomodation-right-content">Preview</h1>
            <div className="row">
              {data.map((d, index) => {
                return (
                  <div className="col-lg-12 mt-4">
                    <Card style={{ borderRadius: "10px" }}>
                      <CardMedia>
                        <div
                          id={`carouselExampleIndicators_${index}`}
                          className="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div className="carousel-indicators">
                            {d.carouselImages.map((image, i) => (
                              <button
                                type="button"
                                data-bs-target={`#carouselExampleIndicators_${index}`}
                                data-bs-slide-to={i}
                                className={i === 0 ? "active" : ""}
                                aria-current={i === 0 ? "true" : "false"}
                                key={i}
                              ></button>
                            ))}
                          </div>
                          <div className="carousel-inner">
                            {d.carouselImages.map((image, i) => (
                              <div
                                className={`carousel-item ${
                                  i === 0 ? "active" : ""
                                }`}
                                key={i}
                              >
                                <img
                                  src={image}
                                  className="d-block w-100"
                                  alt={`Slide ${i + 1}`}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="boat-card-offer">
                            <h4>15%off</h4>
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "20px",
                                color: "#FFF",
                                background: "#8BC53E",
                                padding: "4px 7px",
                                borderRadius: "50px",
                              }}
                            >
                              <BsFillHeartFill />
                            </div>
                          </div>
                        </div>
                      </CardMedia>
                      <CardContent className="">
                        <div className="shipList-ranking">
                          <Typography className="shiplist-card-location">
                            <p>
                              <FaMapMarkerAlt
                                style={{
                                  color: "#8BC53E",
                                  marginRight: "7px",
                                  fontSize: "15px",
                                }}
                              />
                              <span>{d.location}</span>
                            </p>
                          </Typography>
                          <Typography className="shiplist-card-location">
                            <p>
                              <BsFillStarFill
                                style={{
                                  color: "#8BC53E",
                                  marginRight: "7px",
                                  fontSize: "15px",
                                }}
                              />
                              <span>{d.rating}</span>
                            </p>
                          </Typography>
                        </div>

                        <div>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="shiplist-card-heading"
                          >
                            <h1>{d.shipName}</h1>
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="shiplist-card-discription"
                          >
                            <p>{d.guests} Guests</p>
                            <p>{d.bed} Bed</p>
                            <p>{d.cabin} Cabin</p>
                            <p>{d.bathroom} bathroom</p>
                          </Typography>
                        </div>

                        <Typography className="shiplist-card-deatil-button">
                          <h3>
                            ${d.nightPrice} <span>Night</span>
                          </h3>
                          <Link >
                            <button>View Detail</button>
                          </Link>
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
      </div>
      )}
      {!loading && (
      <div className="mt-5">
        <Footer />
      </div>
      )}

    </div>
    </>
  );
}

export default BookingConfirmed;
