import React from "react";
import classes from "./EmailWeb.module.css";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import { useLanguage } from "../../utils/LanguageContext";


function EmailWeb() {
  const { langData, fetchLangData, translate } = useLanguage();

  return (
    <div>
      <Navbar />
      <div className="row" style={{ background: "#F3F3F5", padding: "120px" }}>
        <div className="col-lg-3 col-md-3 d-none d-lg-block">
          <UserSideNavbar />
        </div>
        <div className="col-lg-9 col-md-9 col-12">
          <div className={classes["email-webBg"]}>
            <div className={classes["email-webLogo"]}>
              <img src="/assets/icons/dashboardLogo.png" alt="..." />
              <h1>{translate('Hello World')}</h1>
            </div>
            <div className={classes["email-webContent"]}>
              <p>
                {translate('Sometimes you just want to send a simple HTML email with a simple design and clear call to action. This is it.')}
              </p>
            </div>
            <div className={classes["email-webCallAction"]}>
              <button>
                <Link> {translate('Call To Action')} </Link>
              </button>
            </div>
            <div className={`${classes['email-webContent']} mt-4 mb-3`}>
              <p>
                {translate("This is a really simple email template. It's sole purpose is to get the recipient to click the button with no distractions.")}
              </p>
            </div>
            <div className={classes["email-webPoints"]}>
              <ul>
                <li>{translate('This is the first line')}</li>
                <li>{translate('This is the 2nd line')}</li>
                <li>
                  {translate('This is the last line which is extra long so we can see how it wraps if it is indeed long enough')}
                </li>
              </ul>
            </div>
            <div className={classes["email-webPoints"]}>
              <ul>
                <li>
                  {translate("It's been a hard day's night, and I been working like a dog")}
                </li>
                <li>
                  {translate("It's been a hard day's night, I should be sleeping like a log")}
                </li>
                <li>
                  {translate("But when I get home to you I find the things that you do")}
                </li>
                <li> {translate("Will make me feel alright")} </li>
              </ul>
            </div>
            <div className={classes["email-webGoodluck"]}>
              <p>{translate("Good luck! Hope it works.")} </p>
            </div>

            <div className={classes["email-webFooter"]}>
              <p>
               {translate(" Company Inc, 3 Abbey Road, San Francisco CA 94102 Don't like these emails? Unsubscribe.")}
              </p>
              <p>{translate('Powered by HTMLemail.io')}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EmailWeb;
