import React, { useEffect, useState } from "react";
import "./HomePage.css";
import MainSearchEngine from "../../Components/MainSearchEngine/MainSearchEngine";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Footer from "../../Components/Footer/Footer";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import { BsArrowRightShort, BsFillHeartFill } from "react-icons/bs";
import { BiLocationPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import makeHttpRequest from "../../utils/api";
import Navbar from "../../Components/Navbar/Navbar";
import { CircularProgress, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLanguage } from "../../utils/LanguageContext";
import { addData } from "./landingSlice";
import { addLeadingZero } from "../../utils/addLeadingZero";
import { showAmount } from "../../utils/showAmount";
import { useNavigate } from "react-router-dom";
import CenterHeading from "../../Components/Heading/CenterHeading";
import LeftHeading from "../../Components/Heading/LeftHeading";
import WorkHeading from "../../Components/Heading/WorkHeading";
import Wishlist from "../../Components/Wishlist";
import LocationCarousal from "../../Components/LocationCarousal/LocationCarousal";
import { limitCharacters, title } from "../../utils/helpers";
import EventModal from "../../Components/EventModal/EventModal";

function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.landing);
  const { langData, fetchLangData, translate } = useLanguage();
  let firstEle = data?.propertyTypes ? data?.propertyTypes[0] : "";
  let secondEle = data?.propertyTypes ? data?.propertyTypes[1] : "";
  let thirdEle = data?.propertyTypes ? data?.propertyTypes[2] : "";
  let fourthEle = data?.propertyTypes ? data?.propertyTypes[3] : "";
  let fifthEle = data?.propertyTypes ? data?.propertyTypes[4] : "";
  const [loading, setLoading] = useState(false);
  const [selected_type_location, setSelectedTypeLocation] =
    useState("property");

  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "home");
      dispatch(addData(response.data));
      document.title = title()
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handlePropertyDetails = (item) => {
    navigate(`/accomodation/${item?.id}/${item.slug}/details`);
  };

  const handleBoatDetails = (item) => {
    navigate(`/boat/${item?.id}/${item.slug}/details`);
  };

  // const handleLocationFn = async (id) => {
  //   navigate("/locations");
  // };

  const handleLocationFn = async (id) => {
    setLoading(true);
    if (selected_type_location === "property") {
      const res = await makeHttpRequest(
        "GET",
        `search?type=${selected_type_location}&location=${id}`
      );
      navigate("/search", { state: res });
    } else {
      const res = await makeHttpRequest("GET", `boat?location=${id}`);
      navigate("/boat", { state: res });
    }
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && (
        <>
          <Navbar />
          <div className="container">
            <div className="main-heading">
          <EventModal />

              <h1>{translate(data?.banner?.data_values?.heading)}</h1>
              <p>{translate(data?.banner?.data_values?.sub_heading)}</p>
            </div>
            <div
              className="main-heading-background"
              style={{
                backgroundImage: `url("/assets/icons/mainHeadingBg.png")`,
              }}
            >
              <MainSearchEngine location_type={setSelectedTypeLocation} />
            </div>
          </div>

          <div className="row discover-location-wrapper">
            <div className="col-lg-6 col-md-12 col-12 left-container">
              <LeftHeading
                h1={translate(data?.location?.heading)}
                p={translate(data?.location?.sub_heading)}
                btn_label={translate(data?.location?.button)}
                img1 = 'not show image'
                img2 = 'not show image'
              />
            </div>

            <div className="col-lg-6 col-md-12 col-12 mt-5">
              {/* <div className="large">
                {data?.locations?.map((location, index) => (
                  <div
                    onClick={handleLocationFn}
                    key={index}
                    className="col-lg-4 col-md-6 col-12 discoverLocation-img"
                    style={{
                      backgroundImage: `url("${location.image}")`,
                      cursor: "pointer",
                    }}
                  >
                    <div className="locationPrice-wrapper">
                      <h4>{translate(location.name)}</h4>
                      <div className="location-capariPrice">
                        <p>{translate("Average price")}</p>
                        <h6>
                          {translate(data?.curr_sym)}
                          {showAmount(location.average_price)}
                          <span>&nbsp;{translate("Night")}</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}

              {/* <div className="mobile"> */}

              <LocationCarousal />
              {/* </div> */}
            </div>
          </div>

          <div className="container">
            <CenterHeading
              h1={translate(data?.property?.heading)}
              p={translate(data?.property?.sub_heading)}
            />
            {/* <div className="property-type">
              <div className="property-type-topIcon">
                <img src="/assets/icons/aa1.png" alt="..." />
              </div>
              <div className="property-type-bottomIcon">
                <img src="/assets/icons/aa2.jpeg" alt="..." />
              </div>
              <h1>{data?.property?.heading}</h1>
              <p>{data?.property?.sub_heading}</p>
            </div> */}
            <div>
              {data?.propertyTypes?.length > 0 && (
                <div className="row mb-5 class1">
                  <div className="col-lg-6 col-md-12 col-12 mt-2">
                    {firstEle && (
                      <Card
                        className="m-1"
                        sx={{ boxShadow: "none", borderRadius: "16px" }}
                      >
                        <Link
                          to={`/accomodation/search-location/${firstEle.id}/${firstEle.slug}`}
                          className="link-wrapper"
                        >
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="432px"
                            image={
                              // firstEle.image !== ""
                              //   ?
                              firstEle.image
                              // : "/assets/icons/apartmentImg.png"
                            }
                          />

                          <CardContent className="card-content-wrapper">
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="proprty-card-footer"
                              title={firstEle.name}
                            >
                              {translate(limitCharacters(firstEle.name, 15))} (
                              {addLeadingZero(firstEle.total)})
                            </Typography>
                            <BsArrowRightShort
                              style={{
                                fontSize: "22px",
                                marginLeft: "10px",
                                color: "#8BC53E",
                              }}
                            />
                          </CardContent>
                        </Link>
                      </Card>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12 mt-2">
                        {secondEle && (
                          <Card
                            className="m-1"
                            sx={{ boxShadow: "none", borderRadius: "16px" }}
                          >
                            <Link
                              to={`/accomodation/search-location/${secondEle.id}/${secondEle.slug}`}
                              className="link-wrapper"
                            >
                              <CardMedia
                                component="img"
                                alt="green iguana"
                                height="180px"
                                image={
                                  secondEle.image !== ""
                                    ? secondEle.image
                                    : "/assets/icons/houseImg.png"
                                }
                              />
                              <CardContent className="card-content-wrapper">
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  className="proprty-card-footer"
                                  title={secondEle.name}
                                >
                                  {translate(
                                    limitCharacters(secondEle.name, 15)
                                  )}{" "}
                                  ({addLeadingZero(secondEle.total)})
                                </Typography>
                                <BsArrowRightShort
                                  style={{
                                    fontSize: "22px",
                                    marginLeft: "10px",
                                    color: "#8BC53E",
                                  }}
                                />
                              </CardContent>
                            </Link>
                          </Card>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mt-2">
                        {fourthEle && (
                          <Card
                            className="m-1"
                            sx={{ boxShadow: "none", borderRadius: "16px" }}
                          >
                            <Link
                              to={`/accomodation/search-location/${fourthEle.id}/${fourthEle.slug}`}
                              className="link-wrapper"
                            >
                              <CardMedia
                                component="img"
                                alt="green iguana"
                                height="180px"
                                image={
                                  fourthEle.image !== ""
                                    ? fourthEle.image
                                    : "/assets/icons/motelImg.png"
                                }
                              />

                              <CardContent className="card-content-wrapper">
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  className="proprty-card-footer"
                                  title={fourthEle.name}
                                >
                                  {translate(limitCharacters(fourthEle.name))} (
                                  {addLeadingZero(fourthEle.total)})
                                </Typography>
                                <BsArrowRightShort
                                  style={{
                                    fontSize: "22px",
                                    marginLeft: "10px",
                                    color: "#8BC53E",
                                  }}
                                />
                              </CardContent>
                            </Link>
                          </Card>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mt-2">
                        {thirdEle && (
                          <Card
                            className="m-1"
                            sx={{ boxShadow: "none", borderRadius: "16px" }}
                          >
                            <Link
                              to={`/accomodation/search-location/${thirdEle.id}/${thirdEle.slug}`}
                              className="link-wrapper"
                            >
                              <CardMedia
                                component="img"
                                alt="green iguana"
                                height="180px"
                                image={
                                  thirdEle.image !== ""
                                    ? thirdEle.image
                                    : "/assets/icons/villaImg.png"
                                }
                              />
                              <CardContent className="card-content-wrapper">
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  className="proprty-card-footer"
                                  title={thirdEle.name}
                                >
                                  {translate(
                                    limitCharacters(thirdEle.name, 15)
                                  )}{" "}
                                  ({addLeadingZero(thirdEle.total)})
                                </Typography>
                                <BsArrowRightShort
                                  style={{
                                    fontSize: "22px",
                                    marginLeft: "10px",
                                    color: "#8BC53E",
                                  }}
                                />
                              </CardContent>
                            </Link>
                          </Card>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mt-2">
                        {fifthEle && (
                          <Card
                            className="m-1"
                            sx={{ boxShadow: "none", borderRadius: "16px" }}
                          >
                            <Link
                              to={`/accomodation/search-location/${fifthEle.id}/${fifthEle.slug}`}
                              className="link-wrapper"
                            >
                              <CardMedia
                                component="img"
                                alt="green iguana"
                                height="180px"
                                image={
                                  fifthEle.image !== ""
                                    ? fifthEle.image
                                    : "/assets/icons/motelImg.png"
                                }
                              />

                              <CardContent className="card-content-wrapper">
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  className="proprty-card-footer"
                                  title={fifthEle.name}
                                >
                                  {translate(limitCharacters(fifthEle.name))} (
                                  {addLeadingZero(fifthEle.total)})
                                </Typography>
                                <BsArrowRightShort
                                  style={{
                                    fontSize: "22px",
                                    marginLeft: "10px",
                                    color: "#8BC53E",
                                  }}
                                />
                              </CardContent>
                            </Link>
                          </Card>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="best-boat-background">
            <div className="container">
              <div className="best-boat-content-wrapper">
                <div className="best-boats-content">
                  <h1 className="h1">
                    {translate(data?.boat?.heading)}
                    <img
                      className="img1"
                      src="/assets/icons/aa2.jpeg"
                      alt="..."
                    />
                    <img
                      className="img2"
                      src="/assets/icons/aa1.png"
                      alt="..."
                    />
                  </h1>
                  {/* <div className="boat-type-topIcon">
                    <img src="/assets/icons/aa1.png" alt="..." />
                  </div>
                  <div className="boat-type-bottomIcon">
                    <img src="/assets/icons/aa2.jpeg" alt="..." />
                  </div> */}
                  <p>{translate(data?.boat?.sub_heading)}</p>
                </div>
                <div className="best-boat-button">
                  <Link to={"/boat"} className="link-wrapper">
                    <Button variant="contained">
                      {translate("Discover More")}
                      <BsArrowRightShort
                        style={{ fontSize: "15px", marginLeft: "10px" }}
                      />
                    </Button>
                  </Link>
                </div>
              </div>
              <div>
                {data?.boatTypes && data?.boatTypes?.length > 0 && (
                  <div className="row boat-wrapper class1">
                    {data?.boatTypes[0] && (
                      <div className="col-lg-3">
                        <Card
                          sx={{
                            borderTopRightRadius: "8px",
                            borderTopLeftRadius: "8px",
                            borderBottomRightRadius: "16px",
                            borderBottomLeftRadius: "16px",
                            boxShadow: "none",
                          }}
                          className="boot-card-content-wrapper"
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/boat/search-location/${data?.boatTypes[0]?.id}/${data?.boatTypes[0]?.slug}`}
                            className="link-wrapper"
                          >
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              // height="100%"
                              image={
                                // data?.boatTypes[0].image !== ""
                                // ?
                                data?.boatTypes[0].image
                                // : "/assets/icons/fastBoat1.png"
                              }
                              style={{ backgroundColor: "#8BC53E" }}
                            />

                            <CardContent className="card-boot-content-wrapper">
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="boat-card-footer"
                              >
                                {translate(data?.boatTypes[0].name)} (
                                {addLeadingZero(data?.boatTypes[0].total)})
                              </Typography>
                              <BsArrowRightShort
                                style={{
                                  fontSize: "22px",
                                  marginLeft: "10px",
                                  color: "#8BC53E",
                                }}
                              />
                            </CardContent>
                          </Link>
                        </Card>
                      </div>
                    )}

                    {data?.boatTypes[1] && (
                      <div className="col-lg-3">
                        <Card
                          sx={{
                            borderTopRightRadius: "8px",
                            borderTopLeftRadius: "8px",
                            borderBottomRightRadius: "16px",
                            borderBottomLeftRadius: "16px",
                            boxShadow: "none",
                          }}
                          className="boot-card-content-wrapper"
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/boat/search-location/${data?.boatTypes[1]?.id}/${data?.boatTypes[1]?.slug}`}
                            className="link-wrapper"
                          >
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              // height="100%"
                              image={
                                // data?.boatTypes[1].image !== ""
                                // ?
                                data?.boatTypes[1].image
                                // : "/assets/icons/fastBoat1.png"
                              }
                              style={{ backgroundColor: "#8BC53E" }}
                            />

                            <CardContent className="card-boot-content-wrapper">
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="boat-card-footer"
                              >
                                {translate(data?.boatTypes[1].name)} (
                                {addLeadingZero(data?.boatTypes[1].total)})
                              </Typography>
                              <BsArrowRightShort
                                style={{
                                  fontSize: "22px",
                                  marginLeft: "10px",
                                  color: "#8BC53E",
                                }}
                              />
                            </CardContent>
                          </Link>
                        </Card>
                      </div>
                    )}
                    {data?.boatTypes[2] && (
                      <div className="col-lg-3">
                        <Card
                          sx={{
                            borderTopRightRadius: "8px",
                            borderTopLeftRadius: "8px",
                            borderBottomRightRadius: "16px",
                            borderBottomLeftRadius: "16px",
                            boxShadow: "none",
                          }}
                          className="boot-card-content-wrapper"
                        >
                          <Link
                            to={`/boat/search-location/${data?.boatTypes[2]?.id}/${data?.boatTypes[2]?.slug}`}
                            className="link-wrapper"
                          >
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              // height="100%"
                              image={
                                data?.boatTypes[2].image !== ""
                                  ? data?.boatTypes[2].image
                                  : "/assets/icons/fastBoat2.png"
                              }
                              style={{ backgroundColor: "#8BC53E" }}
                            />
                            <CardContent className="card-boot-content-wrapper">
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="boat-card-footer"
                              >
                                {translate(data?.boatTypes[2]?.name)} (
                                {addLeadingZero(data?.boatTypes[2]?.total)})
                              </Typography>
                              <BsArrowRightShort
                                style={{
                                  fontSize: "22px",
                                  marginLeft: "10px",
                                  color: "#8BC53E",
                                }}
                              />
                            </CardContent>
                          </Link>
                        </Card>
                      </div>
                    )}
                    {data?.boatTypes[3] && (
                      <div className="col-lg-3 ">
                        <Card
                          sx={{
                            borderTopRightRadius: "8px",
                            borderTopLeftRadius: "8px",
                            borderBottomRightRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            boxShadow: "none",
                          }}
                          className="boot-card-content-wrapper"
                        >
                          <Link
                            to={`/boat/search-location/${data?.boatTypes[3]?.id}/${data?.boatTypes[3]?.slug}`}
                            className="link-wrapper"
                          >
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              // height="80%"
                              image={
                                data?.boatTypes[3].image !== ""
                                  ? data?.boatTypes[3].image
                                  : "/assets/icons/fastBoat4.png"
                              }
                              style={{ backgroundColor: "#8BC53E" }}
                            />
                            <CardContent className="card-boot-content-wrapper">
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="boat-card-footer"
                              >
                                {translate(data?.boatTypes[3].name)} (
                                {addLeadingZero(data?.boatTypes[3].total)})
                              </Typography>
                              <BsArrowRightShort
                                style={{
                                  fontSize: "22px",
                                  marginLeft: "10px",
                                  color: "#8BC53E",
                                }}
                              />
                            </CardContent>
                          </Link>
                        </Card>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container">
            <CenterHeading
              h1={translate(data?.trip?.heading)}
              p={translate(data?.trip?.sub_heading)}
            />
            {/* <div className="property-type">
              <div className="weeklyTrip-type-topIcon">
                <img src="/assets/icons/aa1.png" alt="..." />
              </div>
              <div className="weeklyTrip-type-bottomIcon">
                <img src="/assets/icons/aa2.jpeg" alt="..." />
              </div>
              <h1>{data?.trip?.heading}</h1>
              <p>{data?.trip?.sub_heading}</p>
            </div> */}
            <div>
              <div className="row boat-wrapper class1">
                {data?.properties?.map((item, index) => (
                  <div
                    key={index+'p'}
                    className="col-lg-4 weeklyTripBg p-1 px-2"
                    style={{
                      backgroundImage: `url("/assets/icons/weeklyTripBg.png")`,
                    }}
                  >
                    <Card style={{ borderRadius: "10px" }}>
                      <CardMedia>
                        <div
                          id={`carouselExampleIndicators_${index+'p'}`}
                          className="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div className="carousel-indicators">
                            {item?.image?.map((image, i) => (
                              <button
                                type="button"
                                data-bs-target={`#carouselExampleIndicators_${index+'p'}`}
                                data-bs-slide-to={i}
                                className={i === 0 ? "active" : ""}
                                aria-current={i === 0 ? "true" : "false"}
                                key={i}
                              ></button>
                            ))}
                          </div>
                          <div className="carousel-inner">
                            {item?.image?.map((image, i) => (
                              <div
                                className={`carousel-item ${
                                  i === 0 ? "active" : ""
                                }`}
                                key={i}
                              >
                                <img
                                  src={image}
                                  className="d-block w-100"
                                  alt={`Slide ${i + 1}`}
                                />
                              </div>
                            ))}
                          </div>
                          {/* <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            >
                              <i className="fas fa-chevron-left"></i>
                            </span>
                          </button>
                          <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            >
                              <i className="fas fa-chevron-right"></i>
                            </span>
                          </button> */}
                        </div>
                        <Wishlist id={item.id} type={"property"} />
                      </CardMedia>
                      <CardContent className="home-boat-card-content">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="top-dealCard-content-heading"
                        >
                          <h2>{translate(item.name)}</h2>
                          <h3>({item.total})</h3>
                        </Typography>
                        <Typography className="top-dealCard-content-discription">
                          <p>
                            <BiLocationPlus style={{ color: "#8BC53E" }} />
                            {translate(item.location)}
                          </p>
                        </Typography>
                        <Typography className="top-dealCard-content-button">
                          <h3>
                            {translate(data?.curr_sym)}
                            {item?.is_complete === 1
                              ? showAmount(item.price)
                              : showAmount(item.price1) +
                                " - " +
                                translate(data?.curr_sym) +
                                showAmount(item.price2)}{" "}
                            <span>{translate(item.label)}</span>
                          </h3>
                          <button onClick={() => handlePropertyDetails(item)}>
                            {translate("View Detail")}
                          </button>
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                ))}
                {data?.boats?.length > 0 &&
                  data?.boats?.map((item, index) => (
                    <div
                      key={index+'b'}
                      className="col-lg-4 weeklyTripBg p-1 px-2"
                      style={{
                        backgroundImage: `url("/assets/icons/weeklyTripBg.png")`,
                      }}
                    >
                      <Card style={{ borderRadius: "10px" }}>
                        <CardMedia>
                          <div
                            id={`carouselExampleIndicators_${index+'b'}`}
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-indicators">
                              {item.image.map((image, i) => (
                                <button
                                  type="button"
                                  data-bs-target={`#carouselExampleIndicators_${index+'b'}`}
                                  data-bs-slide-to={i}
                                  className={i === 0 ? "active" : ""}
                                  aria-current={i === 0 ? "true" : "false"}
                                  key={i}
                                ></button>
                              ))}
                            </div>
                            <div className="carousel-inner">
                              {item.image.map((image, i) => (
                                <div
                                  className={`carousel-item ${
                                    i === 0 ? "active" : ""
                                  }`}
                                  key={i}
                                >
                                  <img
                                    className="d-block w-100 home-page-slider"
                                    src={
                                      image != ""
                                        ? image
                                        : "/assets/icons/shipList1.png"
                                    }
                                    alt={`Slide ${i + 1}`}
                                  />
                                </div>
                              ))}
                            </div>

                            {/* <button
                              className="carousel-control-prev"
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              >
                                <i className="fas fa-chevron-left"></i>
                              </span>
                            </button>
                            <button
                              className="carousel-control-next"
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              >
                                <i className="fas fa-chevron-right"></i>
                              </span>
                            </button> */}
                          </div>
                          <Wishlist id={item.id} type={"boat"} />
                        </CardMedia>
                        <CardContent className="home-boat-card-content">
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="top-dealCard-content-heading"
                          >
                            <h2>{translate(item.name)}</h2>
                            <h3>({item.total})</h3>
                          </Typography>
                          <Typography className="top-dealCard-content-discription">
                            <p>
                              <BiLocationPlus style={{ color: "#8BC53E" }} />
                              {translate(item.location)}
                            </p>
                          </Typography>
                          <Typography className="top-dealCard-content-button">
                            <h3>
                              {translate(data?.curr_sym)}
                              {showAmount(item.price)}{" "}
                              <span>{translate(item.label)}</span>
                            </h3>
                            <button onClick={() => handleBoatDetails(item)}>
                              {translate("View Detail")}
                            </button>
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="container">
            <div>
              <div className="row enjoyTrip-wrapper">
                <div className="col-lg-8">
                  <div className="enjouTrip-heading">
                    {/* <div className="enjouTrip-topIcon">
                      <img src="/assets/icons/aa1.png" alt="..." />
                    </div>
                    <div className="enjouTrip-bottomIcon">
                      <img src="/assets/icons/aa2.jpeg" alt="..." />
                    </div>
                    <h1>{data?.work?.title}</h1>
                    <p>{data?.work?.heading}</p> */}
                    <WorkHeading
                      h1={translate(data?.work?.title)}
                      p={translate(data?.work?.heading)}
                    />
                  </div>
                  <div
                    className="col-lg-4 trip-enjoy-img1"
                    style={{
                      // this url also change from below
                      backgroundImage: `url(${data?.work?.right_image})`,
                    }}
                  >
                    {/* ${data?.work?.background_image} */}
                  </div>
                  <div className="row mt-4">
                    {data?.works?.map((value, i) => (
                      <div className="col-lg-6 col-md-6 col-12 enjouTrip-content">
                        <h1>{translate(value?.data_values?.step)}</h1>
                        <p>{translate(value?.data_values?.description)}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-12 trip-enjoy-img"
                  style={{
                    // this url also change from above
                    backgroundImage: `url(${data?.work?.right_image})`,
                    width: "306px",
                    height: "493px",
                  }}
                >
                  {/* <div className="tripenjoy2-icon">
                    <img
                      src={data?.work?.left_image}
                      width={"306px"}
                      height={"493px"}
                      // src="/assets/icons/tripEnjoy2.png"
                      alt="..."
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="homePage-fastBoatCards">
            <FastBoatCards />
          </div> */}

          <div className="homePage-footer">
            <Footer />
          </div>
        </>
      )}
    </>
  );
}

export default LandingPage;
