import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { useLanguage } from "../../utils/LanguageContext";

const DetailCarousal = ({images}) => {
  const { data, loading, error } = useSelector((state) => state.landing);
  const { langData, fetchLangData, translate } = useLanguage();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update screen width when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine the number of slides to show based on screen width
  const slidesToShow = screenWidth < 768 ? 1 : 3; // Adjust the breakpoint as needed

  const settings = {
    arrow: false,
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // const settings = {
  //   arrow: false,
  //   dots: false,
  //   infinite: true,
  //   speed: 5000,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  // };

  return (
    <div>
      {loading && <p>{translate("Loading...")}</p>}
      {error && (
        <p>{translate("Error loading data. Please try again later.")}</p>
      )}

      <Slider {...settings}>
        {images?.map((location, index) => (
          <div
            key={index}
            className="col-lg-12 col-md-12 col-12"
          >
            <div
              style={{
                backgroundImage: `url("${
                  location || "/fallback-image-url.jpg"
                }")`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "100% ",
                height: "500px",
              }}
            >
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default DetailCarousal;
