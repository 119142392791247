import React, { useEffect, useState } from "react";
import "./ContactUsPage.css";
import { IoIosArrowForward } from "react-icons/io";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import Footer from "../../Components/Footer/Footer";
import {
  BiLogoFacebook,
  BiLogoYoutube,
  BiLogoInstagramAlt,
  BiLogoLinkedin,
} from "react-icons/bi";
import Navbar from "../../Components/Navbar/Navbar";
import Toaster from "../../Components/Toaster";
import makeHttpRequest from "../../utils/api";
import { CircularProgress } from "@mui/material";
import { useLanguage } from "../../utils/LanguageContext";

function ContactUsPage() {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    name: "",
    lastName: "",
    subject: "",
    message: "",
    email: "",
  });
  const [site_contact, setSiteContact] = useState("");
  const { langData, fetchLangData, translate } = useLanguage();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "contact");
      setSiteContact(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!state.name) {
        Toaster("info", "Enter name");
        return;
      }
      if (!state.email) {
        Toaster("info", "Enter email");
        return;
      }
      if (!state.message) {
        Toaster("info", "Enter message");
        return;
      }
      setLoading(true);
      const data = {
        name: state.name + " " + state.lastName,
        email: state.email,
        subject: state.subject,
        message: state.message,
      };
      const res = await makeHttpRequest("POST", "contact", data);

      setLoading(false);
      if (res.success === true) {
        Toaster("success", res.message);
        setState({
          name: "",
          lastName: "",
          subject: "",
          message: "",
          email: "",
        });
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      console.error("Error is :", error);
      setLoading(false);
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (!loading) {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  return (
    <>
      <Navbar />
      <div className="contactUs-heading">
        <h1>{translate("Contact Us")}</h1>
        <p>
          {translate("Home")} <IoIosArrowForward />{" "}
          <span>{translate("Contact Us")}</span>
        </p>
      </div>

      <div className="container">
        <div className="row" style={{ marginTop: "80px" }}>
          <div className="col-lg-6 col-md-6 col-12 mt-3">
            <div className="contactUs-cards">
              <img src="/assets/icons/officeLocation.png" alt="..." />
              <h3>{translate("Office Location")}</h3>
              <p>{translate(site_contact?.contact?.contact_address)}</p>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-12 mt-3">
            <div className="contactUs-cards">
              <img src="/assets/icons/hotlineIcon.png" alt="..." />
              <h3>{translate("Hotline")}</h3>
              <p>{translate(site_contact?.contact?.contact_number)}</p>
            </div>
          </div> */}
          <div className="col-lg-6 col-md-6 col-12 mt-3">
            <div className="contactUs-cards">
              <img src="/assets/icons/officeEmail.png" alt="..." />
              <h3>{translate("Email Address")}</h3>
              <p>{translate(site_contact?.contact?.email_address)}</p>
            </div>
          </div>
        </div>

        <div className=" row contactUs-fromBg mt-5 mb-4">
          <div className="col-lg-6 col-md-12 col-12 contactForm-content ">
            <h1>{translate(site_contact?.contact?.title)}</h1>
            {/* <p>
              Duis commodo ipsum quis ante venenatis rhoncus. Vivamus imperdiet
              felis facilisis hendrerit. Nulla eu elementurn ex. ut pulvinar
              est. Nam aliquet et
            </p> */}
            {/* <img src="/assets/icons/contactUs-location.png" alt="..." /> */}
            <div className="mapouter w-100">
              <div className="gmap_canvas">
                <iframe
                  id="gmap_canvas"
                  src={site_contact?.contact?.google_map_embed_url}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
              </div>
            </div>
            {/* <div className="social-media-icons mt-4">

              <BiLogoFacebook
                style={{
                  background: "gray",
                  color: "#050F15",
                  padding: "5px",
                  // opacity:"0.08",
                  borderRadius: "50px",
                  fontSize: "30px",
                  marginRight: "10px",
                }}
              />
              <BiLogoLinkedin
                style={{
                  background: "gray",
                  color: "#050F15",
                  padding: "5px",
                  color: "#000",
                  borderRadius: "50px",
                  fontSize: "30px",
                  marginRight: "10px",
                }}
              />

              <BiLogoInstagramAlt
                style={{
                  background: "gray",
                  color: "#050F15",
                  padding: "5px",
                  color: "#000",
                  borderRadius: "50px",
                  fontSize: "30px",
                  marginRight: "10px",
                }}
              />

              <BiLogoYoutube
                style={{
                  background: "gray",
                  color: "#050F15",
                  padding: "8px",
                  color: "#000",
                  borderRadius: "50px",
                  fontSize: "30px",
                  marginRight: "10px",
                }}
              />
            </div> */}
          </div>
          <div className="col-lg-6 col-md-12 col-12 ">
            <h3 className="contactUs-subtitle">
              {translate(site_contact?.contact?.contact_form_title)}
            </h3>

            <div className="row">
              <div className="col-lg-6 col-md-12 col-12 mt-4">
                <div className="contactUs-inputs">
                  <input
                    type="text"
                    placeholder={translate("First Name")}
                    name="name"
                    value={state.name}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-4">
                <div className="contactUs-inputs">
                  <input
                    type="text"
                    placeholder={translate("Last Name")}
                    name="lastName"
                    value={state.lastName}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-12 mt-4">
                <div className="contactUs-inputs">
                  <input
                    type="email"
                    placeholder={translate("Email")}
                    name="email"
                    value={state.email}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-12 mt-4">
                <div className="contactUs-inputs">
                  <input
                    placeholder={translate("Subject")}
                    name="subject"
                    value={state.subject}
                    onChange={handleInput}
                  />
                </div>
              </div>
              {/* <div className="col-lg-12 col-md-12 col-12 mt-4">
                <div className="contactUs-inputs">
                  <input
                    type="number"
                    placeholder="+123456789"
                    name="contact"
                    value={state.contact}
                    onChange={handleInput}
                  />
                </div>
              </div> */}
              <div className="col-lg-12 col-md-12 col-12 mt-4">
                <div className="contactUs-inputs">
                  <label>{translate("Your Text")}</label>
                  <input
                    style={{ paddingBottom: "80px" }}
                    type="text"
                    placeholder={translate("Write your message")}
                    name="message"
                    value={state.message}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-12  mt-4 form-btn">
                {loading && <CircularProgress size={40} />}
                {!loading && (
                  <button onClick={handleSubmit}>{translate("Submit")}</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="contactUs-fastBoatCards">
        <FastBoatCards />
      </div> */}

      <div className="contactUs-footer">
        <Footer />
      </div>
    </>
  );
}

export default ContactUsPage;
