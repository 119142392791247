import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import "./RoomCategory.css";
import { Link } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";


function RoomCategory() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();


  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/room-categories");
      setData(res.data);
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="mainContainer">
      <div className="roomCategory-headingWrapper">
        <div className="roomCategory-heading">
          <h1>{translate('Room Categories')}</h1>
          <p>
            {translate('Total category')} <span>{data?.room_categories?.total}</span>
          </p>
        </div>
        <div className="">
          <button className="roomCategory-addBtn">
            <Link to="/create-room-category">+ {translate('Add New Category')}</Link>
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <div className="table-wrapper">
          <table id="mytable" className="table table-bordred table-striped">
            <thead className="roomCategory-table-th">
              <th>{translate('S.N.')}</th>
              <th>{translate("Name")}</th>
              <th>{translate('Accomodation')}</th>
              <th>{translate('Rooms')}</th>
              <th>{translate('Action')}</th>
            </thead>
            <tbody>
              {data?.room_categories?.data?.map((row, index) => (
                <tr key={index} className="roomCategory-td-wrapper">
                  <td style={{ paddingTop: "18px" }}>
                    <div className="sr-no-bg">0{index + 1}</div>
                  </td>
                  <td>{row.name}</td>
                  <td>{row.property.name}</td>
                  <td>{row.rooms.length}</td>
                  <td className="roomCategory-viewWrapper">
                    <Link to={`/edit-room-category/${row.id}`} className="roomCategory-view-bg">{translate('Edit')}</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default RoomCategory;
