import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { CircularProgress, Stack } from "@mui/material";
import makeHttpRequest from "../../utils/api";
import { useLanguage } from "../../utils/LanguageContext";
import { addLeadingZero } from "../../utils/addLeadingZero";
import { showAmount } from "../../utils/showAmount";

function Dashboard() {
  const [curr_sym, setCurrSym] = useState("");
  const [balance, setBalance] = useState("");
  const [total_properties, setTotalProperties] = useState("");
  const [total_rooms, setTotalRooms] = useState("");
  const [total_boats, setTotalBoats] = useState("");
  const [total_room_category, setTotalRoomCategory] = useState("");
  const { langData, fetchLangData, translate } = useLanguage();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "owner/dashboard");
      setCurrSym(response?.data?.curr_sym);
      setBalance(response?.data?.balance);
      setTotalProperties(response?.data?.total_properties);
      setTotalRooms(response?.data?.total_rooms);
      setTotalRoomCategory(response?.data?.total_room_category);
      setTotalBoats(response?.data?.total_boats);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      <div className="addProperty-container">
        {/* <div className="addproperty-btnWrapper">
          <button className="addProperty-addBtn">
            <Link to="/all-property">
              <BsArrowLeft />
            </Link>
          </button>
          <h2>{translate("My Profile")}</h2>
        </div> */}
        <div className="row mt-3">
          {!loading && (
            <div className="col-lg-12 col-md-12 col-12">
              <div className="supportTicket-bg">
                <div
                  className="table-responsive"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="supportTicket-wrapper">
                    <div className="row mx-1">
                      <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                        <div className="card">
                          <div className="card-header header-box">
                            {translate("Available Balance")}
                          </div>
                          <div className="card-body d-flex justify-content-end">
                            <h2>
                              {curr_sym} {showAmount(balance)}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                        <div className="card">
                          <div className="card-header header-box">
                            {translate("Total Properties")}
                          </div>
                          <div className="card-body d-flex justify-content-end">
                            <h2>{addLeadingZero(total_properties)}</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                        <div className="card">
                          <div className="card-header header-box">
                            {translate("Total Rooms")}
                          </div>
                          <div className="card-body d-flex justify-content-end">
                            <h2>{addLeadingZero(total_rooms)}</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                        <div className="card">
                          <div className="card-header header-box">
                            {translate("Total Room Categories")}
                          </div>
                          <div className="card-body d-flex justify-content-end">
                            <h2>{addLeadingZero(total_room_category)}</h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                        <div className="card">
                          <div className="card-header header-box">
                            {translate("Total Boats")}
                          </div>
                          <div className="card-body d-flex justify-content-end">
                            <h2>{addLeadingZero(total_boats)}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
