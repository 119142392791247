import React, { useEffect, useState } from "react";
import "./MyTicket.css";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";
import calculateTimeDifference from "../../utils/diffForHumans";

function MyTicket() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/ticket");
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="mainContainer">
      <div className="myTicket-headingWrapper">
        <div className="myTicket-heading">
          <h1>{translate("Support Tickets")}</h1>
          <p>
            {translate("Total Tickets")}
            {/* <span>00</span> */}
          </p>
        </div>
      </div>

      <div className="table-responsive">
        <div className="table-wrapper">
          <table id="mytable" className="table table-bordred table-striped">
            <thead className="myTicket-table-th">
              <th>{translate("Ticket No.")}</th>
              <th>{translate("Subject")}</th>
              <th>{translate("Status")}</th>
              <th>{translate("Priority")}</th>
              <th>{translate("Last Reply")}</th>
              {/* <th>{translate("Action")}</th> */}
            </thead>
            <tbody>
              {data?.supports?.data?.map((s, i) => (
                <tr key={i} className="text-center">
                  <td>
                    {translate("#")}
                    {s.ticket}
                  </td>
                  <td>{s.subject}</td>
                  <td>
                    {s.status === 0 && (
                      <span className="badge badge-success">
                        {translate("Open")}
                      </span>
                    )}
                    {s.status === 1 && (
                      <span className="badge badge-primary">
                        {translate("Answered")}
                      </span>
                    )}
                    {s.status === 2 && (
                      <span className="badge badge-warning">
                        {translate("Customer Reply")}
                      </span>
                    )}
                    {s.status === 3 && (
                      <span className="badge badge-dark">
                        {translate("Closed")}
                      </span>
                    )}
                  </td>
                  <td>
                    {s.priority === 1 && (
                      <span className="badge badge-dark">
                        {translate("Low")}
                      </span>
                    )}
                    {s.priority === 2 && (
                      <span className="badge badge-success">
                        {translate("Medium")}
                      </span>
                    )}
                    {s.priority === 3 && (
                      <span className="badge badge-primary">
                        {translate("High")}
                      </span>
                    )}
                  </td>
                  <td>{calculateTimeDifference(s.last_reply)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="myTicket-Img">
        <img src="/assets/icons/pendingProperty-img.png" alt="..." />
      </div> */}
    </div>
  );
}

export default MyTicket;
