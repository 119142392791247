import React from "react";
import "./BoatSearchEngine.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import { useState } from "react";
import makeHttpRequest from "../../utils/api";
import { Grid, TextField } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import { useLanguage } from "../../utils/LanguageContext";

function BoatSearchEngine({
  boat_types,
  locations,
  onDataReceived,
  locationId,
  boat_typeId,
}) {
  const [location, setLocation] = React.useState("");
  const [search_type, setSearchType] = useState(boat_typeId);

  const [type, setType] = useState("boat");
  const [loading, setLoading] = useState(false);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [adult, setAdult] = useState(0);
  const [child, setChild] = useState(0);
  const { langData, fetchLangData, translate } = useLanguage();

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date().setDate(new Date().getDate() + 1),
      key: "selection",
    },
  ]);

  const handleSearchCategory = (para) => {
    setType(para);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  const handleAdultChange = (event) => {
    setAdult(event.target.value);
  };

  const handleChildChange = (event) => {
    setChild(event.target.value);
  };

  const handleSubmitBoatSearch = async () => {
    try {
      const { startDate, endDate } = dateRange[0];
      let start = formatDate(startDate);
      let end = formatDate(endDate);

      setLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `boat?boatType=${search_type}&location=${location}&start=${start}&end=${end}&adult=${adult}&child=${child}`
      );
      // onDataReceived('res.data this is coming from child');
      onDataReceived(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const formatDate = (arg) => {
    const date = new Date(arg);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    return formattedDate;
  };

  const handleSelect = (ranges) => {
    // Update the date range when a user selects dates
    setDateRange([ranges.selection]);
    setShowDateRangePicker(false); // Close the DateRangePicker after selecting
  };

  const toggleDateRangePicker = () => {
    setShowDateRangePicker(!showDateRangePicker);
  };
  return (
    <div className="search-engine-wrapper">
      <div className="row wrapper">
        <div className="col-lg-2 col-md-3 mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {translate("Location")}
            </InputLabel>
            <Select
              // className="mainSearch-dropdown"
              // labelId="demo-simple-select-label"
              variant="outlined"
              id="demo-simple-select"
              value={location ? location : locationId}
              label={translate("Location")}
              onChange={handleLocationChange}
            >
              {locations?.map((t, i) => (
                <MenuItem key={i} value={t.id}>
                  {translate(t.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-3 col-md-3  mt-3">
          <Grid item md={3} sm={6} xs={12} spacing={3}>
            <div>
              <TextField
                fullWidth
                variant="outlined"
                label={`${translate("Checkin")} ${translate("-")} ${translate(
                  "Checkout"
                )}`}
                value={`${formatDate(dateRange[0].startDate)} - ${formatDate(
                  dateRange[0].endDate
                )}`}
                onClick={toggleDateRangePicker}
                InputProps={{
                  readOnly: true,
                }}
              />
              {showDateRangePicker && (
                <DateRangePicker
                  localeText={{ start: "Check-in", end: "Check-out" }}
                  ranges={[dateRange[0]]}
                  onChange={handleSelect}
                />
              )}
            </div>
          </Grid>
        </div>
        <div className="col-lg-2 col-md-3  mt-3">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {translate("Boat Type")}
            </InputLabel>
            <Select
              // className="mainSearch-dropdown"
              variant="outlined"
              id="demo-simple-select"
              value={search_type}
              label={translate("Boat Type")}
              onChange={handleTypeChange}
            >
              {boat_types?.map((t, i) => (
                <MenuItem key={i} value={t.id}>
                  {translate(t.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-3 col-md-3">
          <div className="row">
            <div className="col-lg-6 col-md-6  mt-3">
              <TextField
                fullWidth
                label={translate("Adult")}
                variant="outlined"
                name="adults"
                value={adult}
                onChange={handleAdultChange}
              />
            </div>
            <div className="col-lg-6 col-md-6  mt-3">
              <TextField
                fullWidth
                label={translate("Child")}
                variant="outlined"
                name="childs"
                value={child}
                onChange={handleChildChange}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-3  mt-3 show-accommodation">
          <button onClick={handleSubmitBoatSearch}>
            <img src="/assets/icons/Vector.png" alt="..." />
            {translate("Show Boats")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default BoatSearchEngine;
