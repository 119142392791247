import React, { useState, useEffect } from "react";
import "./AllLocations.css";
import Navbar from "../../Components/Navbar/Navbar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import makeHttpRequest from "../../utils/api";
import { CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
  p: 4,
  borderRadius: "16px",
  border: "none",
};

function AllLocations() {
  const navigate = useNavigate();
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [age, setAge] = React.useState("");
  const [page_title, setPageTitle] = useState(null);
  const [empty_message, setEmptyMessage] = useState(null);
  const [locations, setLocations] = useState([]);
  const { langData, fetchLangData, translate } = useLanguage();
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoader] = useState(false);
  const [data, setData] = useState("");
  const [type, setType] = useState("property");

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  // Fetch location data
  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        setLoader(true);
        const response = await makeHttpRequest(
          "GET",
          `locations?page=${currentPage}`
        );
        setData(response?.data?.location);
        setPageTitle(response.data.page_title);
        setEmptyMessage(response.data.empty_message);
        setLocations(response.data.locations.data);
        setLastPage(response.data.locations.last_page);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error is:", error);
      }
    };
    fetchLocationData();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= lastPage) {
      setCurrentPage(newPage);
    }
  };

  const handleLocationFn = async (id) => {
    setLoader(true);
    if (type === "property") {
      const res = await makeHttpRequest(
        "GET",
        `search?type=${type}&location=${id}`
      );
      navigate("/search", { state: res });
    } else {
      const res = await makeHttpRequest("GET", `boat?location=${id}`);
      navigate("/boat", { state: res });
    }
    setLoader(false);
  };

  return (
    <div>
      <Navbar />
      {loading && (
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && (
        <div className="container">
          <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="container">
                  <h1 className="laocationModal-heading">Your Policy</h1>
                  <div className="locationModal-content">
                    <p className="locationModal-discription">
                      We use cookies and similar technologies to deliver tailor
                      content, personalize and evaluate the advertisements and
                      offer an experience of better use. By clicking on 0K or
                      activating an option in Cookie Preferences, you accept as
                      indicated as specified in our Cookie Policy. To change
                      your preferences or withdraw consent, update your
                      Preferences of cookies.
                    </p>
                    <div className="modalOkay-btn-wrapper">
                      <Link>
                        <button onClick={handleClose} className="modalOkay-btn">
                          {translate("Okay")}
                        </button>
                      </Link>
                      <Link>
                        <button className="modalCookie-btn">
                          Cookie Preference
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>

          <div className="allLocation-heading text-center">
            <div className="allLocation-topImg">
              <img src="/assets/icons/aa1.png" alt="..." />
            </div>
            <div className="allLocation-bottom">
              <img src="/assets/icons/aa2.jpeg" alt="..." />
            </div>
            <h1>{translate(data?.heading)}</h1>
            <p>{translate(data?.sub_heading)}</p>
            <div className="allLocation-dropdown-wrapper">
              <FormControl className="allLocation-dropdown">
                <InputLabel id="demo-simple-select-label">
                  {type
                    ? translate("Select" + " " + type)
                    : translate("Select Type")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label={
                    type
                      ? translate("Select " + type)
                      : translate("Select Type")
                  }
                  onChange={handleTypeChange}
                >
                  <MenuItem value={"boat"}>{translate("Boat")}</MenuItem>
                  <MenuItem value={"property"}>
                    {translate("Property")}
                  </MenuItem>
                </Select>
              </FormControl>
              <div className="show-location">
                {/* <Link
                //  to="/Home/locationsCookie"
                >
                  <Button onClick={handleOpen} variant="contained">
                    <img src="/assets/icons/Vector.png" alt="..." />
                    {type
                      ? translate("Search") + " " + type
                      : translate("Search Type")}
                  </Button>
                </Link> */}
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{ marginTop: "50px", marginBottom: "100px" }}
          >
            {locations &&
              locations?.map((d, index) => {
                return (
                  <div className="col-lg-3 col-md-6 col-sm-12 mt-5" key={index}>
                    <div
                      className="allLocation-img"
                      onClick={(e) => handleLocationFn(d.id)}
                      style={{
                        backgroundImage: `url(${d.image})`,
                        cursor: "pointer",
                      }}
                    >
                      <div className="all-locationPrice-wrapper">
                        <h4>{translate(d.name)}</h4>
                        <div className="allLocation-capariPrice">
                          <p>{translate(d.priceTitle)}</p>
                          <h6>
                            {d.average_price} <span>{translate(d.shift)}</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="pagination-wrapper mt-3">
              <button onClick={() => handlePageChange(currentPage - 1)}>
                {translate("Prev")}
              </button>
              <span>
                {translate("Page")} {currentPage} {translate("of")} {lastPage}
              </span>
              <button onClick={() => handlePageChange(currentPage + 1)}>
                {translate("Next")}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <div className="allLocationPage-fastBoatCards">
        <FastBoatCards />
      </div> */}
      {!loading && (
        <div className="allLocationPage-footer">
          <Footer />
        </div>
      )}
    </div>
  );
}

export default AllLocations;
