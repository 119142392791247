import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { Link } from "react-router-dom";
import "./AllBoat.css";
import { showAmount } from "../../utils/showAmount";
import { useLanguage } from "../../utils/LanguageContext";
import { addLeadingZero } from "../../utils/addLeadingZero";

function AllBoat() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/boats");
      setData(res.data);
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteThisItem = async (id) =>{
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/boat/destroy/"+id);
      getData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <div className="mainContainer">
      <div className="allBoat-headingWrapper">
        <div className="allBoat-heading">
          <h1>{translate("All Boat")}</h1>
          <p>
            {translate("Total Boat")} <span>{addLeadingZero(data?.boats?.total)}</span>
          </p>
        </div>
        <div className="">
          <button className="allBoat-addBtn">
            <Link to="/add-boat">
              {translate("+")} {translate("Add New Boat")}
            </Link>
          </button>
        </div>
      </div>

      <div className="table-responsive">
        <div className="table-wrapper">
          <table id="mytable" className="table table-bordred table-striped">
            <thead className="allBoat-table-th">
              <th>{translate("S.N.")}</th>
              <th>{translate("Name")}</th>
              <th>{translate("Boat Type")}</th>
              <th>{translate("Location")}</th>
              <th>{translate("Discount")}</th>
              <th>{translate("Status")}</th>
              <th>{translate("Action")}</th>
            </thead>
            <tbody>
              {data?.boats?.data?.map((row, index) => (
                <tr key={index} className="allBoat-td-wrapper">
                  <td style={{ paddingTop: "18px" }}>
                    <div className="sr-no-bg">{index + 1}</div>
                  </td>
                  <td style={{ paddingTop: "20px" }}>{row.name}</td>
                  <td style={{ paddingTop: "20px" }}>{row.boat_type.name}</td>
                  <td style={{ paddingTop: "20px" }}>{row.location.name}</td>
                  <td style={{ paddingTop: "20px" }}>
                    {showAmount(row.discount)}
                    {translate("%")}
                  </td>
                  <td style={{ paddingTop: "20px" }} className="allBoat-status">
                    {row.status === 1 ? (
                      <span className="badge badge-success">
                        {translate("Active")}
                      </span>
                    ) : (
                      <span className="badge badge-warning">
                        {translate("Inactive")}
                      </span>
                    )}
                  </td>
                  <td>
                    {/* <Link to="/update-boat" className="allBoat-view-bg"> */}
                    <Link
                          to={`/boat/${row.id}/edit`}
                          className="allBoat-view-bg"
                        >
                      {translate(" Edit")}
                    </Link>
                    <div className="text-danger" onClick={()=>deleteThisItem(row?.id)} style={{cursor:"pointer"}}>{translate('Delete')}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AllBoat;
