import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { useLanguage } from "../../utils/LanguageContext";
import { showAmount } from "../../utils/showAmount";
import makeHttpRequest from "../../utils/api";
import { useNavigate } from "react-router-dom";

const LocationCarousal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading, error } = useSelector((state) => state.landing);
  const { langData, fetchLangData, translate } = useLanguage();

  const [selected_type_location, setSelectedTypeLocation] =
    useState("property");

  const settings = {
    arrow: false,
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [  
      {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
     
    ],
  };

  useEffect(() => {
    // Fetch data if not available in the store
    if (!data) {
      // Add your data fetching logic here
    }
  }, [data]);

  const handleLocationFn = async (id) => {
    try {
      if (selected_type_location === "property") {
        const res = await makeHttpRequest(
          "GET",
          `search?type=${selected_type_location}&location=${id}`
        );
        navigate("/search", { state: res });
      } else {
        const res = await makeHttpRequest("GET", `boat?location=${id}`);
        navigate("/boat", { state: res });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      {loading && <p>{translate("Loading...")}</p>}
      {error && (
        <p>{translate("Error loading data. Please try again later.")}</p>
      )}

      <Slider {...settings}>
        {data?.locations?.map((location, index) => (
          <div
            onClick={() => handleLocationFn(location.id)}
            key={index}
            className="col-lg-12 col-md-12 col-12 "
          >
            <div
              className="background-image-container discoverLocation-img"
              style={{
                backgroundImage: `url("${
                  location?.image || "/fallback-image-url.jpg"
                }")`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "100% ",
                height: "500px",
              }}
            >
              <div className="locationPrice-wrapper p-2">
                <h4>{translate(location?.name)}</h4>
                <div className="location-capariPrice">
                  <p>{translate("Average price")}</p>
                  <h6>
                    {translate(data?.curr_sym)}
                    {showAmount(location?.average_price)}
                    <span>&nbsp;{translate("Night")}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LocationCarousal;
