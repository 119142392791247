import React from "react";
import "./Style.css";

function CenterHeading({ h1, p, justify }) {
  return (
    <div className="center-heading">
      <div className="ch-main-heading">       
        <h1>
        <img className="img1" src="/assets/icons/aa1.png" alt="..." />
          {h1}
          <img className="img2" src="/assets/icons/aa2.jpeg" alt="..." />
        </h1>
      </div>
      <p>{p}</p>
    </div>
  );
}

export default CenterHeading;
