import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import { Link } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";
import "./WithdrawMoney.css";
import { useLanguage } from "../../utils/LanguageContext";
import he from "he";


function WithdrawMoney() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("/assets/icons/walletImg.png");
  const [min_limit, setMinLimit] = useState(0);
  const [max_limit, setMaxLimit] = useState(0);
  const [fixed_charge, setFixedCharge] = useState(0);
  const [percent_charge, setPercentCharge] = useState(0);
  const [delay, setDelay] = useState(0);
  const [currency, setCurrenct] = useState("|||");
  const [form, setForm] = useState("");
  const { langData, fetchLangData, translate } = useLanguage();
  const [dynamic_data, setDynamicData] = useState({});



  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/withdraw");
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  async function handleWithdraw() {
    try {
      let val = document.getElementById("amount").value;

      setLoading(true);
      const formData = new FormData();
      formData.append("amount", val);
      formData.append("method_code", id);
      formData.append("currency", currency);
      for (const key in dynamic_data) {
        formData.append(key, dynamic_data[key]);
      }
      await makeHttpRequest("POST", `owner/withdraw`, formData);
      setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  }

  function onPayoutMethodChange(index) {
    if (index !== "") {
      let payout = data?.withdraw_method[index];
      setId(payout?.id);
      setImage(payout?.image);
      setName(payout?.name);
      setMinLimit(showAmount(payout?.min_limit));
      setMaxLimit(showAmount(payout?.max_limit));
      setFixedCharge(showAmount(payout?.fixed_charge));
      setPercentCharge(showAmount(payout?.percent_charge));
      setDelay(payout?.delay);
      setCurrenct(payout?.currency);
      getWithdrawForm(payout?.id);
    } else {
      setName("Wallet");
      setImage("/assets/icons/walletImg.png");
      setMinLimit(0);
      setMaxLimit(0);
      setFixedCharge(0);
      setPercentCharge(0);
      setDelay(0);
      setCurrenct("|||");
      setForm(null);

    }
  }

  const getWithdrawForm = async (id) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("POST", `owner/withdraw/form`, {
        id:id,
      });
      setLoading(false);
      setForm(res?.data);
    } catch (error) {
      // setLoading(false);
    }
  }

  const handleInputChange = (e, key) => {
    const { type, value, files } = e.target;

    setDynamicData((prevData) => ({
      ...prevData,
      [key]: type == "file" ? files[0] : value,
    }));
  };
  return (
    <div className="withdrawMoney-container">
      <div className="withdrawMoney-Bg">
        <div className="row pb-4">
          <div className="col-lg-5 col-md-6 col-12">
            <img src={image} alt={name} width={400} height={400} />
          </div>
          <div className="col-lg-7 col-md-6 col-12 p-5">
            <div className="withdrawMoney-contentBg mt-3">
              <h1>{translate('Method')}:</h1>
              <select
                className="form-control w-50"
                onChange={(e) => onPayoutMethodChange(e.target.value)}
              >
                <option value={""}>{translate('Select Method')}</option>
                {data?.withdraw_method?.map((method, index) => (
                  <option key={index} value={index}>
                    {method?.name}
                  </option>
                ))}
              </select>
            </div>
            {min_limit > 0 || max_limit > 0 ? (
              <div className="withdrawMoney-contentBg mt-3">
                <h1>Limit:</h1>
                <p>
                  {min_limit} - {max_limit} {data?.curr_name}
                </p>
              </div>
            ) : (
              <div className="withdrawMoney-contentBg mt-3">
                <h1>Limit:</h1>
                <p>
                  <i style={{ fontWeight: 50 }}>Not Apply</i>
                </p>
              </div>
            )}

            {fixed_charge > 0 || percent_charge > 0 ? (
              <div className="withdrawMoney-contentBg mt-3">
                <h1>{translate('CHARGE')}:</h1>
                <p>
                  {fixed_charge} {data?.curr_name} + {percent_charge}%
                </p>
              </div>
            ) : (
              <div className="withdrawMoney-contentBg mt-3">
                <h1>{translate('CHARGE')}:</h1>
                <p>
                  <i style={{ fontWeight: 50 }}>{translate('Not Apply')}</i>
                </p>
              </div>
            )}
            {delay >= 0 && (
              <div className="withdrawMoney-contentBg mt-3">
                <h1>{translate('PROCESSING TIME')}</h1>
                <p>{delay}</p>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          {form?.withdraw?.description && (
            <div className="col-lg-6 col-md-6 col-sm-12 pr-5 pl-5">
              <div className="border border-success px-4 m-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: form?.withdraw?.description
                      ? he.decode(form?.withdraw?.description)
                      : "",
                  }}
                ></div>
              </div>
            </div>
          )}
          <div class="col-lg-6 col-md-6 col-sm-12 pr-5 pl-5 my-2">
            {form?.withdraw?.user_data && Object.keys(form?.withdraw?.user_data).map((v, k) => (
              <div key={k} className="signUp-password mt-3">
                <label>
                  {translate(form?.withdraw?.user_data[v].field_level)}{" "}
                  {form?.withdraw?.user_data[v].validation === "required" && (
                    <span className="text-danger">{translate("*")}</span>
                  )}
                </label>
                {form?.withdraw?.user_data[v].type === "text" && (
                  <input
                    type="text"
                    name={form?.withdraw?.user_data[v].field_name}
                    className="form-control"
                    value={dynamic_data[form?.withdraw?.user_data[v].field_name] || ""}
                    onChange={(e) =>
                      handleInputChange(e, form?.withdraw?.user_data[v].field_name)
                    }
                    placeholder={form?.withdraw?.user_data[v].field_level}
                    required={form?.withdraw?.user_data[v].validation === "required"}
                  />
                )}
                {form?.withdraw?.user_data[v].type === "textarea" && (
                  <textarea
                    name={form?.withdraw?.user_data[v].field_name}
                    className="form-control"
                    placeholder={form?.withdraw?.user_data[v].field_level}
                    rows="3"
                    value={dynamic_data[form?.withdraw?.user_data[v].field_name] || ""}
                    onChange={(e) =>
                      handleInputChange(e, form?.withdraw?.user_data[v].field_name)
                    }
                    required={form?.withdraw?.user_data[v].validation === "required"}
                  />
                )}
                {form?.withdraw?.user_data[v].type === "file" && (
                  <>
                    <div
                      className="fileinput fileinput-new"
                      data-provides="fileinput"
                    >
                      <input
                        type="file"
                        name={form?.withdraw?.user_data[v].field_name}
                        className="form-control"
                        onChange={(e) =>
                          handleInputChange(e, form?.withdraw?.user_data[v].field_name)
                        }
                        placeholder={form?.withdraw?.user_data[v].field_level}
                        required={form?.withdraw?.user_data[v].validation === "required"}
                      />
                    </div>
                    {form?.withdraw?.user_data.errors &&
                      form?.withdraw?.user_data.errors[form?.withdraw?.user_data[v].field_name] && (
                        <span className="text-danger">
                          {form?.withdraw?.user_data.errors[form?.withdraw?.user_data[v].field_name]}
                        </span>
                      )}
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="col-12 pr-5 pl-5">
            <div
              className=" row withdrawMoney-amountBg"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-5 col-md-6 col-12 withdrawMoney-amountHeading">
                <h1>{translate('ENTER AMOUNT')}:</h1>
              </div>

              <div className="col-lg-7 col-md-6 col-12">
                <div className="input-group withdrawMoney-input ">
                  <input
                    id="amount"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value.replace(
                        /^\.|[^\d\.]/g,
                        ""
                      );
                    }}
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      style={{ background: "#45AAF0", color: "#FFF" }}
                    >
                      {currency}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="withdrawMoney-btn mt-2">
          <button onClick={handleWithdraw}>
            <Link>{translate('WITHDRAW NOW')}</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WithdrawMoney;
