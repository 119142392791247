import React, { useEffect, useState } from "react";
import classes from "./Reviews.module.css";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import makeHttpRequest from "../../utils/api";
import Footer from "../../Components/Footer/Footer";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import Navbar from "../../Components/Navbar/Navbar";
import { MdClose } from "react-icons/md";
import { useLanguage } from "../../utils/LanguageContext";

let style = {};
const isMobile = window.innerWidth <= 767;
if (isMobile) {
  style = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
} else {
  style = {
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
}

function Reviews() {
  const handleClose = () => setOpen(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [inputReview, setInputReview] = useState("");
  const [propertyId, setPropertyId] = useState(0);
  const [boatId, setBoatId] = useState(0);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { langData, fetchLangData, translate } = useLanguage();
  const handleOpen = (id, type, rating = "", des = "") => {
    setInputReview(des);
    setSelectedValue(rating);
    if (type === "boat") {
      setBoatId(id);
    } else {
      setPropertyId(id);
    }
    setOpen(true);
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "user/reviews");
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRateChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleReviewChange = (e) => {
    setInputReview(e.target.value);
  };

  const storeFeedback = async () => {
    try {
      setLoading(true);
      let obj = {
        rating: selectedValue,
        description: inputReview,
      };
      if (propertyId != 0) {
        obj.property_id = propertyId;
      } else if (boatId != 0) {
        obj.boat_id = boatId;
      }
      const res = await makeHttpRequest("POST", "user/reviews/store", obj);
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className={classes["page-container"]}>
        <div className="row">
          <div className="col-lg-3 col-md-3 d-none d-lg-block">
            <UserSideNavbar />
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className={classes["reviewmodal-wrapper"]}>
                <Box sx={style}>
                  <div className={classes["reviewmodal-heading"]}>
                    <h1>{translate("Feedback")}</h1>
                    <MdClose
                      onClick={handleClose}
                      style={{
                        color: "#FFF",
                        cursor: "pointer",
                        fontSize: "38px",
                        paddingRight: "10px",
                      }}
                    />
                  </div>
                  <div className={classes["reviewsubmit-inputs"]}>
                    <label>{translate("Stars")}</label>
                    <select
                      className="form-control mb-3"
                      onChange={handleRateChange}
                      value={selectedValue}
                    >
                      {(() => {
                        const options = [];
                        for (let i = 1; i <= 5; i++) {
                          options.push(
                            <option
                              key={i}
                              value={i}
                              selected={selectedValue === i ? "selected" : ""}
                            >
                              {i}{" "}
                              {i < 2
                                ? " " + translate("Star")
                                : translate("Stars")}
                            </option>
                          );
                        }
                        return options;
                      })()}
                    </select>
                    {/* <input type="text" placeholder="Text area....." /> */}
                    <label>{translate("Write feedback")}</label>
                    <textarea
                      className="form-control"
                      onKeyUp={handleReviewChange}
                    >
                      {inputReview}
                    </textarea>
                    <div className={classes["reviewsubmit-wrapper"]}>
                      <Link>
                        <button
                          onClick={storeFeedback}
                          className={classes["reviewsubmit-btn"]}
                        >
                          {translate("Submit")}
                        </button>
                      </Link>
                    </div>
                  </div>
                </Box>
              </div>
            </Modal>
            {/* Property Waiting for Review */}
            {/* <div className="table-heading">
              <h1>{translate('All Reviews')}</h1>
            </div> */}
            {data?.pendingReviewProperties?.length > 0 && (
              <div className={`${classes['reviews-bg']} text-center`}>
                <div className={classes["reviews-viewallWrapper"]}>
                  <h2>{translate("Property Waiting for Review")}</h2>
                  <button className={classes["reviews-viewallBtn"]}>
                    <Link to={"/user-pending-reviews/Property"}>
                      {translate("View All")}
                    </Link>
                  </button>
                </div>
                <div
                  className="table-responsive"
                  style={{ borderRadius: "10px" }}
                >
                  <div className={classes["table-wrapper"]}>
                    <table
                      id="mytable"
                      className="table table-bordred table-striped"
                    >
                      <thead className={classes["reviews-table-th"]}>
                        <th>{translate("Property Name")}</th>
                        <th>{translate("Location")}</th>
                        <th>{translate("Phone")}</th>
                        <th>{translate("Rate")}</th>
                      </thead>
                      <tbody>
                        {data?.pendingReviewProperties?.map((p, i) => (
                          <tr key={i}>
                            <td>{p.name}</td>
                            <td>{p?.location?.name}</td>
                            <td>{p.phone ? p?.phone : "Not Available"}</td>
                            <td>
                              <AiFillStar
                                style={{
                                  color: "#f2c821",
                                  cursor: "pointer",
                                  fontSize: "25px",
                                }}
                                onClick={() => handleOpen(p.id, "property")}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {/* Reviewed Property */}
            {data?.reviewedProperties?.length > 0 && (
              <div className={`${classes['reviews-bg']} text-center mt-4`}>
                <div className={classes["reviews-viewallWrapper"]}>
                  <h2>{translate("Reviewed Property")}</h2>
                  <button className={classes["reviews-viewallBtn"]}>
                    <Link to={"/user-completed-reviews/Property"}>
                      {translate("View All")}
                    </Link>
                  </button>
                </div>

                <div
                  className="table-responsive"
                  style={{ borderRadius: "10px" }}
                >
                  <div className={classes["table-wrapper"]}>
                    <table
                      id="mytable"
                      className="table table-bordred table-striped"
                    >
                      <thead className={classes["reviews-table-th"]}>
                        <th>{translate("Property Name")}</th>
                        <th>{translate("Location")}</th>
                        <th>{translate("Phone")}</th>
                        <th>{translate("Rated")}</th>
                      </thead>
                      <tbody>
                        {data?.reviewedProperties?.map((p, i) => (
                          <tr key={i}>
                            <td>{p.name}</td>
                            <td>{p?.location?.name}</td>
                            <td>{p.phone ? p?.phone : "Not Available"}</td>
                            <td>
                              <AiFillStar
                                style={{
                                  color: "#f2c821",
                                  cursor: "pointer",
                                  fontSize: "25px",
                                }}
                                onClick={() =>
                                  handleOpen(
                                    p.id,
                                    "property",
                                    p?.review?.rating,
                                    p?.review?.description
                                  )
                                }
                              />
                              ({p?.review?.rating})
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {/* Boat Waiting for Review */}
            {data?.pendingReviewBoats?.length > 0 && (
              <div className={`${classes['reviews-bg']} text-center mt-4`}>
                <div className={classes["reviews-viewallWrapper"]}>
                  <h2>{translate("Boat Waiting for Review")}</h2>
                  <button className={classes["reviews-viewallBtn"]}>
                    <Link to={"/user-pending-reviews/Boat"}>
                      {translate("View All")}
                    </Link>
                  </button>
                </div>

                <div
                  className="table-responsive"
                  style={{ borderRadius: "10px" }}
                >
                  <div className={classes["table-wrapper"]}>
                    <table
                      id="mytable"
                      className="table table-bordred table-striped"
                    >
                      <thead className={classes["reviews-table-th"]}>
                        <th>{translate("Boat Name")}</th>
                        <th>{translate("Location")}</th>
                        <th>{translate("Phone")}</th>
                        <th>{translate("Rate")}</th>
                      </thead>
                      <tbody>
                        {data?.pendingReviewBoats?.map((p, i) => (
                          <tr key={i}>
                            <td>{p.name}</td>
                            <td>{p?.location?.name}</td>
                            <td>{p.phone ? p?.phone : "Not Available"}</td>
                            <td>
                              <AiFillStar
                                style={{
                                  color: "#f2c821",
                                  cursor: "pointer",
                                  fontSize: "25px",
                                }}
                                onClick={() => handleOpen(p.id, "boat")}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {/* Reviewed Boat*/}
            {data?.reviewedBoats?.length > 0 && (
              <div className={`${classes['reviews-bg']} text-center mt-4`}>
                <div className={classes["reviews-viewallWrapper"]}>
                  <h2>{translate("Reviewed Boat")}</h2>
                  <button className={classes["reviews-viewallBtn"]}>
                    <Link to={"/user-completed-reviews/Boat"}>
                      {translate("View All")}
                    </Link>
                  </button>
                </div>

                <div
                  className="table-responsive"
                  style={{ borderRadius: "10px" }}
                >
                  <div className={classes["table-wrapper"]}>
                    <table
                      id="mytable"
                      className="table table-bordred table-striped"
                    >
                      <thead className={classes["reviews-table-th"]}>
                        <th>{translate("Boat Name")}</th>
                        <th>{translate("Location")}</th>
                        <th>{translate("Phone")}</th>
                        <th>{translate("Rated")}</th>
                      </thead>
                      <tbody>
                        {data?.reviewedBoats?.map((p, i) => (
                          <tr key={i}>
                            <td>{p.name}</td>
                            <td>{p?.location?.name}</td>
                            <td>{p.phone ? p?.phone : "Not Available"}</td>
                            <td>
                              <AiFillStar
                                style={{
                                  color: "#f2c821",
                                  cursor: "pointer",
                                  fontSize: "25px",
                                }}
                                onClick={() =>
                                  handleOpen(
                                    p.id,
                                    "boat",
                                    p?.review?.rating,
                                    p?.review?.description
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={classes["reviews-tablecontent"]}>
                  <p>{translate("No Boat Found")}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Reviews;
