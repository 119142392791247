import React, { useEffect, useState } from 'react';
import ReactImagePickerEditor from 'react-image-picker-editor';
import { imageUrlToBase64 } from '../../utils/helpers';
import { CircularProgress, Stack } from '@mui/material';

function ImageEditor({ imageData, imageDataBase, setImageDataBase, handleImageChange }) {
    const [loadingImage, setLoadingImage] = useState([]);
    const [base64Images, setBase64Images] = useState([]);

    const setValueAtIndex = (index, value) => {
        const newArray = [...imageDataBase];
        newArray[index] = value;

        // Set the new array as the state
        setImageDataBase(newArray);
    };
    const fetchBase64Images = async () => {
        setLoadingImage(true);
        const base64Promises = imageData.map(async (image, index) => {
            const base64Data = await imageUrlToBase64(image?.selected);
            await setValueAtIndex(index, base64Data);
            return base64Data;
        });
        const base64Results = await Promise.all(base64Promises);
        setBase64Images(base64Results);
        setLoadingImage(false);
    };
    useEffect(() => {
        fetchBase64Images();
    }, [imageData]);
    const config2 = {
        borderRadius: "16px",
        language: "en",
        width: '330px',
        height: '250px',
        objectFit: 'contain',
        compressInitial: null,
        hideDownloadBtn: true,
        hideDeleteBtn:true,
        hideEditBtn:false,
        hideAddBtn:false
      };
    return (
        <>
                {loadingImage? (
                    <Stack alignItems="center" justifyContent="center">
                        <CircularProgress size={20} sx={{ color: "#8bc53e" }} />
                    </Stack>
                    ):(
                        base64Images.map((base64Image, index) => (
                            <div key={index} className="col-lg-6 col-md-12 col-12 mt-5 d-flex justify-content-center">
                                <ReactImagePickerEditor 
                                    imageSrcProp={base64Image}
                                    imageChanged={(newImage) =>
                                        newImage && handleImageChange(index, newImage)
                                    }
                                    config={config2}
                                />
                            </div>
                        ))
                    )}
        </>
    );
}

export default ImageEditor;