import React, { useEffect, useState } from "react";
import "./BlogDetail.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { BsArrowRightShort } from "react-icons/bs";
import Footer from "../../Components/Footer/Footer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Navbar from "../../Components/Navbar/Navbar";
import makeHttpRequest from "../../utils/api";
import { Box, CircularProgress, Stack } from "@mui/material";
import he from "he";
import { useLanguage } from "../../utils/LanguageContext";
import { title } from "../../utils/helpers";

const renderArrowPrev = (onClickHandler, hasPrev, label) => {
  return (
    <button
      type="button"
      onClick={onClickHandler}
      title={label}
      style={{
        position: "absolute",
        zIndex: 2,
        top: "calc(50% - 15px)",
        left: 0,
        border: "none",
        background: "none",
        cursor: "pointer",
        padding: 0,
        outline: "none",
      }}
    >
      {hasPrev && (
        <IoIosArrowBack
          style={{
            color: "black",
            fontSize: "30px",
            background: "#8bc53e",
            borderRadius: "50px",
            padding: "5px",
            color: "#FFF",
          }}
        />
      )}
    </button>
  );
};


function BlogDetails() {
  const { id, slug } = useParams();
  const navigate = useNavigate();
  // const { selectedBlog } = useSelector((state) => state.blogs);
  const [selectedBlog, setSelectedBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [selectedOption, setSelectedOption] = useState("offer1");
  const { langData, fetchLangData, translate } = useLanguage();

  useEffect(() => {
    if (id && slug) {
      getData();
    } else {
      navigate("/blog");
    }
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `blog/${id}/${slug}`);
      // dispatch(addBlogs(response.data));
      if (res.data.recent_blogs) {
        setRecentBlogs(res.data.recent_blogs);
      }
      if (res.data.related_blogs) {
        setRelatedBlogs(res.data.recent_blogs);
      }
      if (res.data.blog) {
        setSelectedBlog(res.data.blog);
      }
      document.title = title(res?.data?.blog?.name)
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const openNextBlog = async (id, slug) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `blog/${id}/${slug}`);
      // dispatch(addBlogs(response.data));
      if (res.data.recent_blogs) {
        setRecentBlogs(res.data.recent_blogs);
      }
      if (res.data.related_blogs) {
        setRelatedBlogs(res.data.recent_blogs);
      }
      if (res.data.blog) {
        setSelectedBlog(res.data.blog);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <Box>
        {loading && (
          <Stack height="100vh" alignItems="center" justifyContent="center">
            <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
          </Stack>
        )}
        {!loading && (
          <>
            <div className="blog-detailPage-heading">
              <h1>{translate(selectedBlog?.title)}</h1>
              <p>
                {translate("Home")}
                <IoIosArrowForward />
                {translate("Blog")}
                <IoIosArrowForward />
                <span>{translate("Detail")}</span>
              </p>
            </div>

            <div className="container">
              <div className="row" style={{ marginTop: "80PX" }}>
                <div className="col-lg-8 col-md-8 col-12">
                  <div className="blogIndividual-mainImg">
                    <img src={selectedBlog?.image} alt="..." />
                  </div>
                  <div className="blogIndividual-hotelDate">
                    <h6>
                      {/* {selectedBlog?.type} */}
                      </h6>
                    <p>{selectedBlog?.created_at}</p>
                  </div>
                  <div className="blogIndividual-hotelName">
                    <h1>{selectedBlog?.title}</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedBlog?.body
                          ? he.decode(selectedBlog?.body)
                          : "",
                      }}
                    ></div>
                  </div>
                  <hr
                    style={{
                      color: "#FFF",
                      marginTop: "40px",
                      marginBottom: "40px",
                    }}
                  />
                  <hr style={{ color: "#FFF" }} />
                </div>

                <div className="col-lg-4 col-md-4 col-12">
                  <div className="blogIndividual-rightBg mt-4">
                    <div className="blogIndividual-recentPost">
                      <h3>{translate("Recent Post")}</h3>
                      <hr />
                      {recentBlogs?.map((blog, index) => (
                        <div
                          className="blogIndividual-recentCards mt-2"
                          onClick={(e) => openNextBlog(blog?.id, blog?.slug)}
                        >
                          <img
                            src={blog.image}
                            alt={blog.title}
                            width={120}
                            height={120}
                          />
                          <div className="blogIndividual-recentContent">
                            <h1>{blog.title}</h1>
                            {/* <h1>How to Score the Best </h1> */}
                            <p>{blog.created_at}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="realted-blogs-wrapper">
                <div className="realted-blogs-content">
                  <div className="realted-blogs-topIcon">
                    <img src="/assets/icons/aa1.png" alt="..." />
                  </div>
                  <div className="realted-blogs-bottomIcon">
                    <img src="/assets/icons/aa2.jpeg" alt="..." />
                  </div>
                  <h1>{translate("Related Blog")}</h1>
                  <p>
                    {translate(
                      "Explore More of Our Insights: Discover Related Blogs to Deepen Your Knowledge, Stay Informed"
                    )}
                    .
                  </p>
                </div>
                <div className="realted-blogs-button">
                  {/* <button variant="contained">View All</button> */}
                </div>
              </div>

              {loading && (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress size={50} sx={{ color: "#8bc53e" }} />
                </Stack>
              )}
              {!loading && (
                <div className="row " style={{ marginBottom: "40px" }}>
                  {relatedBlogs?.map((blog, index) => {
                    return (
                      <div key={index} className="col-lg-4 mt-4">
                        <Card sx={{ borderRadius: "10px" }}>
                          <div>
                            <img src={blog.image} alt="....." />
                          </div>
                          <CardContent className="">
                            <div className="blog-hotelName-wrapper">
                              <div className="blog-hotelName">
                                <p>
                                  <span>{blog?.type}</span>
                                </p>
                              </div>
                              <div className="blog-hotelName">
                                <p>{blog?.hotelDate}</p>
                              </div>
                            </div>

                            <div>
                              <div
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="blog-card-content"
                              >
                                <h1>{blog?.title}</h1>
                              </div>
                              <div
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="blog-card-content"
                              >
                                <p>{blog?.hotelDiscription}</p>
                                <p>{blog?.hotelDiscription}</p>
                              </div>
                            </div>

                            <div className="blog-deatil-button">
                              <Link>
                                <Button variant="contained">
                                  {translate("Read More")}
                                  <BsArrowRightShort
                                    style={{
                                      fontSize: "15px",
                                      marginLeft: "10px",
                                    }}
                                  />
                                </Button>
                              </Link>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </Box>
      {!loading && (
        <>
          {/* <div className="blogIndividual-fastBoatCards">
            <FastBoatCards />
          </div> */}

          <div className="blogIndividual-footer">
            <Footer />
          </div>
        </>
      )}
    </>
  );
}

export default BlogDetails;
