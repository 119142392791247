import React, { useState } from "react";
import classes from "./NewSupportTickets.module.css";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import Navbar from "../../Components/Navbar/Navbar";
import UserSideNavbar from "../UserSideNavbar/UserSideNavbar";
import Footer from "../../Components/Footer/Footer";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import { useLanguage } from "../../utils/LanguageContext";


function NewSupportTickets() {
  const [selectedImage8, setSelectedImage8] = useState(null);
  const { langData, fetchLangData, translate } = useLanguage();


  return (
    <div>
      <Navbar />
      <div className={classes["page-container"]}>
        <div className="row">
          <div className="col-lg-3 col-md-3 d-none d-lg-block">
            <UserSideNavbar />
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <div className={`table-heading ${classes["table-heading"]}`}>
              <h1>{translate('Support Tickets')}</h1>
            </div>

            <div className={classes["AddSupportTickets-bg"]}>
              <div className={classes["AddSupportTickets-btnWrapper"]}>
                <button className={classes["AddSupportTickets-addBtn"]}>
                  <Link to="/support-ticket">
                    <BsArrowLeft />
                  </Link>
                </button>
                <h2>{translate('Open New Support Ticket')}</h2>
              </div>
              <div className="row mt-4">
                <div className={`col-lg-6 col-md-12 col-12 mt-3 ${classes['AddSupportTickets-name-inputs']}`}>
                  <label className={classes["AddSupportTickets-inputLabel"]}>
                    {translate('Name')}<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    required
                  />
                </div>
                <div className={`col-lg-6 col-md-12 col-12 mt-3 ${classes['AddSupportTickets-name-inputs']}`}>
                  <label className={classes["AddSupportTickets-inputLabel"]}>
                    {translate('Email Address')}<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    required
                  />
                </div>

                <div className={`col-lg-6 col-md-12 col-12 mt-5 ${classes['AddSupportTickets-inputs']}`}>
                  <label className={classes["AddSupportTickets-inputLabel"]}>
                    {translate('Subject')}<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className={`col-lg-6 col-md-12 col-12 mt-5 ${classes['AddSupportTickets-inputs']}`}>
                  <label className={classes["AddSupportTickets-inputLabel"]}>
                    {translate('Priority')}<span>*</span>
                  </label>
                  <div className={classes["AddSupportTickets-dropdown"]}>
                    <select>
                      <option>{translate('Select One')}</option>
                      <option>{translate("High")}</option>
                      <option>{translate('Medium')}</option>
                      <option>{translate('Low')}</option>
                    </select>
                  </div>
                </div>

                <div className={`col-lg-6 col-md-12 col-12 mt-5 ${classes['AddSupportTickets-inputs']}`}>
                  <label className={classes["AddSupportTickets-inputLabel"]}>
                    {translate('Attachments')}
                  </label>
                  <ImageUploader
                    selectedImage={selectedImage8}
                    onImageChange={setSelectedImage8}
                  />
                  {/* <div className="AddSupportTickets-attachment">
                  <div className="AddSupportTickets-attachmentContent">
                    <img src="/assets/icons/uploadIcon.png" alt="..." />
                    <p>PNG, GIF, WEBP, MP4. Max 500Mb.</p>
                  </div>
                </div> */}
                  <p className={classes["AddSupportTickets-attachmentText"]}>
                    {translate('Allowed file extensions: .jpg, .jpeg, .png, .pdf, .doc, .docx')}
                  </p>
                </div>

                <div className={`col-lg-6 col-md-12 col-12 mt-5 ${classes['AddSupportTickets-message-input']}`}>
                  <label className={classes["AddSupportTickets-inputLabel"]}>
                    {translate('Message')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    required
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                  <div className={classes["AddSupportTickets-submitBtn"]}>
                    <button>
                      <Link>{translate('Submit')}</Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NewSupportTickets;
