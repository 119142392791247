import React, { useEffect } from "react";
import { useState } from "react";
import makeHttpRequest from "../utils/api";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

function RegisterImage({ type }) {
  const [content, setContent] = useState(false);
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const res = await makeHttpRequest("POST", "content", {
          content: type,
        });
        setContent(res?.data);
      } catch (error) {
        console.error("Error is:", error);
      }
    };
    fetchCountryData();
  }, []);
  return (
    <>
      <div
        id="logincarouselExampleIndicator"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        {/* <div className="carousel-indicators-login">
          <button
            type="button"
            data-bs-target="#logincarouselExampleIndicator"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#logincarouselExampleIndicator"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#logincarouselExampleIndicator"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div> */}
        <div className="carousel-inner">
          {content?.content?.comment_1 && (
            <div className="carousel-item active">
              <img
                src="/assets/icons/loginPageImg.png"
                className="d-block w-100"
                alt="..."
              />
              <div className="carousel-caption">
                <h2>“{content?.content?.comment_1}”</h2>
                <h3>{content?.content?.name_1}</h3>
                <p>{content?.content?.review_1}</p>
              </div>
            </div>
          )}
          {content?.content?.comment_2 && (
            <div className="carousel-item">
              <img
                src="/assets/icons/signupPageImg.png"
                className="d-block w-100"
                alt="..."
              />
              <div className="carousel-caption">
                <h2>“{content?.content?.comment_2}”</h2>
                <h3>{content?.content?.name_2}</h3>
                <p>{content?.content?.review_2}</p>
              </div>
            </div>
          )}
          {content?.content?.comment_3 && (
            <div className="carousel-item">
              <img
                src="/assets/icons/resetPasswordImg.png"
                className="d-block w-100"
                alt="..."
              />

              <div className="carousel-caption">
                <h2>“{content?.content?.comment_3}”</h2>
                <h3>{content?.content?.name_3}</h3>
                <p>{content?.content?.review_3}</p>
              </div>
            </div>
          )}
        </div>
        <div>
          <button
            className="login-carousel-control-prev"
            type="button"
            data-bs-target="#logincarouselExampleIndicator"
            data-bs-slide="prev"
          >
            <span
              className="login-carousel-control-prev-icon"
              aria-hidden="true"
            >
              <BsArrowLeft />
            </span>
          </button>
          <button
            className="login-carousel-control-next"
            type="button"
            data-bs-target="#logincarouselExampleIndicator"
            data-bs-slide="next"
          >
            <span
              className="login-carousel-control-next-icon"
              aria-hidden="true"
            >
              <BsArrowRight />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default RegisterImage;
