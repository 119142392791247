import React, { useState, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from '../../utils/api';
import { Link } from 'react-router-dom';
import he from "he";


const EventModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [event, setEvent] = useState('');
  const { langData, fetchLangData, translate } = useLanguage();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await makeHttpRequest("Post",`event`);
    if (res?.success === true) {
      setEvent(res?.data);
      console.log('res?.data?.title',res?.data?.title);
      
      const lastVisit = localStorage.getItem('lastVisit');
      const today = new Date().toLocaleDateString();
  
      if (!lastVisit || lastVisit !== today) {
        if(res?.data?.title == ''){
          setShowModal(false);
        } else {
          setShowModal(true);
        // localStorage.setItem('lastVisit', today);
        }
      }
    } else {
    setShowModal(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    localStorage.setItem('lastVisit', new Date().toLocaleDateString());
  };

  return (
    <>
    {event && (
      <Modal show={showModal} style={{ paddingTop: "100px" }}>
        <div className="modal-confirm modal-bg">
            {event?.tag_line && (
              <div className="modal-header">
                <h4 className="modal-title w-100 text-center mt-3">
                  { translate(event?.tag_line)}
                </h4>
              </div>
            )}
          {(event?.image || event?.description || (event?.url && event?.label)) && (
            <div className="modal-body">
                {event?.image && (
                    <div className='d-flex justify-content-center'>
                      <img src={event?.image} height={'100%'} width={'100%'} alt={event?.tag_line ? translate(event?.tag_line) : translate("Caprieasy")} />
                    </div>
                )}
                {event?.description && (
                  <div className='d-flex justify-content-center'>
                    <p className="text-center mt-3"
                      dangerouslySetInnerHTML={{
                        __html: event?.description
                          ? he.decode(event?.description)
                          : "",
                      }}
                    >
                    </p>
                  </div>
                )}
                {event?.url && event?.label && (
                  <div className='d-flex justify-content-center'>
                    <Link to={event?.url}> <Button variant="contained" className='btn btn-outline-primary' size="small">{translate(event?.label)}</Button> </Link>
                  </div>
                )}
            </div>
           )}
          <div className="modal-footer modalOk-btn">
            <button
              style={{
                background: "#70B25C",
                color: "#FFF",
                border: "none",
                fontWeight: "bold",
                borderRadius: "5px",
              }}
              className=" w-100 p-2"
              onClick={handleCloseModal}
              data-dismiss="modal"
              aria-label="Close"
            >
              {translate("Remind Me Later")}
            </button>
          </div>
        </div>
      </Modal>
    )}
    </>
  );
};

export default EventModal;
