import React, { useState } from "react";
import { Dialog, List, ListItem, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { clearUser } from "../../UserDashboardComponent/UserProfile/userSlice";
import { useDispatch } from "react-redux";
import { useLanguage } from "../../utils/LanguageContext";


function UserSidebar() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.user);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("availability");
  const { langData, fetchLangData, translate } = useLanguage();

  const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userType");
    localStorage.removeItem("userEmail");
    navigate("/");
  };

  {
    /* Guest Menu */
  }
  //   const menuItems = [
  //     {
  //       text: "Home",
  //       to: "/",
  //       isActive: pathname === "/" || pathname.startsWith("/Home"),
  //     },
  //     {
  //       text: "Accommodations",
  //       to: "/accomodation",
  //       isActive: pathname.startsWith("/accomodation"),
  //     },
  //     {
  //       text: "Boat",
  //       to: "/boat",
  //       isActive: pathname.startsWith("/boat"),
  //     },
  //     {
  //       text: "Blog",
  //       to: "/blog",
  //       isActive: pathname === "/blog",
  //     },
  //     {
  //       text: "Contact Us",
  //       to: "/contact",
  //       isActive: pathname === "/contact",
  //     },
  //   ];

  const menuItems = [
    {
      text: translate("Availability Request"),
      path: "/dashboard",
      icon: "",
      isActive: selectedOption === "availability",
    },
    {
      text: translate("Property Booking History"),
      path: "/property-history",
      icon: "",
      isActive: selectedOption === "propertyHistory",
    },
    {
      text: translate("Boat Booking History"),
      path: "/boat-history",
      icon: "",
      isActive: selectedOption === "History",
    },
    // {
    //   text: translate("Payments & Payouts"),
    //   icon: "",
    //   isActive: selectedOption === "paymentsPayout",
    // },
    // {
    //   text: translate("Notifications"),
    //   icon: "",
    //   isActive: selectedOption === "notifications",
    // },
    {
      text: translate("Reviews"),
      path: "/user-reviews",
      icon: "",
      isActive: selectedOption === "userReviews",
    },
    {
      text: translate("Support Tickets"),
      path: "/support-ticket",
      icon: "",
      isActive: selectedOption === "supportTickets",
    },
    {
      text: translate("Change Password"),
      path: "/change-password",
      icon: "",
      isActive: selectedOption === "changePassword",
    },
    // {
    //   text: translate("Login & Security"),
    //   path: "/login-security",
    //   icon: "",
    //   isActive: selectedOption === "loginSecurity",
    // },
    {
      text: translate("Logout"),
      onClick: handleLogout,
    },
  ];

  return (
    <div>
      {isLoggedIn && (
        <div className="floating-btn-container" onClick={handleOpenSidebar}>
          <span className="floating-btn">&gt;&gt;</span>
        </div>
      )}
      <Dialog
        open={isSidebarOpen}
        onClose={handleCloseSidebar}
        PaperProps={{
          style: {
            width: "80%", // Set the width of the sidebar
            maxWidth: "300px", // Set a maximum width if desired
            backgroundColor: "#f8fbfc",
          },
        }}
      >
        <List>
          {/* Guest Menu */}
          {/* {menuItems.map((menuItem, index) => (
            <ListItem
              button
              key={index}
              onClick={handleCloseSidebar}
              className={menuItem.isActive ? "active" : ""}
            >
              <Link className="nav-link" to={menuItem.path}>
                <ListItemText primary={menuItem.text} />
              </Link>
            </ListItem>
          ))} */}
          {menuItems.map((menuItem, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                handleCloseSidebar();
                if (menuItem.onClick) menuItem.onClick();
              }}
              className={menuItem.isActive ? "active" : ""}
            >
              {menuItem.path ? (
                <Link className="nav-link" to={menuItem.path}>
                  <ListItemText primary={menuItem.text} />
                  {menuItem.icon}
                </Link>
              ) : (
                <div className="nav-link" onClick={menuItem.onClick}>
                  {menuItem.text}
                </div>
              )}
            </ListItem>
          ))}
        </List>
      </Dialog>
    </div>
  );
}

export default UserSidebar;
