import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { Link } from "react-router-dom";
import "./AllProperty.css";
import { useLanguage } from "../../utils/LanguageContext";
import Loader from "../../Components/Loader";
import { addLeadingZero } from "../../utils/addLeadingZero";

function AllProperty() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/properties");
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteThisItem = async (id) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/property/destroy/" + id);
      getData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="mainContainer">
          <div className="allProperty-headingWrapper">
            <div className="allProperty-heading">
              <h1>{translate("All Properties")}</h1>
              <p>
                {translate("Total Property")}{" "}
                <span>{addLeadingZero(data?.properties?.total)}</span>
              </p>
            </div>
            <div className="">
              <button className="allProperty-addBtn">
                <Link to="/add-property">+ {translate("Add Property")}</Link>
              </button>
            </div>
          </div>
          <div class="inner-continer">
            <div className="table-responsive">
              <div className="table-wrapper">
                <table
                  id="mytable"
                  className="table table-bordred table-striped"
                >
                  <thead className="allProperty-table-th">
                    <th>{translate("S.N.")}</th>
                    <th>{translate("Name")}</th>
                    <th>{translate("Property Type")}</th>
                    <th>{translate("Location")}</th>
                    <th>{translate("Discount")}</th>
                    <th>{translate("Room")}</th>
                    <th>{translate("Categories")}</th>
                    <th>{translate("Status")}</th>
                    <th>{translate("Action")}</th>
                  </thead>
                  <tbody>
                    {data?.properties?.data?.map((row, index) => {
                      return (
                        <tr key={index} className="allProperty-td-wrapper">
                          <td style={{ paddingTop: "18px" }}>
                            <div className="sr-no-bg">0{index + 1}</div>
                          </td>
                          <td style={{ paddingTop: "20px" }}>{row.name}</td>
                          <td style={{ paddingTop: "20px" }}>
                            {row.property_type_name}
                          </td>
                          <td style={{ paddingTop: "20px" }}>{row.location}</td>
                          <td style={{ paddingTop: "20px" }}>{row.discount}</td>
                          <td style={{ paddingTop: "20px" }}>
                            {row.is_complete === 1
                              ? translate("Not Apply")
                              : row.room_count}
                          </td>
                          <td>
                            {/* className="allProperty-categoryWrapper"> */}
                            {/* <p className="allProperty-category-bg"> */}
                            {row.is_complete === 1
                              ? translate("Not Apply")
                              : row.room_category_count}
                            {/* </p> */}
                          </td>
                          <td
                            style={{ paddingTop: "20px" }}
                            className="allProperty-status"
                          >
                            {row.status === 1 ? (
                              <span className="badge badge-success">
                                {translate("Active")}
                              </span>
                            ) : (
                              <span className="badge badge-warning">
                                {translate("Inactive")}
                              </span>
                            )}
                          </td>
                          <td>
                            <Link
                              to={`/property/${row.id}/edit`}
                              className="allProperty-view-bg"
                            >
                              {translate("Edit")}
                            </Link>
                            <div
                              className="text-danger"
                              onClick={() => deleteThisItem(row?.id)}
                              style={{ cursor: "pointer" }}
                            >
                              {translate("Delete")}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    {data?.properties?.data?.length === 0 && (
                      <tr className="text-center">
                        <td colSpan={9}>{translate(data?.empty_message)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AllProperty;
