import React, { useEffect, useState } from "react";
import "./AccomodationPage.css";
import MainSearchEngine from "../../Components/MainSearchEngine/MainSearchEngine";
import { BsStarFill } from "react-icons/bs";

import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { BiCheck } from "react-icons/bi";
import { BsFillStarFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import Footer from "../../Components/Footer/Footer";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../Components/Navbar/Navbar";
import { styled } from "@mui/material/styles";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import { Checkbox, FormControlLabel } from "@mui/material";
import { MenuItem, Radio, Slider, TextField } from "@mui/material";
import makeHttpRequest from "../../utils/api";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";
import {
  Carousel,
  Card as CardR,
  Stack as StackR,
  Button as ButtonR,
} from "react-bootstrap";
import Wishlist from "../../Components/Wishlist";
import { Link } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";
const Title = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "60px",
  fontWeight: 700,
  textAlign: "center",
  marginTop: "50px",
});

const ImageSliderContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "200px",
  overflow: "hidden",
  position: "relative",
});

const ImageSliderImage = styled("img")({
  width: "190px",
  height: "190px",
  borderRadius: "50%",
  objectFit: "cover",
  transition: "transform 0.3s ease",
  margin: "0 10px",
});

const ArrowWrapper = styled("div")({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  cursor: "pointer",
});

function AccomodationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, slug } = useParams();
  const [accomodation, setAccomodation] = useState("");
  const [loading, setLoading] = useState(false);
  const [section_loading, setSectionLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [price, setPrice] = useState([10, 500]);

  const [show_filter, setShowFilter] = useState(true);
  const [selected_type, setSelectedType] = useState("");
  const [filter_location, setFilterLocation] = useState("");
  const [selected_star, setSelectedStar] = useState("");
  const [filter_amenities, setFilterAmenities] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [adult, setAdult] = useState(0);
  const [child, setChild] = useState(0);
  const [multi_image_slider, setMultiImageSlider] = useState([]);
  const { langData, fetchLangData, translate } = useLanguage();
  const [lower_range, setLowerRange] = useState(10);
  const [higher_range, setHigherRange] = useState(500);

  useEffect(() => {
    if (location.state) {
      const res = location.state;
      setFilterLocation(res?.data?.request?.location);
      setAccomodation(res.data);
    } else if (id && slug) {
      getData();
    } else {
      //DIRECT URL
      getAccomodations();
    }
  }, [id]);

  // useEffect(() => {
  //   alert("d");
  //   if (!location.state && window.location.pathname != "/accomodation") {
  //     alert("d2");
  //     getSearchData();
  //   }
  // }, [filter_location, filter_amenities, price, selected_type]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `search-location/${id}/${slug}`);
      setAccomodation(res.data);

      const objectsArray = Object.values(res?.data?.property_types);
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      let subarraySize = 0;
      if (isMobile) {
        subarraySize = 1;
      } else {
        subarraySize = 3;
      }

      const newMultiImageSlider = [];
      for (let i = 0; i < objectsArray.length; i += subarraySize) {
        const subarray = objectsArray.slice(i, i + subarraySize);
        newMultiImageSlider.push(subarray);
      }
      setMultiImageSlider(newMultiImageSlider);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const getAccomodations = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "property");
      setAccomodation(res.data);
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      let subarraySize = 0;
      if (isMobile) {
        subarraySize = 1;
      } else {
        subarraySize = 3;
      }
      const objectsArray = Object.values(res?.data?.property_types);
      const newMultiImageSlider = [];
      for (let i = 0; i < objectsArray.length; i += subarraySize) {
        const subarray = objectsArray.slice(i, i + subarraySize);
        newMultiImageSlider.push(subarray);
      }
      setMultiImageSlider(newMultiImageSlider);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  function generateSlug(inputString) {
    const slug = inputString.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    return slug.replace(/^-+|-+$/g, "");
  }

  const handleDetails = (item) => {
    if (slug) {
      navigate(`/accomodation/${item?.id}/${slug}/details`);
    } else {
      const resultSlug = generateSlug(item.name);
      navigate(`/accomodation/${item?.id}/${resultSlug}/details`);
    }
  };

  const minDistance = 10;
  const handlePriceChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    let pr = "";
    if (activeThumb === 0) {
      pr = [Math.min(newValue[0], price[1] - minDistance), price[1]];
    } else {
      pr = [price[0], Math.max(newValue[1], price[0] + minDistance)];
    }
    setPrice(pr);
    setLowerRange(showAmount(pr[0]));
    setHigherRange(showAmount(pr[1]));
    if (price[0] != 10 || price[1] != 500) {
      getSearchData(
        selected_type,
        filter_location,
        pr,
        filter_amenities,
        selected_star
      );
    }
  };

  const handleLowerChange = (event) => {
    const value = event.target.value;
    setLowerRange(value);

    // Convert the input value back to a number and update the Slider
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      setPrice([numericValue, price[1]]);
    }
  };

  const handleHigherChange = (event) => {
    const value = event.target.value;
    setHigherRange(value);

    // Convert the input value back to a number and update the Slider
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      setPrice([price[0], numericValue]);
    }
  };

  const handleLocationChange = async (e) => {
    const { value } = e.target;
    setFilterLocation(value);
    // if (value) {
    getSearchData(selected_type, value, price, filter_amenities, selected_star);
    // }
  };

  const handleTypeFilter = (event) => {
    const { type, value } = event.currentTarget.dataset;
    setSelectedType(value);
    getSearchData(
      value,
      filter_location,
      price,
      filter_amenities,
      selected_star
    );
    if (type == 1) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };

  const handleAmenitiesChange = (event) => {
    const amenityId = parseInt(event.target.value); // Parse the ID to an integer
    const isChecked = event.target.checked;

    if (isChecked) {
      setFilterAmenities((prevSelectedAmenities) => [
        ...prevSelectedAmenities,
        amenityId,
      ]);
    } else {
      setFilterAmenities((prevSelectedAmenities) =>
        prevSelectedAmenities.filter((id) => id !== amenityId)
      );
    }
    getSearchData(
      selected_type,
      filter_location,
      price,
      filter_amenities,
      selected_star
    );
  };

  const handleStarRating = (value) => {
    setSelectedStar(value);
    if (value) {
      getSearchData(
        selected_type,
        filter_location,
        price,
        filter_amenities,
        value
      );
    }
  };

  const getSearchData = async (type, loc, pr, amen, str) => {
    // alert("e");
    let url_data = {
      type: "property",
      property_type: type,
      location: loc,
      start: startDate,
      end: endDate,
      adult: adult,
      child: child,
      price: pr,
      amenities: amen,
      star: str,
    };
    try {
      setSectionLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `search?propertyType=${url_data.property_type}&type=${url_data.type}&location=${url_data.location}&start=${url_data.start}&end=${url_data.end}&adult=${url_data.adult}&child=${url_data.child}&amenities=${url_data.amenities}&price=${url_data.price}&star=${url_data.star}`
      );
      setAccomodation(res.data);
      setSectionLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      {loading && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && (
        <div className="container">
          <div style={{ marginBottom: "70px", marginTop: "40px" }}>
            <MainSearchEngine
              locationId={filter_location}
              accom={setAccomodation}
            />
          </div>

          {!loading &&
            accomodation &&
            accomodation?.property_types?.length === 0 && (
              <Stack my={5} alignItems="center" justifyContent="center">
                <Title>{translate(accomodation?.empty_message)}</Title>
              </Stack>
            )}
          {/* Round Slider */}
          <div>
            <div className="container-fluid">
              <Carousel indicators={false}>
                {!loading &&
                  accomodation &&
                  accomodation?.property_types?.length > 0 &&
                  multi_image_slider?.map((items, index) => (
                    <Carousel.Item>
                      <StackR
                        direction="horizontal"
                        className="h-100 justify-content-center align-items-center"
                        gap={3}
                      >
                        {items?.map((item) => (
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/accomodation/search-location/${item?.id}/${item?.slug}`}
                          >
                            <CardR
                              style={{
                                width: "18rem",
                                border: "none",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CardR.Body className="cardSize">
                                <CardR.Img
                                  className="imageSize"
                                  variant="top"
                                  src={item.image}
                                />
                                <CardR.Title
                                  className="accomodation-property-type"
                                  style={{
                                    textAlign: "center",
                                    // padding: "5px",
                                  }}
                                >
                                  {translate(item.name)}
                                </CardR.Title>
                              </CardR.Body>
                            </CardR>
                          </Link>
                        ))}
                      </StackR>
                    </Carousel.Item>
                  ))}
              </Carousel>
            </div>
          </div>

          {!loading && (
            <div
              className="row"
              style={{ marginTop: "30px", marginBottom: "80px" }}
            >
              <div className="col-lg-3 col-md-4 col-12">
                <div className="accomodationList-right-bg ">
                  <Card sx={{ background: "none", boxShadow: "none" }}>
                    {/* Property Type */}
                    <div>
                      <h6>{translate("Property Type")}</h6>
                      <TextField
                        select
                        fullWidth
                        className="mainSearch-dropdown"
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        id="demo-simple-select"
                        style={{
                          marginTop: "1rem",
                          backgroundColor: "#F3F3F5",
                          border: "none",
                        }}
                        // name="type_filter"
                        value={selected_type}
                        label={translate("Search by type")}
                      >
                        <MenuItem
                          onClick={handleTypeFilter}
                          key="0"
                          value=""
                          selected={selected_type === ""}
                        >
                          {translate("Any")}
                        </MenuItem>
                        {accomodation?.property_types?.length > 0 &&
                          accomodation?.property_types?.map((type, index) => (
                            <MenuItem
                              // key={type.id}
                              onClick={handleTypeFilter}
                              value={type.id}
                              data-type={type.type}
                              selected={selected_type === type.id}
                            >
                              {translate(type.name)}
                            </MenuItem>
                          ))}
                      </TextField>
                      <hr />
                    </div>
                    <div>
                      <h6>{translate("Location")}</h6>
                      <TextField
                        select
                        fullWidth
                        className="mainSearch-dropdown"
                        labelId="demo-simple-select-label"
                        variant="outlined"
                        id="demo-simple-select"
                        style={{
                          marginTop: "1rem",
                          backgroundColor: "#F3F3F5",
                          border: "none",
                        }}
                        value={filter_location}
                        label={translate("Location of Accomodation")}
                        onChange={handleLocationChange}
                      >
                        <MenuItem
                          key="0"
                          value=""
                          selected={filter_location === "" ? "selected" : ""}
                        >
                          {translate("Any")}
                        </MenuItem>
                        {accomodation?.locations?.length > 0 &&
                          accomodation?.locations?.map((location, index) => (
                            <MenuItem
                              value={location.id}
                              selected={
                                filter_location === location.id
                                  ? "selected"
                                  : ""
                              }
                            >
                              {translate(location.name)}
                            </MenuItem>
                          ))}
                      </TextField>
                      <hr />
                    </div>
                    <div className="slider-wrapping">
                      <Slider
                        getAriaLabel={() => translate("Price Range")}
                        value={price}
                        // defaultValue={800}
                        min={50}
                        max={800}
                        step={50}
                        onChange={handlePriceChange}
                        valueLabelDisplay="auto"
                        // getAriaValueText={valuetext}
                        disableSwap
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography
                          style={{
                            backgroundColor: "#F3F3F5",
                            padding: "0.5rem 1rem",
                            borderRadius: "5px",
                            display: "flex",
                          }}
                        >
                          {/* {accomodation?.curr_sym} {showAmount(price[0])} */}
                          <span>{accomodation?.curr_sym} </span>
                          <input
                            type="number"
                            value={lower_range}
                            onChange={handleLowerChange}
                            style={{
                              width: `${6}ch`,
                              border: "1px solid gray",
                              borderRadius: "5px",
                              marginLeft: "5px",
                            }}
                          />
                        </Typography>
                        <Typography>{translate("-")}</Typography>
                        <Typography
                          style={{
                            backgroundColor: "#F3F3F5",
                            padding: "0.5rem 1.5rem",
                            borderRadius: "5px",
                            display: "flex",
                          }}
                        >
                          {/* {accomodation?.curr_sym} {showAmount(price[1])} */}
                          <span>{accomodation?.curr_sym} </span>
                          <input
                            type="number"
                            value={higher_range}
                            onChange={handleHigherChange}
                            style={{
                              width: `${6}ch`,
                              border: "1px solid gray",
                              borderRadius: "5px",
                              marginLeft: "5px",
                            }}
                          />
                        </Typography>
                      </div>
                      <hr />
                    </div>

                    {/* Popular Filters */}
                    {/* <div>
                      <h6>Popular Filters</h6>
                      <FormControlLabel
                        control={<Checkbox name="SomeName" value="SomeValue" />}
                        label="Breakfast Included"
                      />
                      <FormControlLabel
                        control={<Checkbox name="SomeName" value="SomeValue" />}
                        label="Free Cancellation"
                      />
                      <FormControlLabel
                        control={<Checkbox name="SomeName" value="SomeValue" />}
                        label="Instant Confirmation"
                      />
                    </div> */}
                    <hr />
                    {/* Amenities */}
                    <div>
                      <h6>
                        {translate("Amenities")}
                        {/* Property facilities & services */}
                      </h6>
                      {accomodation?.amenities?.map((am, index) => (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={am.id}
                                value={am.id.toString()}
                                checked={filter_amenities.includes(am.id)} // Check if the amenity is selected
                                onChange={handleAmenitiesChange}
                              />
                            }
                            label={translate(am.name)}
                          />
                        </div>
                      ))}
                      {/* <div>
                  <p style={{ marginTop: "0.5rem", color: "#45aaf0" }}>
                    Show all 20
                  </p>
                  </div> */}
                    </div>
                    <hr />
                    {/* <div>
                      <h6>Bed type</h6>
                      <FormControlLabel
                        control={<Radio name="SomeName" value="SomeValue" />}
                        label="1 Single bed"
                      />
                      <br />
                      <FormControlLabel
                        control={<Radio name="SomeName" value="SomeValue" />}
                        label="2 double bed"
                      />
                      <br />
                      <FormControlLabel
                        control={<Radio name="SomeName" value="SomeValue" />}
                        label="2 beds"
                      />
                      <p style={{ marginTop: "0.5rem", color: "#45aaf0" }}>
                        Show all 20
                      </p>
                    </div> */}
                    {show_filter && (
                      <>
                        <hr />
                        <div className="filter-box">
                          {Array.from(
                            { length: accomodation?.star },
                            (_, i) =>
                              i !== 0 && (
                                <p
                                  className={`bg-rating ${
                                    i == selected_star
                                      ? "bg-selected-rating"
                                      : ""
                                  }`}
                                  onClick={() => handleStarRating(i)}
                                  style={{ fontSize: "14px" }}
                                >
                                  {i}
                                </p>
                              )
                          )}
                        </div>
                      </>
                    )}
                  </Card>
                </div>
              </div>

              {section_loading && (
                <Stack
                  height="80vh"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
                </Stack>
              )}
              {!section_loading && (
                <div className="col-lg-9 col-md-8 col-12 mt-5">
                  {accomodation?.properties?.data?.length == 0 ? (
                    <Stack
                      width="100%"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="#fff"
                      border="1px solid #7c7777"
                      // marginTop="50px"
                    >
                      <h2>{translate(accomodation?.empty_message)}</h2>
                    </Stack>
                  ) : (
                    accomodation?.properties?.data?.map((item, index) => {
                      return (
                        <div className="accomodationList-left-bg ">
                          <div className="row">
                            <div className="col-lg-4 col-md-8 col-sm-12">
                              <Card style={{ borderRadius: "10px" }}>
                                <CardMedia>
                                  <div
                                    id={`carouselExampleIndicators_${index}`}
                                    className="carousel slide"
                                    data-bs-ride="carousel"
                                  >
                                    <div className="carousel-indicators">
                                      {item?.image?.map((image, i) => (
                                        <button
                                          type="button"
                                          data-bs-target={`#carouselExampleIndicators_${index}`}
                                          data-bs-slide-to={i}
                                          className={i === 0 ? "active" : ""}
                                          aria-current={
                                            i === 0 ? "true" : "false"
                                          }
                                          key={i}
                                        ></button>
                                      ))}
                                    </div>
                                    <div className="carousel-inner accomodation-card-image">
                                      {item?.image?.map((image, i) => (
                                        <div
                                          className={`carousel-item ${
                                            i === 0 ? "active" : ""
                                          }`}
                                          key={i}
                                        >
                                          <img
                                            src={image}
                                            className="d-block w-100"
                                            alt={`Slide ${i + 1}`}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                    <div className="boat-card-offer">
                                      <h4>
                                        {item?.discount} {translate("off")}
                                      </h4>
                                      <Wishlist
                                        id={item.id}
                                        type={"property"}
                                      />
                                    </div>
                                    <button
                                      className="carousel-control-prev"
                                      type="button"
                                      data-bs-target={`#carouselExampleIndicators_${index}`}
                                      data-bs-slide="prev"
                                    >
                                      <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                      >
                                        <i className="fas fa-chevron-left"></i>
                                      </span>
                                    </button>
                                    <button
                                      className="carousel-control-next"
                                      type="button"
                                      data-bs-target={`#carouselExampleIndicators_${index}`}
                                      data-bs-slide="next"
                                    >
                                      <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                      >
                                        <i className="fas fa-chevron-right"></i>
                                      </span>
                                    </button>
                                  </div>
                                </CardMedia>
                              </Card>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 p-0">
                              <CardContent className="pb-0 pt-0">
                                <div
                                  className={`accomodation-nameWrapper ${
                                    item?.is_complete === 1
                                      ? "accomodation-nameWrapper-bagde"
                                      : ""
                                  }`}
                                >
                                  <div className="accomodation-card-location">
                                    <h1>{item?.name}</h1>
                                    <p>
                                      <FaMapMarkerAlt
                                        style={{
                                          color: "#8BC53E",
                                          marginRight: "7px",
                                          fontSize: "15px",
                                        }}
                                      />
                                      <span>{item?.location}</span>
                                    </p>
                                  </div>
                                  <div className="accomdation-discount-wrapper">
                                    {/* 
                                  <p className="accomdation-discount">
                                    {item?.discount}
                                  </p>  
                                  */}
                                    {item?.top_rated === 1 ? (
                                      <p className="accomdation-discount">
                                        {translate("Top Rated")}
                                      </p>
                                    ) : null}
                                    {item?.top_reviewed === 1 ? (
                                      <p className="accomdation-discount">
                                        {translate("Top Reviewed")}
                                      </p>
                                    ) : null}
                                    {item?.is_complete === 1 ? (
                                      <p className="accomdation-discount-sole">
                                        {translate("Sole")}
                                      </p>
                                    ) : null}
                                    {!item?.is_complete ? (
                                      <p className="accomdation-discount-star">
                                        <BsStarFill
                                          style={{ color: "yellow" }}
                                        />
                                        {item?.star}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  className="shiplist-card-discription"
                                >
                                  {item?.extra_features?.map((feature, i) => {
                                    return (
                                      <p key={i}>
                                        <BiCheck
                                          style={{
                                            color: "#8BC53E",
                                            fontSize: "25px",
                                          }}
                                        />
                                        {feature}
                                      </p>
                                    );
                                  })}
                                </div>
                                <div className="accomodation-card-night-wrapper">
                                  <div className="accomodation-card-Night">
                                    {item?.is_complete === 1 && (
                                      <h3>
                                        {accomodation?.curr_sym}
                                        {showAmount(item?.price)}/{" "}
                                        <span>{translate(item.label)}</span>
                                      </h3>
                                    )}
                                    {!item?.is_complete && (
                                      <h3>
                                        {accomodation?.curr_sym}
                                        {showAmount(item?.price1)} -{" "}
                                        {accomodation?.curr_sym}
                                        {showAmount(item?.price2)}/{" "}
                                        <span>{translate(item.label)}</span>
                                      </h3>
                                    )}
                                    <p>{translate("Includes taxes & fees")}</p>
                                  </div>
                                </div>

                                <div className="accomodation-card-deatil-button">
                                  <p>
                                    <BsFillStarFill
                                      style={{
                                        color: "#FFF",
                                        marginRight: "7px",
                                        fontSize: "20px",
                                        background: "#8BC53E",
                                        padding: "4px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    {item?.rating}
                                    <span className="review">
                                      ({item?.review || 0}{" "}
                                      {translate("Reviews")})
                                    </span>
                                  </p>

                                  <button onClick={() => handleDetails(item)}>
                                    {translate("View Detail")}
                                  </button>
                                </div>
                              </CardContent>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {/* {!loading && (
        <div className="accomodationPage-fastBoatCards">
          <FastBoatCards />
        </div>
      )} */}
      {!loading && (
        <div className="accomodationPage-footer">
          <Footer />
        </div>
      )}
    </div>
  );
}

export default AccomodationPage;
