import React, { useEffect, useState } from 'react';
import ReactImagePickerEditor from 'react-image-picker-editor';
import { CircularProgress, Stack } from '@mui/material';
import { imageUrlToBase64 } from '../../utils/helpers';

function SingleImageEditor({ imageUrl, setImage }) {
    const [loadingImage, setLoadingImage] = useState(false);
    const [base64Image, setBase64Image] = useState('');

    useEffect(() => {
        const fetchBase64Image = async () => {
            setLoadingImage(true);
            const base64Data = await imageUrlToBase64(imageUrl);
            setBase64Image(base64Data);
            setLoadingImage(false);
        };
        fetchBase64Image();
    }, [imageUrl]);

    const config2 = {
        borderRadius: "16px",
        language: "en",
        width: '330px',
        height: '200px',
        objectFit: 'contain',
        compressInitial: null,
        hideDownloadBtn: true,
        hideDeleteBtn: true,
        hideEditBtn: false,
        hideAddBtn: false
    };

    return (
        <>
            {loadingImage ? (
                <Stack alignItems="center" justifyContent="center">
                    <CircularProgress size={20} sx={{ color: "#8bc53e" }} />
                </Stack>
            ) : (
                    <ReactImagePickerEditor
                        imageSrcProp={base64Image}
                        imageChanged={(newImage) => {
                            newImage && setImage(newImage);
                        }}
                        config={config2}
                    />
            )}
        </>
    );
}

export default SingleImageEditor;
