import React from "react";
import "./FastBoatCards.css";
import { BsArrowUpRight } from "react-icons/bs";
import Card from "@mui/material/Card";

function FastBoatCards() {
  return (
    <div className="row fastBoat-wrapper">
      <div className="col-lg-3 ">
        <Card sx={{ borderRadius: "10px" }}>
          <div
            className="fastBoat-img"
            style={{
              backgroundImage: `url("/assets/icons/1st.JPEG")`,
            }}
          ></div>
        </Card>
      </div>
      <div className="col-lg-3  d-none d-lg-block d-md-block">
        <Card sx={{ borderRadius: "10px" }}>
          <div
            className="fastBoat-img"
            style={{
              // backgroundImage: `url("/assets/icons/fastBoat9.png")`,
              backgroundImage: `url("/assets/icons/2nd.WEBP")`,
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <div className="fastBoat-content-wrapper">
              <div className="fastBoat-content">
                <h6>Fast Boat</h6>
                <p>(02)</p>
              </div>

              <div className="fastBoat-content-icon">
                <BsArrowUpRight />
              </div>
            </div> */}
            </div>
          </div>
        </Card>
      </div>
      <div className="col-lg-3 col-md-6 d-none d-lg-block d-md-block">
        <Card sx={{ borderRadius: "10px" }}>
          <div
            className="fastBoat-img"
            style={{
              backgroundImage: `url("/assets/icons/3rd.JPEG")`,
              // backgroundImage: `url("/assets/icons/fastBoat8.png")`,
            }}
          ></div>
        </Card>
      </div>
      <div className="col-lg-3 col-md-6 d-none d-lg-block d-md-block">
        <Card sx={{ borderRadius: "10px" }}>
          <div
            className="fastBoat-img"
            style={{
              backgroundImage: `url("/assets/icons/4th.JPEG")`,
              // backgroundImage: `url("/assets/icons/fastBoat9.png")`,
            }}
          ></div>
        </Card>
      </div>
    </div>
  );
}

export default FastBoatCards;
