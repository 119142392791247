import React from "react";
import "./Style.css";

function LeftHeading({ h1, p }) {
  return (
    <div className="left-heading" style={{ display: "block", margin: "0px" }}>
      <div className="wh-main-heading" style={{ display: "block" }}>
        <h1 className="h1" style={{ margin: "0" }}>
          <img className="img1" src="/assets/icons/aa1.png" alt="..." />
          {h1}
          <img className="img2" src="/assets/icons/aa2.jpeg" alt="..." />
        </h1>
      </div>
      <p style={{ margin: "0" }}>{p}</p>
    </div>
  );
}

export default LeftHeading;
