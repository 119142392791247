import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  user: "",
  isLoggedIn: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },
    userLogged: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    ownerLogged: (state, action) => {
      state.isLoggedIn = action.payload;
    },

    clearUser: (state, action) => {
      state.isLoggedIn = "";
      state.user = "";
    },
  },
});

export const { addUser, userLogged, clearUser, ownerLogged } = userSlice.actions;

export default userSlice.reducer;
